"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JiraIssuesPopover = void 0;
const selectors_1 = require("@lib/selectors");
const popups_1 = require("@lib/popups");
const selectors_2 = require("../../@arch/selectors");
const sonar_integrations_1 = require("@sonar-software/sonar-integrations");
const JiraIssue_1 = require("./JiraIssue");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getJiraIssuesSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.integrations.jira.issues.get);
const JiraIssuesPopover = ({ initiativeId }) => {
    const [query, setQuery] = (0, react_1.useState)();
    (0, _hooks_1.useJiraStatuses)();
    const getJiraIssues = (0, _hooks_1.useAction)(sonar_integrations_1.integrationsActions.requestGetJiraIssues);
    const putJiraInitiativesLink = (0, _hooks_1.useAction)(sonar_integrations_1.integrationsActions.requestPutJiraInitiativesLinkIssue);
    const resetJiraIssues = (0, _hooks_1.useAction)(sonar_integrations_1.integrationsActions.resetGetJiraIssues);
    const jiraIssuesData = (0, _hooks_1.useSonarSelector)(getJiraIssuesSelectors.data);
    const initiativeJiraIssues = (0, _hooks_1.useSonarSelector)(selectors_2.getInitiativeJiraIssuesSelector.data);
    const jiraIssuesRequested = (0, _hooks_1.useSonarSelector)(getJiraIssuesSelectors.isRequested);
    const selectRef = (0, react_1.useRef)(null);
    const { customerId } = (0, _hooks_1.useCurrentUser)();
    (0, react_1.useEffect)(() => {
        var _a;
        (_a = selectRef.current) === null || _a === void 0 ? void 0 : _a.focus();
    }, [selectRef]);
    const handleOnClear = (0, react_1.useCallback)(() => {
        setQuery(undefined);
        resetJiraIssues();
    }, [resetJiraIssues]);
    const handleOnSearch = (0, react_1.useCallback)((value) => {
        setQuery(value || undefined);
        if ((value === null || value === void 0 ? void 0 : value.length) >= 3) {
            getJiraIssues({ searchText: value });
        }
        else {
            resetJiraIssues();
        }
    }, [getJiraIssues, resetJiraIssues]);
    const options = (0, react_1.useMemo)(() => {
        const jiraIssueOptions = [];
        jiraIssuesData === null || jiraIssuesData === void 0 ? void 0 : jiraIssuesData.forEach((issue) => {
            const { issueKey, issueName, statusId, projectKey } = issue;
            if (!(initiativeJiraIssues === null || initiativeJiraIssues === void 0 ? void 0 : initiativeJiraIssues.some((jiraIssue) => jiraIssue.issueKey === issueKey))) {
                jiraIssueOptions.push({
                    label: react_1.default.createElement(JiraIssue_1.JiraIssue, { initiativeId: initiativeId, issueKey: issueKey, issueName: issueName, projectKey: projectKey, statusId: statusId }),
                    value: issueKey
                });
            }
        });
        return jiraIssueOptions;
    }, [initiativeId, initiativeJiraIssues, jiraIssuesData]);
    const handleOnSelect = (selected) => {
        const jiraIssue = jiraIssuesData === null || jiraIssuesData === void 0 ? void 0 : jiraIssuesData.filter((jiraStatus) => jiraStatus.issueKey === selected);
        if (jiraIssue) {
            const [{ issueKey, issueName, statusId, projectKey }] = jiraIssue;
            putJiraInitiativesLink({
                customerId: customerId,
                initiativeId: initiativeId,
                issueKey: issueKey,
                issueName: issueName,
                projectKey: projectKey,
                statusId: statusId
            });
        }
    };
    return (react_1.default.createElement("div", { id: 'ant-popover' },
        react_1.default.createElement(antd_1.Select, { allowClear: true, className: 'jira-issue-select', filterOption: false, getPopupContainer: popups_1.getClosestPopup, notFoundContent: jiraIssuesRequested ? react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, null) : null, onClear: handleOnClear, onSearch: handleOnSearch, onSelect: handleOnSelect, options: options, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.SEARCH_JIRA_ISSUES, ref: selectRef, showSearch: true, value: query })));
};
exports.JiraIssuesPopover = JiraIssuesPopover;
