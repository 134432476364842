"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExtensionAuthClose = void 0;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const _hooks_1 = require("@hooks");
const ExtensionAuthClose = () => {
    const { isLoggedIn } = (0, _hooks_1.useAuthToken)();
    if (isLoggedIn) {
        const { search } = window.location;
        const extension = (0, sonar_ts_lib_1.getQueryStringParameter)(search, 'extension');
        if (extension) {
            window.close();
        }
    }
    return null;
};
exports.ExtensionAuthClose = ExtensionAuthClose;
