"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getInitiativeRoute = getInitiativeRoute;
exports.getMonitorRoute = getMonitorRoute;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
function getInitiativeRoute(initiativeId) {
    var _a;
    return sonar_ts_constants_1.FULL_ROUTES.INITIATIVES_DETAIL.replace(sonar_ts_constants_1.PARAMS.ID, (_a = initiativeId === null || initiativeId === void 0 ? void 0 : initiativeId.toString()) !== null && _a !== void 0 ? _a : '');
}
function getMonitorRoute(applicationId) {
    var _a;
    return sonar_ts_constants_1.FULL_ROUTES.MONITORS.replace(sonar_ts_constants_1.PARAMS.ID, (_a = applicationId === null || applicationId === void 0 ? void 0 : applicationId.toString()) !== null && _a !== void 0 ? _a : '');
}
