"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FileModal = void 0;
const React = __importStar(require("react"));
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const FileInput_1 = require("./FileInput");
const antd_1 = require("antd");
const modals_1 = require("./modals");
class FileModal extends React.Component {
    constructor() {
        super(...arguments);
        this.fileFormRef = React.createRef();
        this.handleOnOkClick = () => {
            const { onOk } = this.props;
            const { current } = this.fileFormRef;
            current === null || current === void 0 ? void 0 : current.validateFields().then((values) => {
                onOk(values);
                current.resetFields();
            }).catch((info) => {
                throw new Error(info);
            });
        };
        this.handleOnCancelClick = () => {
            const { onCancel } = this.props;
            const { current } = this.fileFormRef;
            current === null || current === void 0 ? void 0 : current.resetFields();
            onCancel();
        };
        this.getInitialFieldValues = (file) => {
            const { current } = this.fileFormRef;
            const name = (current === null || current === void 0 ? void 0 : current.getFieldValue('name')) || (file === null || file === void 0 ? void 0 : file.name);
            return [
                {
                    name: 'name',
                    value: name
                }
            ];
        };
    }
    render() {
        const { disabled, open, file } = this.props;
        return (React.createElement(modals_1.SonarModal, { className: 'file-modal', okText: file ? sonar_ts_constants_1.COPY_SPECIFIC.UPDATE : sonar_ts_constants_1.COPY_SPECIFIC.SAVE, onCancel: this.handleOnCancelClick, onOk: this.handleOnOkClick, open: open, title: file ? sonar_ts_constants_1.COPY_SPECIFIC.UPDATE_FILE : sonar_ts_constants_1.COPY_SPECIFIC.ADD_A_FILE },
            React.createElement(antd_1.Form, { fields: this.getInitialFieldValues(file), ref: this.fileFormRef },
                React.createElement(FileInput_1.FileInput, { disabled: disabled }))));
    }
}
exports.FileModal = FileModal;
