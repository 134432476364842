"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v3FlowSortOptions = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
exports.v3FlowSortOptions = [
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'name',
        label: sonar_ts_constants_1.COPY_SPECIFIC.NAME
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Descending,
        key: 'isActive',
        label: sonar_ts_constants_1.COPY_SPECIFIC.ACTIVE
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Descending,
        key: 'versionNumber',
        label: sonar_ts_constants_1.COPY_SPECIFIC.VERSION
    }
];
