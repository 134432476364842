"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOrganizations = void 0;
const hooks_1 = require("@lib/hooks");
const sonar_core_1 = require("@sonar-software/sonar-core");
const empty = [];
/**
 * Subscribes to and returns the collection of organizations. Will load related data upon mount if data is not
 * available or forced via `refresh`.
 * @param refresh Determines if the component should force load the latest data upon mount. Defaults to `false`.
 * @returns Array of {@link OrganizationMetadata}.
 */
exports.useOrganizations = (0, hooks_1.createAsyncCollectionStateWithPreloadHook)({
    loadAction: sonar_core_1.coreActions.requestGetCoreOrganizationsAll,
    selector: (state) => state.core.organizations.all.get,
    useTransform: (result) => result.data || empty
});
