"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddNewStakeholderModal = void 0;
const notifications_1 = require("@ui/notifications");
const sonar_http_1 = require("@sonar-software/sonar-http");
const events_1 = require("@lib/events");
const stakeholders_1 = require("@hooks/stakeholders");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const modals_1 = require("@ui/modals");
const react_1 = __importStar(require("react"));
const form_items_1 = require("@ui/form-items");
const _hooks_1 = require("@hooks");
const AddNewStakeholderModal = ({ isOpen, onCancel, popoverPosition, popoverTarget }) => {
    const { isIdle, isReceived, isRequested, request, reset, response } = (0, stakeholders_1.useAddStakeholder)();
    const { Form, form } = (0, _hooks_1.useForm)({
        required: ['name', 'type']
    });
    const loadStakeholders = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreStakeholders);
    (0, react_1.useEffect)(() => {
        if (!isReceived || !response) {
            return;
        }
        if (response.stakeholderId) {
            form.reset();
            loadStakeholders();
        }
    }, [form, isReceived, loadStakeholders, response]);
    const handleOnFinish = (0, react_1.useCallback)(() => {
        const { email, name, type } = form.getFieldsValue();
        if (!form.isValid) {
            return;
        }
        request({
            email,
            name,
            stakeholderType: type
        });
    }, [form, request]);
    const handleOnValuesChange = (0, react_1.useCallback)(() => {
        if (!isIdle && !isRequested) {
            reset();
        }
        form.validate();
    }, [form, isIdle, isRequested, reset]);
    const handleCancel = (0, react_1.useCallback)(() => {
        if (onCancel) {
            onCancel();
        }
        form.reset();
    }, [form, onCancel]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(modals_1.PopoverModal, { className: 'add-new-stakeholder-modal', confirmLoading: isRequested, destroyOnClose: true, okButtonProps: {
                disabled: !form.isValid,
                id: 'add-stakeholder-submit-button'
            }, okText: sonar_ts_constants_1.COPY_SPECIFIC.ADD_NEW_STAKEHOLDER, onCancel: handleCancel, onOk: handleOnFinish, open: isOpen, popoverPosition: popoverPosition, popoverTarget: popoverTarget, title: sonar_ts_constants_1.COPY_SPECIFIC.ADD_A_NEW_STAKEHOLDER },
            react_1.default.createElement(Form, { form: form, layout: 'vertical', onFinish: handleOnFinish, onKeyUp: (0, events_1.handleEnter)(() => form.submit()), onValuesChange: handleOnValuesChange, requiredMark: false },
                react_1.default.createElement(form_items_1.StakeholderName, { autoFocus: true, disabled: isRequested, id: 'add-stakeholder-name', label: sonar_ts_constants_1.COPY_SPECIFIC.NAME }),
                react_1.default.createElement(form_items_1.StakeholderEmail, { disabled: isRequested, id: 'add-stakeholder-email', label: sonar_ts_constants_1.COPY_SPECIFIC.EMAIL }),
                react_1.default.createElement(form_items_1.StakeholderTypeOption, { disabled: isRequested, id: 'add-stakeholder-type', label: sonar_ts_constants_1.COPY_SPECIFIC.TYPE }))),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { byStatusCode: [
                {
                    options: { description: sonar_ts_constants_1.NOTIFICATIONS_COPY.STAKEHOLDER_CONFLICT },
                    statusCode: sonar_http_1.ErrorOrFailStatusCode.Conflict
                }
            ], errored: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, failed: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, received: sonar_ts_constants_1.NOTIFICATIONS_COPY.SUCCESS_POST_STAKEHOLDER, resetAction: reset, stateSelector: stakeholders_1.useAddStakeholder.selectors.state })));
};
exports.AddNewStakeholderModal = AddNewStakeholderModal;
