"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applicationWatchers = exports.applicationReducers = void 0;
const maintenance_1 = require("./maintenance");
const reducer_1 = require("./reset/reducer");
const sidebar_1 = require("./sidebar");
const reducer_2 = require("./auth/reducer");
const xuder_1 = require("@sonar-software/xuder");
const redux_1 = require("redux");
const reducer_3 = require("./periscope/update/reducer");
const sagas_1 = require("./sagas");
exports.applicationReducers = (0, redux_1.combineReducers)({
    auth: reducer_2.authReducers,
    maintenance: maintenance_1.applicationMaintenanceReducers,
    periscope: reducer_3.periscopeReducers,
    reset: reducer_1.applicationResetReducers,
    sidebar: sidebar_1.applicationSidebarReducers
});
exports.applicationWatchers = (0, xuder_1.combineAllWatchers)([
    sagas_1.serviceUnavailableErrorWatcher,
    sagas_1.hardResetApplicationWatcher,
    sagas_1.softResetApplicationWatcher,
    sagas_1.unauthorizedErrorWatcher
]);
