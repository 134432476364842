"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PardotEmailTemplateSearchableSelectionTableV3 = void 0;
const __1 = require("../");
const PardotEmailTemplateRow_1 = require("./PardotEmailTemplateRow");
const PardotEmailTemplateSearch_1 = require("./PardotEmailTemplateSearch");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const tables_1 = require("@ui/tables");
const KeyPagination_1 = require("@ui/KeyPagination");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const PardotEmailTemplateSearchableSelectionTableV3 = ({ parentIndex, resourceIdsUri, uri }) => {
    const scrollableListRef = (0, react_1.useRef)(null);
    const { loading, resource, queryParams, updateParams } = (0, _hooks_1.useQueryResource)({
        defaultParams: KeyPagination_1.keyPaginationDefaultQueryParams,
        fallbackParams: KeyPagination_1.keyPaginationFallbackQueryParams,
        uri
    });
    const selectionData = (0, _hooks_1.useSelectionTable)({
        getRowKey: __1.getRowKey,
        queryParams,
        resourceIdsUri,
        results: resource === null || resource === void 0 ? void 0 : resource.results,
        selectionType: sonar_ts_types_1.SonarObjectType.PardotEmailTemplate
    });
    const renderEmailTemplate = (0, react_1.useCallback)((record) => react_1.default.createElement(PardotEmailTemplateRow_1.PardotEmailTemplateRow, { key: record.sonarObjectId, parentIndex: parentIndex, record: record }), [parentIndex]);
    const results = resource === null || resource === void 0 ? void 0 : resource.results;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(PardotEmailTemplateSearch_1.PardotEmailTemplateSearch, { onChange: updateParams }),
        react_1.default.createElement(tables_1.SelectionTable, { dataSource: results, loading: loading, ref: scrollableListRef, renderRow: renderEmailTemplate, rowKey: __1.getRowKey, selectionData: selectionData, selectionType: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.PARDOT_EMAIL_TEMPLATES }),
        react_1.default.createElement(KeyPagination_1.KeyPagination, { defaultPageSize: Number(queryParams.count), listRef: scrollableListRef, onChange: updateParams, resource: resource })));
};
exports.PardotEmailTemplateSearchableSelectionTableV3 = PardotEmailTemplateSearchableSelectionTableV3;
