"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforceFieldSearchableSelectionTableV3 = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const __1 = require("../");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const tables_1 = require("@ui/tables");
const FieldSortOptions_1 = require("./FieldSortOptions");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const KeyPagination_1 = require("@ui/KeyPagination");
const _1 = require("./");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const dqlTransform = (params) => (0, sonar_ts_lib_1.queryParamsToDynamicQueryLanguageParams)(params, {
    capitalizeOrderingParams: false,
    filterParams: {
        filterByTags: { name: 'tagIds' },
        query: {
            name: params.useInternal ? 'InternalName' : 'DisplayName',
            operator: sonar_ts_types_1.DynamicQueryOperator.Like
        }
    },
    orderingParams: {
        name: params.useInternal ? 'InternalName' : 'DisplayName'
    },
    removeParams: ['useInternal']
});
const SalesforceFieldSearchableSelectionTableV3 = ({ parentIndex, resourceIdsUri, showParents, uri, useDql }) => {
    const scrollableListRef = (0, react_1.useRef)(null);
    const updateReferences = (0, _hooks_1.useAction)(sonar_core_1.coreActions.updateSonarObjectReferences);
    const { loading, resource, queryParams, updateParams } = (0, _hooks_1.useQueryResource)(Object.assign(Object.assign({ defaultParams: Object.assign(Object.assign({}, KeyPagination_1.keyPaginationDefaultQueryParams), { sortBy: FieldSortOptions_1.v3FieldSortOptions[1].key, sortDirection: FieldSortOptions_1.v3FieldSortOptions[1].direction }), fallbackParams: KeyPagination_1.keyPaginationFallbackQueryParams }, useDql ? { apiTransform: dqlTransform } : {}), { uri }));
    (0, react_1.useEffect)(() => {
        if (resource === null || resource === void 0 ? void 0 : resource.results) {
            updateReferences(resource.results.map((result) => ({
                displayName: result.displayName,
                ingestedBy: result.ingestedBy,
                parentDisplayName: result.parentDisplayName,
                parentSonarObjectId: result.parentSonarObjectId,
                populatedBy: result.populatedBy,
                relatedCounts: result.relatedCounts,
                sonarObjectId: result.sonarObjectId,
                sonarObjectType: sonar_ts_types_1.SonarObjectType.SalesforceField,
                tags: result.tagIds
            })));
        }
    }, [resource === null || resource === void 0 ? void 0 : resource.results, updateReferences]);
    const selectionData = (0, _hooks_1.useSelectionTable)({
        getRowKey: __1.getRowKey,
        queryParams: dqlTransform(queryParams),
        resourceIdsUri,
        results: resource === null || resource === void 0 ? void 0 : resource.results,
        selectionType: sonar_ts_types_1.SonarObjectType.SalesforceField
    });
    const renderField = (0, react_1.useCallback)((field) => react_1.default.createElement(_1.PeriscopeFieldRow, { field: field, key: field.sonarObjectId, parentIndex: parentIndex, showParents: showParents, useApiNames: Boolean(queryParams.useInternal) }), [parentIndex, queryParams.useInternal, showParents]);
    const results = resource === null || resource === void 0 ? void 0 : resource.results;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(_1.SalesforceFieldSearch, { onChange: updateParams, queryParams: queryParams, showInternalNameToggle: useDql }),
        react_1.default.createElement(tables_1.SelectionTable, { dataSource: results, forceShowSelection: resourceIdsUri ? undefined : false, loading: loading, ref: scrollableListRef, renderRow: renderField, rowKey: __1.getRowKey, selectionData: selectionData, selectionType: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.FIELDS }),
        react_1.default.createElement(KeyPagination_1.KeyPagination, { defaultPageSize: Number(queryParams.count), listRef: scrollableListRef, onChange: updateParams, resource: resource })));
};
exports.SalesforceFieldSearchableSelectionTableV3 = SalesforceFieldSearchableSelectionTableV3;
