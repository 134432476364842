"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCreateInitiativeTask = useCreateInitiativeTask;
const selectors_1 = require("@lib/selectors");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const useInitiativeReference_1 = require("./useInitiativeReference");
const sonar_core_1 = require("@sonar-software/sonar-core");
const _hooks_1 = require("@hooks");
const react_1 = require("react");
function getNextOrderIndex(tasks) {
    var _a;
    return ((_a = (0, sonar_ts_lib_1.getMax)(tasks.map((task) => task.orderIndex))) !== null && _a !== void 0 ? _a : -1) + 1;
}
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.initiatives.tasks.post);
function useCreateInitiativeTask(initiativeId) {
    const { tasks } = (0, useInitiativeReference_1.useInitiativeReference)(initiativeId);
    const requestBase = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCoreInitiativesTasks);
    const reset = (0, _hooks_1.useAction)(sonar_core_1.coreActions.resetPostCoreInitiativesTasks);
    const isProcessing = (0, _hooks_1.useSonarSelector)(selectors.isRequested);
    const isSuccessful = (0, _hooks_1.useSonarSelector)(selectors.isReceived);
    const result = (0, _hooks_1.useSonarSelector)(selectors.data);
    const nextOrderIndex = (0, react_1.useMemo)(() => getNextOrderIndex(tasks), [tasks]);
    const request = (0, react_1.useCallback)((data) => {
        var _a;
        if (initiativeId) {
            requestBase({
                assignedOn: '',
                assignedTo: (_a = data.assignedTo) !== null && _a !== void 0 ? _a : 0,
                completed: false,
                completedBy: 0,
                completedOn: '',
                initiativeId,
                name: data.name,
                orderIndex: nextOrderIndex
            });
        }
    }, [initiativeId, nextOrderIndex, requestBase]);
    return {
        isProcessing,
        isSuccessful,
        request,
        reset,
        result
    };
}
useCreateInitiativeTask.selectors = selectors;
