"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAsyncState = useAsyncState;
const useSonarSelector_1 = require("./useSonarSelector");
/**
 * Subscribes to a collection of state {@link AsyncSelectors} and provides the associated state data and flags.
 * @param selectors Async selectors collection object for an async state.
 * @returns An {@link UseAsyncStateResult} instance with state data and flags.
 */
function useAsyncState(selectors) {
    const data = (0, useSonarSelector_1.useSonarSelector)(selectors.data);
    const isErrored = (0, useSonarSelector_1.useSonarSelector)(selectors.isErrored);
    const isFailed = (0, useSonarSelector_1.useSonarSelector)(selectors.isFailed);
    const isIdle = (0, useSonarSelector_1.useSonarSelector)(selectors.isIdle);
    const isReceived = (0, useSonarSelector_1.useSonarSelector)(selectors.isReceived);
    const isRequested = (0, useSonarSelector_1.useSonarSelector)(selectors.isRequested);
    return {
        data,
        isErrored,
        isFailed,
        isIdle,
        isReceived,
        isRequested
    };
}
