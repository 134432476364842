"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ChangeRecordsFilter = void 0;
require("./ChangeRecordsFilter.scss");
const ChangeCountsRow_1 = require("./ChangeCountsRow");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const selectors_1 = require("@timeline/@arch/selectors");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const SearchItems_1 = require("@sidebar/SearchItems");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const sonar_core_1 = require("@sonar-software/sonar-core");
const _hooks_1 = require("@hooks");
const sonarObjectTypeOrder = [...SearchItems_1.targetObjectTypeOrder, sonar_ts_types_1.SonarObjectType.SalesforceProfile];
const defaultChangeCounts = {
    discovered: 0,
    modified: 0,
    vanished: 0
};
const addFilter = (objectFilter, objectType, changeType) => {
    objectFilter[objectType] = Object.assign(Object.assign({}, objectFilter[objectType]), { [changeType]: true });
};
const removeFilter = (objectFilter, objectType, changeType) => {
    var _a, _b;
    (_a = objectFilter[objectType]) === null || _a === void 0 ? true : delete _a[changeType];
    if (!Object.keys((_b = objectFilter[objectType]) !== null && _b !== void 0 ? _b : {}).length) {
        delete objectFilter[objectType];
    }
};
const ChangeRecordsFilter = ({ clearSelectedChangeType, highlightedChangeType, onChange, params, selectedChangeType }) => {
    var _a;
    const summary = (0, _hooks_1.useSonarSelector)(selectors_1.getTimelineSummarySelector.data);
    const changeCounts = (0, react_1.useMemo)(() => sonarObjectTypeOrder.filter((sonarObjectType) => { var _a; return (summary === null || summary === void 0 ? void 0 : summary.changeCounts[sonarObjectType]) || ((_a = params.objectFilter) === null || _a === void 0 ? void 0 : _a[sonarObjectType]); }).map((sonarObjectType) => {
        var _a;
        return (Object.assign(Object.assign({}, (_a = summary === null || summary === void 0 ? void 0 : summary.changeCounts[sonarObjectType]) !== null && _a !== void 0 ? _a : defaultChangeCounts), { sonarObjectType }));
    }), [params.objectFilter, summary === null || summary === void 0 ? void 0 : summary.changeCounts]);
    (0, react_1.useEffect)(() => {
        var _a, _b, _c;
        if (selectedChangeType) {
            const objectFilter = Object.assign({}, params.objectFilter);
            const allSelected = Object.keys((_a = summary === null || summary === void 0 ? void 0 : summary.changeCounts) !== null && _a !== void 0 ? _a : []).every((objectType) => { var _a; return (_a = objectFilter[objectType]) === null || _a === void 0 ? void 0 : _a[selectedChangeType]; });
            if (allSelected) {
                Object.keys((_b = summary === null || summary === void 0 ? void 0 : summary.changeCounts) !== null && _b !== void 0 ? _b : []).forEach((objectType) => {
                    removeFilter(objectFilter, objectType, selectedChangeType);
                });
            }
            else {
                Object.keys((_c = summary === null || summary === void 0 ? void 0 : summary.changeCounts) !== null && _c !== void 0 ? _c : []).forEach((objectType) => {
                    addFilter(objectFilter, objectType, selectedChangeType);
                });
            }
            onChange({ objectFilter });
            clearSelectedChangeType();
        }
    }, [clearSelectedChangeType, onChange, params.objectFilter, selectedChangeType, summary === null || summary === void 0 ? void 0 : summary.changeCounts]);
    const onClick = (0, react_1.useCallback)((changeType, sonarObjectType) => {
        var _a;
        const objectFilter = Object.assign({}, params === null || params === void 0 ? void 0 : params.objectFilter);
        if ((_a = objectFilter[sonarObjectType]) === null || _a === void 0 ? void 0 : _a[changeType]) {
            removeFilter(objectFilter, sonarObjectType, changeType);
        }
        else {
            addFilter(objectFilter, sonarObjectType, changeType);
        }
        onChange({ objectFilter });
    }, [onChange, params === null || params === void 0 ? void 0 : params.objectFilter]);
    const onClickObjectType = (0, react_1.useCallback)((sonarObjectType) => {
        var _a;
        const objectFilter = Object.assign({}, params === null || params === void 0 ? void 0 : params.objectFilter);
        if (Object.keys((_a = objectFilter[sonarObjectType]) !== null && _a !== void 0 ? _a : {}).length === 3) {
            delete objectFilter[sonarObjectType];
        }
        else {
            objectFilter[sonarObjectType] = {
                [sonar_core_1.TimelineChangeType.Discovered]: true,
                [sonar_core_1.TimelineChangeType.Modified]: true,
                [sonar_core_1.TimelineChangeType.Vanished]: true
            };
        }
        onChange({ objectFilter });
    }, [onChange, params === null || params === void 0 ? void 0 : params.objectFilter]);
    const clearFilters = (0, react_1.useCallback)(() => {
        onChange({ objectFilter: {} });
    }, [onChange]);
    if (!changeCounts.length) {
        return null;
    }
    const hasFilters = Object.keys((_a = params === null || params === void 0 ? void 0 : params.objectFilter) !== null && _a !== void 0 ? _a : {}).length;
    return (react_1.default.createElement("div", { className: 'change-records-filter' },
        react_1.default.createElement(antd_1.Divider, null), changeCounts === null || changeCounts === void 0 ? void 0 :
        changeCounts.map((counts) => react_1.default.createElement(ChangeCountsRow_1.ChangeCountsRow, { counts: counts, highlightedChangeType: highlightedChangeType, key: counts.sonarObjectType, onClick: onClick, onClickObjectType: onClickObjectType, params: params })),
        react_1.default.createElement(antd_1.Button, { disabled: !hasFilters, onClick: clearFilters }, sonar_ts_constants_1.COPY_SPECIFIC.CLEAR_ENTITY_FILTERS)));
};
exports.ChangeRecordsFilter = ChangeRecordsFilter;
