"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OidcProviderDeleteButton = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const antd_1 = require("antd");
const modals_1 = require("@ui/modals");
const react_1 = __importStar(require("react"));
const OidcProviderDeleteButton = (props) => {
    const { isEnteringNewData, onCleared, onDeleted } = props;
    const handleConfirmDelete = (0, react_1.useCallback)(() => {
        if (onDeleted) {
            onDeleted();
        }
    }, [onDeleted]);
    return (react_1.default.createElement("span", null,
        isEnteringNewData &&
            react_1.default.createElement(antd_1.Button, { className: 'oidc-provider-clear-link', onClick: onCleared, type: 'text' }, sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.CLEAR),
        !isEnteringNewData &&
            react_1.default.createElement(modals_1.ConfirmDeleteIcon, { cancelButtonProps: {
                    value: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.NO
                }, confirmButtonProps: {
                    value: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.YES
                }, iconClassName: 'oidc-provider-delete-link', onConfirm: handleConfirmDelete, title: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.DELETE_OIDC_CONFIG_CONFIRMATION })));
};
exports.OidcProviderDeleteButton = OidcProviderDeleteButton;
