"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiagnosticsWatcher = void 0;
require("./DiagnosticsWatcher.scss");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const text_1 = require("./text");
const sonar_core_1 = require("@sonar-software/sonar-core");
const modals_1 = require("./modals");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const DiagnosticsWatcher = () => {
    const [isModalVisible, setIsModalVisible] = (0, react_1.useState)(false);
    const [diagnosticSummary, setDiagnosticSummary] = (0, react_1.useState)();
    const [diagnosticUnlockKeypresses, setDiagnosticUnlockKeypresses] = (0, react_1.useState)(0);
    const showModal = (0, react_1.useCallback)(() => {
        setIsModalVisible(true);
    }, []);
    const hideModal = (0, react_1.useCallback)(() => {
        setIsModalVisible(false);
    }, []);
    (0, _hooks_1.useOnMount)(() => {
        const listener = (event) => {
            if ((event.ctrlKey || event.metaKey) && event.key === 'F1') {
                setDiagnosticUnlockKeypresses((prev) => prev + 1);
            }
            else {
                setDiagnosticUnlockKeypresses(0);
            }
        };
        window.addEventListener('keydown', listener);
        return () => {
            window.removeEventListener('keydown', listener);
        };
    });
    (0, react_1.useEffect)(() => {
        if (isModalVisible) {
            return;
        }
        if (diagnosticUnlockKeypresses >= 3) {
            setDiagnosticUnlockKeypresses(0);
            (0, sonar_core_1.getDiagnostics)().then((response) => {
                if (response && response.data) {
                    setDiagnosticSummary(response.data.summary);
                }
            });
            showModal();
        }
    }, [diagnosticUnlockKeypresses, isModalVisible, showModal]);
    return (react_1.default.createElement(modals_1.SonarModal, { centered: true, className: 'diagnostics-modal', footer: [
            react_1.default.createElement(text_1.CopyText, { displayText: sonar_ts_constants_1.COPY_SPECIFIC.COPY_TO_CLIPBOARD, key: 'clipboard', text: diagnosticSummary }),
            react_1.default.createElement(antd_1.Button, { key: 'ok', onClick: hideModal, type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.OK)
        ], onCancel: hideModal, onOk: hideModal, open: isModalVisible, title: sonar_ts_constants_1.COPY_SPECIFIC.DIAGNOSTICS },
        react_1.default.createElement("div", { className: 'diagnostics-container' },
            react_1.default.createElement("textarea", { id: 'diagnostics-summary', readOnly: true, value: diagnosticSummary }))));
};
exports.DiagnosticsWatcher = DiagnosticsWatcher;
