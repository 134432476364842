"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applicationResetReducers = applicationResetReducers;
const actions_1 = require("./actions");
const defaultApplicationState = {
    isLoggingOut: false,
    softResetApplication: false
};
function applicationResetReducers(state = defaultApplicationState, action) {
    const { type } = action;
    switch (type) {
        case actions_1.ApplicationActionType.HardReset:
            return Object.assign(Object.assign({}, defaultApplicationState), { isLoggingOut: true });
        case actions_1.ApplicationActionType.Reset:
            return Object.assign(Object.assign({}, state), { softResetApplication: true });
        case actions_1.ApplicationActionType.ResetComplete:
            return Object.assign(Object.assign({}, state), { softResetApplication: false });
        case actions_1.ApplicationActionType.SoftReset:
            return Object.assign({}, defaultApplicationState);
        default:
            return state;
    }
}
