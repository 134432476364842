"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.genericProcessingFailure = void 0;
exports.useProcessing = useProcessing;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = require("react");
const defaultState = {
    isProcessing: false
};
exports.genericProcessingFailure = {
    message: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT,
    success: false
};
function useProcessing() {
    const [state, setState] = (0, react_1.useState)(defaultState);
    const reset = (0, react_1.useCallback)(() => {
        setState(defaultState);
    }, []);
    const start = (0, react_1.useCallback)(() => {
        setState({ isProcessing: true });
    }, []);
    const stop = (0, react_1.useCallback)((result) => {
        setState({
            isProcessing: false,
            result
        });
    }, []);
    return {
        reset,
        start,
        state,
        stop
    };
}
