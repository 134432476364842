"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResendUserInviteButton = void 0;
require("./UserControls.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_admin_1 = require("@sonar-software/sonar-admin");
const antd_1 = require("antd");
const text_1 = require("@ui/text");
const selectors_1 = require("@lib/selectors");
const icons_1 = require("@ant-design/icons");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getInvitationLinkSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.admin.users.byId.links.invitation.get);
const postSendInvitationLinkSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.admin.users.byId.links.invitation.send.post);
const ResendUserInviteButton = (props) => {
    const { userId, userIsLocked, shardName } = props;
    const userInviteLink = (0, _hooks_1.useSonarSelector)(getInvitationLinkSelectors.data);
    const getUserInviteLink = (0, _hooks_1.useAction)(sonar_admin_1.adminActions.requestGetAdminUsersByIdLinksInvitation);
    const handleCopyUserInviteClicked = (0, react_1.useCallback)(() => {
        getUserInviteLink({
            shardName,
            userId
        });
    }, [getUserInviteLink, userId, shardName]);
    const [sendClicked, setSendClicked] = (0, react_1.useState)(false);
    const sendUserInviteEmailIsRequested = (0, _hooks_1.useSonarSelector)(postSendInvitationLinkSelectors.isRequested);
    const sendUserInviteEmail = (0, _hooks_1.useAction)(sonar_admin_1.adminActions.requestPostAdminUsersByIdLinksInvitationSend);
    const handleSendUserInviteClicked = (0, react_1.useCallback)(() => {
        sendUserInviteEmail({
            shardName,
            userId
        });
        setSendClicked(true);
    }, [sendUserInviteEmail, userId, shardName]);
    (0, react_1.useEffect)(() => {
        if (sendClicked && !sendUserInviteEmailIsRequested) {
            setSendClicked(false);
        }
    }, [sendUserInviteEmailIsRequested, setSendClicked, sendClicked]);
    return (react_1.default.createElement(antd_1.Button.Group, null,
        react_1.default.createElement(antd_1.Button, { className: 'send-user-invite-button', disabled: sendClicked || userIsLocked, icon: react_1.default.createElement(icons_1.MailOutlined, null), onClick: handleSendUserInviteClicked }, sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.RESEND_USER_INVITE),
        react_1.default.createElement(text_1.CopyText, { buttonOnly: true, onClick: handleCopyUserInviteClicked, text: userInviteLink === null || userInviteLink === void 0 ? void 0 : userInviteLink.link, tooltip: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.COPY_USER_INVITE_LINK })));
};
exports.ResendUserInviteButton = ResendUserInviteButton;
