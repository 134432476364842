"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Migrate = void 0;
const css = __importStar(require("css/exports.module.scss"));
require("./Migrate.scss");
const notifications_1 = require("@ui/notifications");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const general_1 = require("@ui/general");
const _hooks_1 = require("@hooks");
const { Option } = antd_1.Select;
const fromOrgIdName = 'fromOrgId';
const toOrgIdName = 'toOrgId';
function isDryRunEnabled(fromOrgId, toOrgId) {
    return typeof fromOrgId !== 'undefined' && typeof toOrgId !== 'undefined';
}
const cloneTagsSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.tags.clone.post);
const Migrate = () => {
    const [fromOrgId, setFromOrgId] = (0, react_1.useState)(undefined);
    const [toOrgId, setToOrgId] = (0, react_1.useState)(undefined);
    const [showWarnings, setShowWarnings] = (0, react_1.useState)(false);
    const [isDryRun, setIsDryRun] = (0, react_1.useState)(false);
    const organizations = (0, _hooks_1.useSalesforceOrganizations)();
    const cloneResponse = (0, _hooks_1.useSonarSelector)(cloneTagsSelectors.data);
    const [form] = antd_1.Form.useForm();
    const cloneTags = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCoreTagsClone);
    (0, react_1.useEffect)(() => {
        if (isDryRun && fromOrgId && toOrgId) {
            cloneTags({
                deleteTagRelationsExistingInToOrg: true,
                dryRun: true,
                fromOrgId,
                toOrgId
            });
            setShowWarnings(true);
        }
    }, [cloneTags, fromOrgId, isDryRun, toOrgId]);
    const handleOnMigrateFinish = (0, react_1.useCallback)(() => {
        if (!fromOrgId || !toOrgId) {
            return;
        }
        cloneTags({
            deleteTagRelationsExistingInToOrg: true,
            dryRun: false,
            fromOrgId,
            toOrgId
        });
        setFromOrgId(undefined);
        setToOrgId(undefined);
        setShowWarnings(false);
        form.resetFields([fromOrgIdName, toOrgIdName]);
    }, [cloneTags, form, fromOrgId, toOrgId]);
    const validateOrganizationIds = (0, react_1.useCallback)((_, value) => {
        if (value) {
            setShowWarnings(false);
            return Promise.resolve();
        }
        return Promise.reject(sonar_ts_constants_1.ORGANIZATIONS_MIGRATION.MESSAGE);
    }, []);
    const handleOnFromOrganizationChange = (0, react_1.useCallback)((newFromOrgId) => {
        if (newFromOrgId === toOrgId) {
            setFromOrgId(newFromOrgId);
            setToOrgId(undefined);
            setShowWarnings(false);
            form.resetFields([toOrgIdName]);
        }
        else {
            setFromOrgId(newFromOrgId);
            setIsDryRun(isDryRunEnabled(newFromOrgId, toOrgId));
        }
    }, [form, toOrgId]);
    const handleOnToOrganizationChange = (0, react_1.useCallback)((newToOrgId) => {
        setToOrgId(newToOrgId);
        setIsDryRun(isDryRunEnabled(fromOrgId, newToOrgId));
    }, [fromOrgId]);
    const isOptionDisabled = (0, react_1.useCallback)((numberRawId) => fromOrgId === numberRawId, [fromOrgId]);
    const renderSelectItems = (0, react_1.useCallback)((disabledContext) => (organizations !== null && organizations !== void 0 ? organizations : [])
        .filter((org) => org.hasSalesforceData)
        .map((value) => {
        const { id, name } = value;
        const disabled = disabledContext ? disabledContext(id) : false;
        return (react_1.default.createElement(Option, { disabled: disabled, key: id.toString(), title: name, value: id }, name));
    }), [organizations]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(general_1.ViewArea, { direction: 'vertical', id: 'migrate-tags' },
            react_1.default.createElement(general_1.SonarCard, { className: 'migrate', title: sonar_ts_constants_1.COPY_SPECIFIC.MIGRATE_TAGS },
                react_1.default.createElement(antd_1.Form, { form: form, onFinish: handleOnMigrateFinish },
                    react_1.default.createElement(antd_1.Row, { gutter: [Number(css.size16), 0] },
                        react_1.default.createElement(antd_1.Col, { span: 12 },
                            react_1.default.createElement(antd_1.Form.Item, { name: fromOrgIdName, rules: [
                                    {
                                        validator: validateOrganizationIds
                                    }
                                ] },
                                react_1.default.createElement(antd_1.Select, { className: 'organizations', onChange: handleOnFromOrganizationChange, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FROM_ORGANIZATION, value: fromOrgId }, renderSelectItems()))),
                        react_1.default.createElement(antd_1.Col, { span: 12 },
                            react_1.default.createElement(antd_1.Form.Item, { name: toOrgIdName, rules: [
                                    {
                                        validator: validateOrganizationIds
                                    }
                                ] },
                                react_1.default.createElement(antd_1.Select, { className: 'organizations', onChange: handleOnToOrganizationChange, placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.TO_ORGANIZATION, value: toOrgId }, renderSelectItems(isOptionDisabled))))),
                    react_1.default.createElement(antd_1.Row, null,
                        react_1.default.createElement(antd_1.Button, { htmlType: 'submit', id: 'tags-migrate-button', type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.MIGRATE_TAGS))),
                react_1.default.createElement(react_if_1.When, { condition: showWarnings && (cloneResponse === null || cloneResponse === void 0 ? void 0 : cloneResponse.logs.length) },
                    react_1.default.createElement(antd_1.Alert, { className: 'warnings', description: cloneResponse === null || cloneResponse === void 0 ? void 0 : cloneResponse.logs.map((warning) => react_1.default.createElement("div", { className: 'warning', key: warning }, warning)), message: 'Warning', showIcon: true, type: 'warning' })))),
        react_1.default.createElement(react_if_1.When, { condition: !isDryRun },
            react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_POST_MIGRATE_TAG, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_POST_MIGRATE_TAG, received: sonar_ts_constants_1.NOTIFICATIONS_COPY.SUCCESS_POST_MIGRATE_TAG, resetRawAction: sonar_core_1.coreActions.resetPostCoreTagsClone, stateSelector: cloneTagsSelectors.state }))));
};
exports.Migrate = Migrate;
