"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.timelineSortOptions = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
exports.timelineSortOptions = [
    {
        direction: 'descending',
        key: 'date',
        label: sonar_ts_constants_1.COPY_SPECIFIC.DETECTED_DATE
    },
    {
        direction: 'ascending',
        key: 'modifiedBy',
        label: sonar_ts_constants_1.COPY_SPECIFIC.MODIFIED_BY
    }
];
