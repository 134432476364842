"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitiativeWorkflow = useInitiativeWorkflow;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_router_dom_1 = require("react-router-dom");
/**
 * Checks if the application is currently on an initiative detail route.
 * If provided an id, ensures that it is the current detail route rather than any detail route.
 * @param id Optional initiative id to validate the route for. Defaults to `undefined`.
 * @returns True if the Initiative Workflow feature flag is enabled and the application
 * route is currently for either any or a specific initiative detail view.
 */
function useInitiativeWorkflow(id = undefined) {
    const { pathname } = (0, react_router_dom_1.useLocation)();
    const lowerPath = pathname.toLowerCase();
    if (!lowerPath.includes(`/${sonar_ts_constants_1.BASE_ROUTES.INITIATIVES}/`)) {
        return false;
    }
    if (id) {
        return lowerPath.endsWith(`/${id}`);
    }
    return !lowerPath.endsWith('/');
}
