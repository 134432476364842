"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAsyncCollection = useAsyncCollection;
const react_1 = require("react");
const useSonarSelector_1 = require("./useSonarSelector");
const selectors_1 = require("../lib/selectors");
function useAsyncCollection(stateSelector) {
    const resultSelector = (0, react_1.useMemo)(() => (0, selectors_1.createCollectionSelector)((0, selectors_1.createDataSelector)(stateSelector)), [stateSelector]);
    const stateCollection = (0, useSonarSelector_1.useSonarSelector)(resultSelector);
    return (0, react_1.useMemo)(() => stateCollection !== null && stateCollection !== void 0 ? stateCollection : [], [stateCollection]);
}
