"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncStatusNotification = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const react_1 = require("react");
const _hooks_1 = require("@hooks");
const SyncStatusNotification = () => {
    const latestSync = (0, _hooks_1.useLatestSalesforceSync)();
    const { errorNotification, infoNotification, successNotification } = (0, _hooks_1.useAppNotifications)();
    const syncStatus = latestSync === null || latestSync === void 0 ? void 0 : latestSync.status;
    const [showSyncInProgress, setShowSyncInProgress] = (0, react_1.useState)((0, sonar_ts_lib_1.isSyncInProgress)(syncStatus));
    (0, react_1.useEffect)(() => {
        const isInProgress = (0, sonar_ts_lib_1.isSyncInProgress)(syncStatus);
        if (showSyncInProgress && !isInProgress) {
            if ((0, sonar_ts_lib_1.isSyncCompleted)(syncStatus)) {
                successNotification(sonar_ts_constants_1.COPY_SPECIFIC.SALESFORCE_SYNC_SUCCESS, { message: sonar_ts_constants_1.COPY_SPECIFIC.SYNC_COMPLETED });
            }
            else if ((0, sonar_ts_lib_1.isSyncFailed)(syncStatus)) {
                errorNotification(sonar_ts_constants_1.COPY_SPECIFIC.SALESFORCE_SYNC_FAILURE, { message: sonar_ts_constants_1.COPY_SPECIFIC.SYNC_COMPLETED });
            }
        }
        setShowSyncInProgress(isInProgress);
    }, [syncStatus, showSyncInProgress, infoNotification, successNotification, errorNotification]);
    (0, react_1.useEffect)(() => {
        if ((0, sonar_ts_lib_1.isSyncInProgress)(syncStatus)) {
            infoNotification(sonar_ts_constants_1.COPY_SPECIFIC.SALESFORCE_SYNC_IN_PROGRESS, { message: sonar_ts_constants_1.COPY_SPECIFIC.SYNC_IN_PROGRESS });
        }
    }, [infoNotification, syncStatus]);
    return null;
};
exports.SyncStatusNotification = SyncStatusNotification;
