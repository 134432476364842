"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationPreferences = void 0;
const notifications_1 = require("@ui/notifications");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const react_if_1 = require("react-if");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.users.byId.notificationPrefs.get);
const patchStateSelector = (0, selectors_1.createSonarSelector)((state) => state.core.users.byId.notificationPrefs.patch);
const NotificationPreferences = () => {
    const { id: userId, isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const followersEnabled = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.Followers);
    const { isRequested, data } = (0, _hooks_1.useAsyncStateWithPreload)(selectors, sonar_core_1.coreActions.requestGetCoreUsersByIdNotificationPreferences, true, { userId });
    const patchPreferences = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreUsersByIdNotificationPreferences);
    const handleDailySyncChange = (0, react_1.useCallback)((value) => {
        patchPreferences({
            allowDailySyncEmails: value,
            userId
        });
    }, [patchPreferences, userId]);
    const handleMentionChange = (0, react_1.useCallback)((value) => {
        patchPreferences({
            allowMentionEmails: value,
            userId
        });
    }, [patchPreferences, userId]);
    const handleFollowedAlertsChange = (0, react_1.useCallback)((value) => {
        patchPreferences({
            receiveFollowerAlerts: value,
            userId
        });
    }, [patchPreferences, userId]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { id: 'notification-preferences' },
            react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, { spinning: isRequested },
                react_1.default.createElement(antd_1.Form, { colon: false },
                    react_1.default.createElement(antd_1.Form.Item, { className: 'horizontal-form-item', label: sonar_ts_constants_1.NOTIFICATION_PREFERENCES_COPY.DAILY_SYNC_EMAIL, name: 'allowDailySyncEmails' },
                        react_1.default.createElement(antd_1.Switch, { checked: data === null || data === void 0 ? void 0 : data.allowDailySyncEmails, onChange: handleDailySyncChange })),
                    react_1.default.createElement(antd_1.Form.Item, { className: 'horizontal-form-item', label: sonar_ts_constants_1.NOTIFICATION_PREFERENCES_COPY.EMAIL_WHEN_AT_MENTIONED, name: 'allowMentionEmails' },
                        react_1.default.createElement(antd_1.Switch, { checked: data === null || data === void 0 ? void 0 : data.allowMentionEmails, onChange: handleMentionChange })),
                    react_1.default.createElement(react_if_1.When, { condition: followersEnabled && !isReadOnly },
                        react_1.default.createElement(antd_1.Form.Item, { className: 'horizontal-form-item', label: sonar_ts_constants_1.NOTIFICATION_PREFERENCES_COPY.ALERTS_FOR_FOLLOWED_CHANGES, name: 'receiveFollowerAlerts' },
                            react_1.default.createElement(antd_1.Switch, { checked: data === null || data === void 0 ? void 0 : data.receiveFollowerAlerts, onChange: handleFollowedAlertsChange })))))),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_PATCH_USER_PREFERENCES, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_PATCH_USER_PREFERENCES, received: sonar_ts_constants_1.NOTIFICATIONS_COPY.SUCCESS_PATCH_USER_PREFERENCES, resetRawAction: sonar_core_1.coreActions.resetPatchCoreUsersByIdNotificationPreferences, stateSelector: patchStateSelector })));
};
exports.NotificationPreferences = NotificationPreferences;
