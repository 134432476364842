"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateFollowing = useUpdateFollowing;
const sonar_core_1 = require("@sonar-software/sonar-core");
const useAction_1 = require("@hooks/useAction");
const useFollowingIdReference_1 = require("./useFollowingIdReference");
const react_1 = require("react");
function useUpdateFollowing(sonarObjectIds = []) {
    const following = (0, useFollowingIdReference_1.useFollowingIdReference)();
    const follow = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestPostCoreFollow);
    const unfollow = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestPostCoreUnfollow);
    const { followed, unfollowed } = (0, react_1.useMemo)(() => {
        const result = {
            followed: [],
            unfollowed: []
        };
        for (const sonarObjectId of sonarObjectIds) {
            if (following === null || following === void 0 ? void 0 : following[sonarObjectId]) {
                result.followed.push(sonarObjectId);
            }
            else {
                result.unfollowed.push(sonarObjectId);
            }
        }
        return result;
    }, [following, sonarObjectIds]);
    return {
        follow: (ids) => follow({ sonarObjectIds: ids !== null && ids !== void 0 ? ids : unfollowed }),
        followed,
        unfollow: (ids) => unfollow({ sonarObjectIds: ids !== null && ids !== void 0 ? ids : followed }),
        unfollowed
    };
}
