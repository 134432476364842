"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ComplianceCategoryBulkSelector = void 0;
/* eslint-disable no-bitwise */
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const filters_1 = require("@periscopes/general/filters");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const enumOptions = (0, sonar_ts_lib_1.enumNumericValues)(sonar_core_1.ComplianceCategory)
    .filter((category) => category !== sonar_core_1.ComplianceCategory.Any);
const options = enumOptions
    .map((category) => ({
    label: sonar_core_1.ComplianceCategory[category],
    value: category
}));
function getValues(value) {
    if (value === undefined) {
        return [];
    }
    const results = [];
    for (const option of enumOptions) {
        if (value & option) {
            results.push(option);
        }
    }
    return results.length ? results : [0];
}
const ComplianceCategoryBulkSelector = (_a) => {
    var { onChange, value } = _a, props = __rest(_a, ["onChange", "value"]);
    const handleChange = (0, react_1.useCallback)((change) => {
        if (change[change.length - 1] === sonar_core_1.ComplianceCategory.None) {
            onChange(sonar_core_1.ComplianceCategory.None);
        }
        else {
            onChange(change.length ? change.reduce((prev, curr) => curr + prev, 0) : undefined);
        }
    }, [onChange]);
    return (react_1.default.createElement(filters_1.OptionFilter, Object.assign({}, props, { mode: 'multiple', onChange: handleChange, options: options, placeholder: sonar_ts_constants_1.COPY_GENERIC.SELECT(sonar_ts_constants_1.COPY_SPECIFIC.COMPLIANCE_CATEGORIES), value: getValues(value) })));
};
exports.ComplianceCategoryBulkSelector = ComplianceCategoryBulkSelector;
