"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArchiveButton = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const icons_1 = require("@ant-design/icons");
const react_router_dom_1 = require("react-router-dom");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const { confirm } = antd_1.Modal;
const deleteSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.initiatives.byId.delete);
const ArchiveButton = ({ initiativeId }) => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const { isRequested, isReceived } = (0, _hooks_1.useAsyncState)(deleteSelectors);
    const deleteInitiative = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCoreInitiativesById);
    const modalRef = (0, react_1.useRef)();
    (0, react_1.useEffect)(() => {
        var _a;
        if (isReceived) {
            (_a = modalRef.current) === null || _a === void 0 ? void 0 : _a.destroy();
            navigate(sonar_ts_constants_1.FULL_ROUTES.INITIATIVES);
        }
    }, [isReceived, navigate]);
    const handleArchive = () => {
        modalRef.current = confirm({
            cancelText: sonar_ts_constants_1.COPY_GENERIC.CANCEL,
            content: sonar_ts_constants_1.COPY_SPECIFIC.ARE_YOU_SURE_ARCHIVE_INITIATVE,
            icon: react_1.default.createElement(icons_1.ExclamationCircleOutlined, null),
            okText: sonar_ts_constants_1.COPY_GENERIC.CONFIRM,
            okType: 'danger',
            onOk: () => deleteInitiative({ id: initiativeId }),
            title: sonar_ts_constants_1.COPY_SPECIFIC.ARCHIVE_INITIATIVE
        });
    };
    return (react_1.default.createElement(antd_1.Button, { danger: true, id: 'initiative-archive-button', loading: isRequested, onClick: handleArchive, type: 'text' }, sonar_ts_constants_1.COPY_SPECIFIC.ARCHIVE_INITIATIVE));
};
exports.ArchiveButton = ArchiveButton;
