"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforceSetupPanel = void 0;
require("./SalesforceSetupPanel.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const selectors_1 = require("@lib/selectors");
const SalesforceOrganizationSyncConfigurationButton_1 = require("./SalesforceOrganizationSyncConfigurationButton");
const SalesforceOrganizationSyncControls_1 = require("./SalesforceOrganizationSyncControls");
const SalesforceOrganizationTypeTag_1 = require("./SalesforceOrganizationTypeTag");
const SalesforceOrgSyncSecurityAlertsControls_1 = require("./SalesforceOrgSyncSecurityAlertsControls");
const SonarTable_1 = require("@ui/SonarTable");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getAllSalesforceOrganizationsSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.organizations.salesforce.all.get);
const salesforceSetupTableColumns = [
    {
        dataIndex: 'name',
        key: 'name',
        title: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.ORGANZATION_NAME,
        width: 300
    },
    {
        dataIndex: 'isSandbox',
        key: 'sandbox',
        render: (isSandbox) => react_1.default.createElement(SalesforceOrganizationTypeTag_1.SalesforceOrganizationTypeTag, { isSandbox: isSandbox }),
        title: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.IS_SANDBOX,
        width: 100
    },
    {
        dataIndex: 'instanceUrl',
        key: 'instanceUrl',
        render: (instanceUrl) => react_1.default.createElement("a", { href: instanceUrl }, instanceUrl),
        title: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.INSTANCE_URL,
        width: 300
    },
    {
        dataIndex: 'salesforceUsername',
        key: 'salesforceUsername',
        title: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.SALESFORCE_USERNAME,
        width: 300
    },
    {
        dataIndex: 'id',
        key: 'id',
        title: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.ID,
        width: 75
    },
    {
        align: 'center',
        key: 'importConfig',
        render: (organization) => react_1.default.createElement(SalesforceOrganizationSyncConfigurationButton_1.SalesforceOrganizationSyncConfigurationButton, { organizationId: organization === null || organization === void 0 ? void 0 : organization.id, organizationName: organization === null || organization === void 0 ? void 0 : organization.name, shardName: organization.shardName }),
        width: 100
    },
    {
        dataIndex: 'latestIndexTime',
        key: 'lastSync',
        render: (latestIndexTime, organization) => react_1.default.createElement(SalesforceOrganizationSyncControls_1.SalesforceOrganizationSyncControls, { organization: organization, shardName: organization.shardName }),
        title: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.LAST_SYNC,
        width: 600
    },
    {
        key: 'syncSecurityAlerts',
        render: (organization) => react_1.default.createElement(SalesforceOrgSyncSecurityAlertsControls_1.SalesforceOrgSyncSecurityAlertsControls, { organization: organization }),
        title: sonar_ts_constants_1.ADMIN_DASHBOARD_COPY.SYNC_SECURITY_ALERTS,
        width: 100
    }
];
const SalesforceSetupPanel = (props) => {
    const { id, searchDetails } = props;
    const allSalesforceOrganizations = (0, _hooks_1.useSonarSelector)(getAllSalesforceOrganizationsSelectors.data);
    const [allSalesforceOrganizationsWithShards, setAllSalesforceOrganizationsWithShards] = (0, react_1.useState)([]);
    const getAllSalesforceOrganizations = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreAllSalesforceOrganizationsByCustomer);
    const refreshOrganizations = (0, react_1.useCallback)(() => {
        if (searchDetails) {
            const { customerId, shardName } = searchDetails;
            getAllSalesforceOrganizations({
                customerId,
                shardName
            });
        }
    }, [searchDetails, getAllSalesforceOrganizations]);
    (0, react_1.useEffect)(() => {
        if (searchDetails) {
            const orgsWithShardNames = [];
            if (allSalesforceOrganizations) {
                const { shardName } = searchDetails;
                allSalesforceOrganizations.organizations.forEach((element) => {
                    orgsWithShardNames.push(Object.assign({ shardName }, element));
                });
            }
            setAllSalesforceOrganizationsWithShards(orgsWithShardNames);
        }
    }, [allSalesforceOrganizations, searchDetails]);
    (0, react_1.useEffect)(() => {
        refreshOrganizations();
    }, [refreshOrganizations]);
    (0, _hooks_1.useInterval)(refreshOrganizations, 5000);
    return (react_1.default.createElement(SonarTable_1.SonarTable, { columns: salesforceSetupTableColumns, dataSource: allSalesforceOrganizationsWithShards, id: id, pagination: false, rowKey: 'id', scroll: { x: 'max-content' } }));
};
exports.SalesforceSetupPanel = SalesforceSetupPanel;
