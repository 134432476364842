"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SyncOrganizationView = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const general_1 = require("./general");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const SyncOrganizationView = () => {
    const organizations = (0, _hooks_1.useOrganizations)();
    const switchOrganization = (0, _hooks_1.useSwitchOrganization)();
    const { progress, updateProgress } = (0, _hooks_1.useOnboardingProgress)();
    const refreshOrgs = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreOrganizationsAll);
    const handleReturn = (0, react_1.useCallback)(() => {
        var _a, _b;
        refreshOrgs();
        const lastUsedOrgId = localStorage.getItem(sonar_ts_types_1.LocalStorageKey.LastUsedOrg);
        if (lastUsedOrgId && !isNaN(Number(lastUsedOrgId))) {
            localStorage.removeItem(sonar_ts_types_1.LocalStorageKey.LastUsedOrg);
            switchOrganization({ organizationId: Number(lastUsedOrgId) });
        }
        else {
            const targetOrganizationId = (_a = progress === null || progress === void 0 ? void 0 : progress.organizationId) !== null && _a !== void 0 ? _a : (_b = organizations === null || organizations === void 0 ? void 0 : organizations.filter((org) => org.hasSalesforceData)[0]) === null || _b === void 0 ? void 0 : _b.id;
            switchOrganization({
                organizationId: targetOrganizationId
            });
        }
        updateProgress({
            isSalesforceConnected: true,
            isSalesforceSynced: true
        });
    }, [organizations, progress === null || progress === void 0 ? void 0 : progress.organizationId, refreshOrgs, switchOrganization, updateProgress]);
    return (react_1.default.createElement(general_1.SyncView, { title: sonar_ts_constants_1.ONBOARDING_SYNC_COPY.SYNCING_ORG },
        react_1.default.createElement(antd_1.Space, { align: 'center', className: 'button-wrapper' },
            react_1.default.createElement(antd_1.Button, { onClick: handleReturn, type: 'primary' }, sonar_ts_constants_1.ONBOARDING_CONNECT_COPY.RETURN_TO_DASHBOARD))));
};
exports.SyncOrganizationView = SyncOrganizationView;
