"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DailySyncToggle = void 0;
require("./DailySyncToggle.scss");
const general_1 = require("@ui/general");
const notifications_1 = require("@ui/notifications");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const _hooks_1 = require("@hooks");
const DailySyncToggle = () => {
    const organization = (0, _hooks_1.useCurrentOrganization)();
    const { isErrored, isFailed, isSuccessful, request, reset } = (0, _hooks_1.useUpdateOrganization)();
    const [toggleClicked, setToggleClicked] = (0, react_1.useState)(false);
    const [enableDailySyncVisible, setEnableDailySyncVisible] = (0, react_1.useState)(!(organization === null || organization === void 0 ? void 0 : organization.dailySyncEnabled));
    (0, react_1.useEffect)(() => {
        if ((organization === null || organization === void 0 ? void 0 : organization.id) && (isErrored || isFailed)) {
            setToggleClicked(false);
            reset();
            request({
                dailySyncEnabled: false,
                organizationId: organization.id
            });
        }
    }, [organization === null || organization === void 0 ? void 0 : organization.id, isErrored, isFailed, reset, request]);
    (0, react_1.useEffect)(() => {
        if (isSuccessful && enableDailySyncVisible) {
            setTimeout(() => setEnableDailySyncVisible(false), 2500);
        }
    }, [enableDailySyncVisible, isSuccessful]);
    const handleClick = (0, react_1.useCallback)(() => {
        if (!(organization === null || organization === void 0 ? void 0 : organization.id)) {
            return;
        }
        setToggleClicked(true);
        request({
            dailySyncEnabled: true,
            organizationId: organization.id
        });
    }, [organization === null || organization === void 0 ? void 0 : organization.id, request]);
    if (!enableDailySyncVisible) {
        return null;
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { id: 'daily-sync-toggle' },
            react_1.default.createElement(general_1.AsyncStateAlert, { received: sonar_ts_constants_1.NOTIFICATIONS_COPY.SUCCESS_POST_ORGANIZATION, stateSelector: _hooks_1.useUpdateOrganization.selectors.state }),
            !toggleClicked && sonar_ts_constants_1.COPY_SPECIFIC.DAILY_SYNCS_DISABLED,
            react_1.default.createElement(antd_1.Row, { justify: 'center' }, !toggleClicked && sonar_ts_constants_1.COPY_SPECIFIC.ACTIVATE_DAILY_SYNCS),
            react_1.default.createElement(antd_1.Row, { justify: 'center' },
                react_1.default.createElement(antd_1.Switch, { checked: toggleClicked, checkedChildren: 'Syncs Enabled!', className: 'enable-daily-sync-toggle', disabled: toggleClicked, onClick: handleClick, unCheckedChildren: 'Syncs Disabled' }))),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_POST_ORGANIZATION, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_POST_ORGANIZATION, stateSelector: _hooks_1.useUpdateOrganization.selectors.state })));
};
exports.DailySyncToggle = DailySyncToggle;
