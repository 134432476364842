"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorForm = exports.deleteMonitorSelectors = exports.patchMonitorSelectors = exports.postMonitorSelectors = void 0;
require("./MonitorForm.scss");
const xuder_1 = require("@sonar-software/xuder");
const selectors_1 = require("@lib/selectors");
const lodash_1 = require("lodash");
const icons_1 = require("@ant-design/icons");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const sonar_core_1 = require("@sonar-software/sonar-core");
const monitors_1 = require("../monitors");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
exports.postMonitorSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.monitors.application.post);
exports.patchMonitorSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.monitors.application.byId.patch);
exports.deleteMonitorSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.monitors.application.byId.delete);
exports.MonitorForm = (0, react_1.forwardRef)(({ applicationId, isFormList = false, monitors, onFinish, onValuesChange }, ref) => {
    const postMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCorePulseApplicationMonitors);
    const resetPostMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.resetPostCorePulseApplicationMonitors);
    const patchMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCorePulseApplicationMonitorsById);
    const resetPatchMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.resetPatchCorePulseApplicationMonitorsById);
    const deleteMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCorePulseApplicationMonitorsById);
    const resetDeleteMonitor = (0, _hooks_1.useAction)(sonar_core_1.coreActions.resetDeleteCorePulseApplicationMonitorsById);
    const [adding, setAdding] = (0, react_1.useState)(false);
    const [monitorList, setMonitorList] = (0, react_1.useState)(monitors !== null && monitors !== void 0 ? monitors : []);
    const previousMonitors = (0, _hooks_1.usePrevious)(monitors);
    const { form } = (0, _hooks_1.useFormList)({
        formListName: 'monitors',
        keyName: 'internalKey',
        required: ['name']
    });
    const menuItems = (0, monitors_1.getMenuItems)(monitorList);
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    (0, react_1.useEffect)(() => {
        if (monitors && !(0, lodash_1.isEqual)(monitors, previousMonitors)) {
            const internalMonitors = monitors.map((monitor, index) => {
                var _a;
                const existingMonitor = monitorList.find((x) => x.id === monitor.id);
                const isInEdit = (_a = form.state.find((x) => x.key === monitor.id)) === null || _a === void 0 ? void 0 : _a.isInEdit;
                // If the existing monitor is in Edit state then it was being edited,
                // but the user saved a different monitor before saving this one.
                const monitorData = isInEdit && existingMonitor ? existingMonitor : monitor;
                return Object.assign(Object.assign({}, monitorData), { internalKey: monitor.id || index });
            });
            // New Monitor is being added, but someone saved a different monitor before saving the new one
            if (adding) {
                const newMonitor = form.getFieldValue(['monitors', monitorList.length - 1]);
                internalMonitors.push(newMonitor);
            }
            setMonitorList(internalMonitors);
            form.setFields([
                {
                    name: ['monitors'],
                    value: internalMonitors
                }
            ]);
        }
    }, [monitors]);
    (0, react_1.useEffect)(() => {
        form.validate();
        if (onValuesChange) {
            onValuesChange();
        }
    }, [form, monitorList, onValuesChange]);
    const updateMonitorList = (0, react_1.useCallback)(() => {
        const existingMonitors = form.getFieldValue('monitors');
        if (existingMonitors) {
            setMonitorList(existingMonitors);
        }
    }, [form]);
    const handleOnFinish = (0, react_1.useCallback)(() => {
        updateMonitorList();
        if (onFinish) {
            onFinish();
        }
    }, [onFinish, updateMonitorList]);
    const handleOnError = (0, react_1.useCallback)(() => {
        setMonitorList(monitors);
        form.setFields([
            {
                name: ['monitors'],
                value: monitors
            }
        ]);
    }, [form, monitors]);
    const postProcess = (0, _hooks_1.useAsyncProcessing)({
        resetAction: resetPostMonitor,
        stateSelector: exports.postMonitorSelectors.state,
        statusOptions: {
            [xuder_1.AsyncStateStatus.Received]: handleOnFinish,
            [xuder_1.AsyncStateStatus.Errored]: handleOnError,
            [xuder_1.AsyncStateStatus.Failed]: handleOnError
        }
    });
    const patchProcess = (0, _hooks_1.useAsyncProcessing)({
        resetAction: resetPatchMonitor,
        stateSelector: exports.patchMonitorSelectors.state,
        statusOptions: {
            [xuder_1.AsyncStateStatus.Received]: handleOnFinish,
            [xuder_1.AsyncStateStatus.Errored]: handleOnError,
            [xuder_1.AsyncStateStatus.Failed]: handleOnError
        }
    });
    const deleteProcess = (0, _hooks_1.useAsyncProcessing)({
        resetAction: resetDeleteMonitor,
        stateSelector: exports.deleteMonitorSelectors.state,
        statusOptions: {
            [xuder_1.AsyncStateStatus.Received]: handleOnFinish,
            [xuder_1.AsyncStateStatus.Errored]: handleOnError,
            [xuder_1.AsyncStateStatus.Failed]: handleOnError
        }
    });
    const handleCancel = (0, react_1.useCallback)((field, removeCallback) => {
        form.setEdit(field.name, false, true);
        if (monitorList.length - 1 === field.name && adding) {
            removeCallback(field.name);
            updateMonitorList();
            setAdding(false);
        }
        else {
            const monitorState = form.getItemState(field.name);
            const previousData = monitorList === null || monitorList === void 0 ? void 0 : monitorList.find((x) => x.internalKey === (monitorState === null || monitorState === void 0 ? void 0 : monitorState.key));
            if (previousData) {
                form.setFields([
                    {
                        name: ['monitors', field.name],
                        value: previousData
                    }
                ]);
            }
        }
    }, [adding, form, monitorList, updateMonitorList]);
    const handleDelete = (0, react_1.useCallback)((field, removeCallback) => {
        const monitor = form.getFieldValue(['monitors', field.name]);
        if (monitor === null || monitor === void 0 ? void 0 : monitor.applicationId) {
            deleteProcess.watchNextCall();
            deleteMonitor({ monitorId: monitor.id });
        }
        removeCallback(field.name);
        updateMonitorList();
    }, [form, deleteMonitor, deleteProcess, updateMonitorList]);
    const saveMonitor = (0, react_1.useCallback)((monitor) => {
        form.validate();
        if (monitor === null || monitor === void 0 ? void 0 : monitor.id) {
            const updateMonitor = {
                enableOnWeekends: (monitor === null || monitor === void 0 ? void 0 : monitor.monitorTypeId) === sonar_core_1.PulseMonitorType.Uptime ?
                    monitor.enableOnWeekends :
                    undefined,
                id: monitor.id,
                monitoringWindow: (monitor === null || monitor === void 0 ? void 0 : monitor.monitorTypeId) === sonar_core_1.PulseMonitorType.Uptime ?
                    monitor.monitoringWindow :
                    undefined,
                name: monitor.name,
                notificationSchedule: monitor.notificationSchedule,
                stakeholderIds: monitor.stakeholderIds
            };
            patchProcess.watchNextCall();
            patchMonitor(updateMonitor);
        }
        else if (applicationId && monitor.monitorTypeId) {
            postProcess.watchNextCall();
            postMonitor({
                applicationId: applicationId,
                enableOnWeekends: monitor.enableOnWeekends,
                monitorTypeId: monitor.monitorTypeId,
                monitoringWindow: monitor.monitoringWindow,
                name: monitor.name,
                notificationSchedule: monitor.notificationSchedule,
                stakeholderIds: monitor.stakeholderIds
            });
            setAdding(false);
        }
    }, [applicationId, form, patchMonitor, postMonitor, patchProcess, postProcess]);
    const handleSaveAllMonitors = (0, react_1.useCallback)(() => {
        form.validate();
        const allMonitors = form.getFieldValue('monitors');
        allMonitors.forEach((monitor) => {
            saveMonitor(monitor);
        });
    }, [form, saveMonitor]);
    const handleSave = (0, react_1.useCallback)((field) => {
        form.validate();
        if (isFormList) {
            updateMonitorList();
            form.setEdit(field.name, false, true);
            if (field.name === monitorList.length - 1 && adding) {
                setAdding(false);
            }
            return;
        }
        form.setEdit(field.name, false, true);
        const monitor = form.getFieldValue(['monitors', field.name]);
        saveMonitor(monitor);
    }, [
        adding,
        isFormList,
        form,
        monitorList.length,
        saveMonitor,
        setAdding,
        updateMonitorList
    ]);
    const handleValuesChange = (0, react_1.useCallback)(() => {
        form.validate();
        if (onValuesChange) {
            onValuesChange();
        }
    }, [form, onValuesChange]);
    const handleAddNew = (0, react_1.useCallback)((addCallback) => {
        const availableMenuItems = menuItems.filter((x) => !x.disabled);
        const autoSelect = availableMenuItems.length === 1 ?
            availableMenuItems[0].default :
            monitors_1.defaultConfig;
        addCallback(autoSelect);
        setMonitorList([...monitorList, autoSelect]);
        setAdding(true);
    }, [menuItems, monitorList]);
    (0, react_1.useImperativeHandle)(ref, () => ({
        getMonitors: () => form.getFieldValue('monitors')
            .map((x) => Object.fromEntries(Object.entries(x).filter(([key]) => key !== 'internalKey'))),
        isValid: () => form.isValid && form.state.every((x) => !x.isInEdit) && !adding,
        saveAllMonitors: () => handleSaveAllMonitors(),
        validate: () => form.validate()
    }));
    return (react_1.default.createElement(antd_1.Form, { className: 'monitor-form', form: form, layout: 'vertical', name: 'monitorsForm', onValuesChange: handleValuesChange },
        react_1.default.createElement(antd_1.Form.List, { initialValue: monitorList, name: 'monitors' }, (fields, { add, remove }) => react_1.default.createElement(react_1.default.Fragment, null,
            fields.map((field) => {
                var _a, _b;
                return react_1.default.createElement("div", { key: field.key },
                    react_1.default.createElement(antd_1.Form.Item, { hidden: true, initialValue: field.key, name: [field.name, 'internalKey'], noStyle: true }),
                    react_1.default.createElement(monitors_1.MonitorCard, { editing: (_b = (_a = form.getItemState(field.name)) === null || _a === void 0 ? void 0 : _a.isInEdit) !== null && _b !== void 0 ? _b : ((monitorList.length - 1 === field.name) && adding), fieldId: field.name, form: form, monitors: monitorList, onCancel: () => handleCancel(field, remove), onDelete: monitorList.length - 1 === field.name && adding ?
                            undefined :
                            () => handleDelete(field, remove), onSave: () => handleSave(field) }));
            }),
            react_1.default.createElement(react_if_1.When, { condition: !isReadOnly && !adding && menuItems.filter((x) => !x.disabled).length > 0 },
                react_1.default.createElement(antd_1.Form.Item, null,
                    react_1.default.createElement(antd_1.Button, { className: 'add-monitor-button', icon: react_1.default.createElement(icons_1.PlusOutlined, null), onClick: () => handleAddNew(add) }, sonar_ts_constants_1.SETTINGS_PULSE_COPY.ADD_NEW_MONITOR)))))));
});
