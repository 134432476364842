"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.IssueHeader = void 0;
const ArchiveIssueButton_1 = require("./ArchiveIssueButton");
const RestoreIssueButton_1 = require("./RestoreIssueButton");
const SnoozeIssueButton_1 = require("./SnoozeIssueButton");
const antd_1 = require("antd");
const _hooks_1 = require("@hooks");
const react_if_1 = require("react-if");
const icons_1 = require("@ui/icons");
const sonar_issues_1 = require("@sonar-software/sonar-issues");
const react_1 = __importStar(require("react"));
const IssueHeader = ({ hideActions, issue, visibility }) => {
    const { active, id, object, sonarObjectType, sourceUrl } = issue;
    const { openPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const handleTitleClick = (0, react_1.useCallback)((e) => {
        const targetElementClasses = e.target.classList;
        if (targetElementClasses.contains('ant-card-head-title') ||
            targetElementClasses.contains('title-button') ||
            targetElementClasses.contains('issue-label')) {
            openPeriscope({
                id,
                name: object,
                openedFromIndex: -1,
                type: sonarObjectType
            });
        }
    }, [openPeriscope, id, object, sonarObjectType]);
    const displayName = `${object} - ${name}`;
    const showRestore = visibility === sonar_issues_1.IssueVisibilityType.Archived || visibility === sonar_issues_1.IssueVisibilityType.Snoozed;
    return (react_1.default.createElement("div", { className: 'title-button', onClick: handleTitleClick },
        react_1.default.createElement("div", { className: 'ant-card-head-title' },
            react_1.default.createElement(antd_1.Space, { align: 'baseline', wrap: true },
                react_1.default.createElement("span", { className: 'issue-label' }, displayName),
                react_1.default.createElement(icons_1.ActiveIndicator, { isActive: active }),
                react_1.default.createElement(icons_1.PeriscopeSource, { id: `issue-sf-link-${id}`, sonarObjectType: sonarObjectType, url: sourceUrl })),
            react_1.default.createElement(react_if_1.When, { condition: !hideActions },
                react_1.default.createElement(antd_1.Space, { className: 'issue-actions' },
                    !showRestore &&
                        react_1.default.createElement(react_1.default.Fragment, null,
                            react_1.default.createElement(SnoozeIssueButton_1.SnoozeIssueButton, { sonarObjectId: id }),
                            react_1.default.createElement(ArchiveIssueButton_1.ArchiveIssueButton, { sonarObjectId: id })),
                    showRestore && react_1.default.createElement(RestoreIssueButton_1.RestoreIssueButton, { sonarObjectId: id }))))));
};
exports.IssueHeader = IssueHeader;
