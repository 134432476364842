"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateStakeholderReference = useUpdateStakeholderReference;
const _hooks_1 = require("@hooks");
const react_1 = require("react");
const sonar_core_1 = require("@sonar-software/sonar-core");
function useUpdateStakeholderReference() {
    const updateAction = (0, _hooks_1.useAction)(sonar_core_1.coreActions.updateStakeholderReferences);
    return (0, react_1.useCallback)((request) => {
        updateAction(Array.isArray(request) ? request : [request]);
    }, [updateAction]);
}
