"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFollowing = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const hooks_1 = require("@lib/hooks");
const empty = [];
/**
 * Subscribes to and returns the map of followed sonar object ids. Will load related data upon mount if data is not
 * available or forced via `refresh`.
 * @param refresh Determines if the component should force load the latest data upon mount. Defaults to `false`.
 * @returns Array of {@link number}.
 */
exports.useFollowing = (0, hooks_1.createAsyncStateWithPreloadHook)({
    loadAction: sonar_core_1.coreActions.requestGetCoreFollowedAll,
    selector: (state) => state.core.followers.all.get,
    useTransform: (request) => { var _a; return ((_a = request.data) === null || _a === void 0 ? void 0 : _a.results) || empty; }
});
