"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitiativeStatusDisplay = void 0;
const css = __importStar(require("css/exports.module.scss"));
const sonar_core_1 = require("@sonar-software/sonar-core");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const icons_1 = require("@ant-design/icons");
const react_1 = __importStar(require("react"));
function getInitiativeStatus(statusId) {
    switch (statusId) {
        case sonar_core_1.InitiativeStatus.New:
            return sonar_ts_constants_1.INITIATIVES_STATUS.NEW;
        case sonar_core_1.InitiativeStatus.Ready:
            return sonar_ts_constants_1.INITIATIVES_STATUS.READY;
        case sonar_core_1.InitiativeStatus.InProgress:
            return sonar_ts_constants_1.INITIATIVES_STATUS.IN_PROGRESS;
        case sonar_core_1.InitiativeStatus.Finished:
            return sonar_ts_constants_1.INITIATIVES_STATUS.FINISHED;
        case sonar_core_1.InitiativeStatus.Testing:
            return sonar_ts_constants_1.INITIATIVES_STATUS.TESTING;
        default:
            return undefined;
    }
}
function getInitiativeStatusColor(statusId) {
    switch (statusId) {
        case sonar_core_1.InitiativeStatus.New:
        case sonar_core_1.InitiativeStatus.Ready:
            return sonar_ts_constants_1.ANTD_INITIATIVES_STATUS_COLORS.BLUE;
        case sonar_core_1.InitiativeStatus.InProgress:
        case sonar_core_1.InitiativeStatus.Testing:
            return sonar_ts_constants_1.ANTD_INITIATIVES_STATUS_COLORS.ORANGE;
        case sonar_core_1.InitiativeStatus.Finished:
            return sonar_ts_constants_1.ANTD_INITIATIVES_STATUS_COLORS.GREEN;
        default:
            return undefined;
    }
}
function getIcon(initiativeStatusId) {
    var _a;
    const color = (_a = getInitiativeStatusColor(initiativeStatusId)) !== null && _a !== void 0 ? _a : '';
    switch (initiativeStatusId) {
        case sonar_core_1.InitiativeStatus.New:
            return react_1.default.createElement(icons_1.PlusCircleTwoTone, { twoToneColor: color });
        case sonar_core_1.InitiativeStatus.Ready:
            return react_1.default.createElement(icons_1.PlayCircleTwoTone, { twoToneColor: color });
        case sonar_core_1.InitiativeStatus.InProgress:
            return react_1.default.createElement(icons_1.ClockCircleTwoTone, { twoToneColor: color });
        case sonar_core_1.InitiativeStatus.Testing:
            return react_1.default.createElement(icons_1.QuestionCircleTwoTone, { twoToneColor: color });
        case sonar_core_1.InitiativeStatus.Finished:
            return react_1.default.createElement(icons_1.CheckCircleTwoTone, { twoToneColor: color });
        default:
            return null;
    }
}
exports.InitiativeStatusDisplay = (0, react_1.memo)(({ statusId }) => react_1.default.createElement(antd_1.Space, { size: Number(css.size4) },
    getIcon(statusId),
    getInitiativeStatus(statusId)));
