"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersTable = void 0;
require("./UsersTable.scss");
const notifications_1 = require("@ui/notifications");
const InviteModal_1 = require("./InviteModal");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const tables_1 = require("@ui/tables");
const sonar_security_1 = require("@sonar-software/sonar-security");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const UsersTableColumns_1 = require("./UsersTableColumns");
const KeyPagination_1 = require("@ui/KeyPagination");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const usersSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.customers.byId.users.get);
const inviteLinkSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.security.invitations.link.get);
const linkSelector = (0, selectors_1.createSonarSelector)((state) => { var _a; return (_a = inviteLinkSelectors.data(state)) === null || _a === void 0 ? void 0 : _a.link; });
const getRowKey = (user) => user.id;
const UsersTable = () => {
    const [inviteUser, setInviteUser] = (0, react_1.useState)(null);
    const [showInviteModal, setShowInviteModal] = (0, react_1.useState)(false);
    const { isReadOnly, id: userId } = (0, _hooks_1.useCurrentUser)();
    const hasDataDictionary = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.DataDictionary);
    const { data: users, isRequested: isLoadingUsers } = (0, _hooks_1.useAsyncState)(usersSelectors);
    const userInviteLinkRequesting = (0, _hooks_1.useSonarSelector)(inviteLinkSelectors.isRequested);
    const userInviteLink = (0, _hooks_1.useSonarSelector)(linkSelector);
    const requestUsers = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreCustomersByIdUsers);
    const requestPostUser = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCoreUsersById);
    const requestInviteLink = (0, _hooks_1.useAction)(sonar_security_1.securityActions.requestGetSecurityInvitationsLink);
    const resetRequestInviteLink = (0, _hooks_1.useAction)(sonar_security_1.securityActions.resetGetSecurityInvitationsLink);
    const { queryParams, updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: KeyPagination_1.keyPaginationDefaultQueryParams,
        fallbackParams: KeyPagination_1.keyPaginationFallbackQueryParams
    });
    (0, react_1.useEffect)(() => {
        requestUsers({
            count: queryParams.count,
            key: queryParams.key,
            paginationDirection: queryParams.paginationDirection
        });
    }, [queryParams.count, queryParams.paginationDirection, queryParams.key, requestUsers]);
    const onAfterCloseInviteModal = (0, react_1.useCallback)(() => {
        resetRequestInviteLink();
        setInviteUser(null);
    }, [resetRequestInviteLink]);
    const handleOnCancelInviteModal = (0, react_1.useCallback)(() => {
        setShowInviteModal(false);
    }, []);
    const readOnlyClickHandler = (0, react_1.useCallback)((user) => {
        requestPostUser({
            isReadOnly: !user.isReadOnly,
            userId: user.id
        });
    }, [requestPostUser]);
    const inviteClickHandler = (0, react_1.useCallback)((user) => {
        requestInviteLink({
            email: user.email
        });
        setInviteUser(user);
        setShowInviteModal(true);
    }, [requestInviteLink]);
    const lockToggleHandler = (0, react_1.useCallback)((id, isLocked) => {
        requestPostUser({
            isLocked,
            userId: id
        });
    }, [requestPostUser]);
    const columns = (0, react_1.useMemo)(() => [
        (0, UsersTableColumns_1.getUserNameColumn)(userId),
        UsersTableColumns_1.userEmailColumn,
        (0, UsersTableColumns_1.getUserReadOnlyColumn)(userId, isReadOnly, readOnlyClickHandler),
        (0, UsersTableColumns_1.getUserOwnerCountColumn)(!hasDataDictionary),
        (0, UsersTableColumns_1.getUserStakeholderCountColumn)(!hasDataDictionary),
        (0, UsersTableColumns_1.getUserStatusColumn)(userId, isReadOnly || userInviteLinkRequesting, inviteClickHandler, lockToggleHandler)
    ], [
        hasDataDictionary,
        inviteClickHandler,
        isReadOnly,
        lockToggleHandler,
        readOnlyClickHandler,
        userId,
        userInviteLinkRequesting
    ]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(tables_1.PaginatedTable, { columns: columns, headerSize: 'small', id: 'users-table', loading: isLoadingUsers, onChange: updateParams, resource: users, rowKey: getRowKey, scroll: { x: 'max-content' }, sticky: true }),
        userInviteLink && inviteUser &&
            react_1.default.createElement(InviteModal_1.InviteModal, { afterClose: onAfterCloseInviteModal, inviteLink: userInviteLink, onCancel: handleOnCancelInviteModal, open: showInviteModal, user: inviteUser }),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_GET_USER_INVITE_LINK, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_GET_USER_INVITE_LINK, stateSelector: inviteLinkSelectors.state })));
};
exports.UsersTable = UsersTable;
