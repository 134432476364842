"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useStakeholderReference = useStakeholderReference;
const selectors_1 = require("@lib/selectors");
const useSonarSelector_1 = require("../useSonarSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const mapSelector = (0, selectors_1.createSonarSelector)((state) => state.core.stakeholders.byIdMap);
const emptyMap = {};
const empty = {
    email: '',
    isLocked: false,
    name: '',
    sonarObjectRefCount: 0,
    stakeholderId: 0,
    stakeholderType: sonar_core_1.StakeholderType.Individual
};
/**
 * Gets the shared sync {@link StakeholderReference} for the provided user id.
 * @param userId Id of the user to lookup.
 * @returns The {@link StakeholderReference} for the target user, or an empty object if none exists yet.
 */
function useStakeholderReference(stakeholderId) {
    return (0, useSonarSelector_1.useSonarSelector)((state) => { var _a, _b; return (_b = ((_a = mapSelector(state).data) !== null && _a !== void 0 ? _a : emptyMap)[stakeholderId]) !== null && _b !== void 0 ? _b : empty; });
}
