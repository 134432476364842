"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useToggle = useToggle;
const react_1 = require("react");
/**
 * Creates a local boolean state and wraps the update with a toggle for ease of use.
 * @param initialValue Initial state value. Defaults to `false`.
 * @returns An array containing the current state value and method to toggle the value.
 */
function useToggle(initialValue = false) {
    const [value, setValue] = (0, react_1.useState)(initialValue);
    const toggle = (0, react_1.useCallback)(() => setValue((current) => !current), []);
    return [value, toggle];
}
