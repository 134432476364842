"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.watchers = void 0;
const sonar_admin_1 = require("@sonar-software/sonar-admin");
const sagas_1 = require("./analytics/sagas");
const application_1 = require("./application");
const xuder_1 = require("@sonar-software/xuder");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_imports_1 = require("@sonar-software/sonar-imports");
const sonar_integrations_1 = require("@sonar-software/sonar-integrations");
const sonar_issues_1 = require("@sonar-software/sonar-issues");
const sonar_notifications_1 = require("@sonar-software/sonar-notifications");
const sonar_security_1 = require("@sonar-software/sonar-security");
exports.watchers = (0, xuder_1.combineAllWatchers)([
    sagas_1.analyticWatchers,
    application_1.applicationWatchers,
    sonar_admin_1.adminSlice.adminWatchers,
    sonar_core_1.coreSlice.coreWatchers,
    sonar_imports_1.importsSlice.importsWatchers,
    sonar_integrations_1.integrationsSlice.integrationWatchers,
    sonar_issues_1.issuesSlice.issuesWatchers,
    sonar_notifications_1.notificationsSlice.watchNotificationsSagas,
    sonar_security_1.securitySlice.securityWatchers
]);
