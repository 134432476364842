"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InactiveToggle = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const antd_1 = require("antd");
const InactiveToggle = ({ checked, onChange }) => {
    // Switch onChange passes a second argument that cannot be passed
    // When provided onChange is a setState function.
    const handleOnChange = (0, react_1.useCallback)((value) => onChange({ hideInactive: !value }), [onChange]);
    return (react_1.default.createElement(antd_1.Space, null,
        react_1.default.createElement("span", null, sonar_ts_constants_1.COPY_GENERIC.SHOW_INACTIVE),
        react_1.default.createElement(antd_1.Switch, { checked: !checked, onChange: handleOnChange })));
};
exports.InactiveToggle = InactiveToggle;
