"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrganizationView = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const general_1 = require("../general");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const SyncOrganizationView_1 = require("./SyncOrganizationView");
const general_2 = require("./general");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const react_router_dom_1 = require("react-router-dom");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const OrganizationView = () => {
    const organizations = (0, _hooks_1.useSalesforceOrganizations)();
    const { organizationId } = (0, _hooks_1.useAuthToken)();
    const latestSync = (0, _hooks_1.useLatestSalesforceSync)();
    const currentOrganization = (0, _hooks_1.useCurrentOrganization)();
    const { loading, progress, updateProgress } = (0, _hooks_1.useOnboardingProgress)();
    const switchOrganization = (0, _hooks_1.useSwitchOrganization)();
    const unlinkOrganization = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCoreOrganizationsUnlink);
    const handleRestart = (0, react_1.useCallback)(() => {
        var _a, _b;
        const lastUsedOrgId = localStorage.getItem(sonar_ts_types_1.LocalStorageKey.LastUsedOrg);
        if (organizationId && organizationId !== (progress === null || progress === void 0 ? void 0 : progress.organizationId)) {
            unlinkOrganization({ organizationId });
        }
        let targetOrganizationId = 0;
        if (lastUsedOrgId && !isNaN(Number(lastUsedOrgId))) {
            targetOrganizationId = Number(lastUsedOrgId);
            localStorage.removeItem(sonar_ts_types_1.LocalStorageKey.LastUsedOrg);
        }
        else {
            targetOrganizationId = (_b = (_a = organizations[0]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : progress === null || progress === void 0 ? void 0 : progress.organizationId;
        }
        switchOrganization({ organizationId: targetOrganizationId }, () => {
            updateProgress({
                isSalesforceConnected: true,
                isSalesforceSynced: true
            });
        });
    }, [
        organizationId,
        organizations,
        progress === null || progress === void 0 ? void 0 : progress.organizationId,
        switchOrganization,
        unlinkOrganization,
        updateProgress
    ]);
    if (loading || !progress) {
        return react_1.default.createElement(general_1.GatewayContent, null);
    }
    const hasCurrentRunningImport = (0, sonar_ts_lib_1.isSyncInProgress)(latestSync === null || latestSync === void 0 ? void 0 : latestSync.status);
    const hasSuccessfulImport = (0, sonar_ts_lib_1.isSyncCompleted)(latestSync === null || latestSync === void 0 ? void 0 : latestSync.status);
    const hasFailedImport = (0, sonar_ts_lib_1.isSyncFailed)(latestSync === null || latestSync === void 0 ? void 0 : latestSync.status);
    if (hasFailedImport && !(currentOrganization === null || currentOrganization === void 0 ? void 0 : currentOrganization.hasSalesforceData)) {
        return react_1.default.createElement(general_2.OnboardingErrorView, { onRestart: handleRestart });
    }
    const { missingSalesforceSyncPermission } = progress;
    const showConfirm = missingSalesforceSyncPermission ||
        (!(currentOrganization === null || currentOrganization === void 0 ? void 0 : currentOrganization.hasSalesforceData) && !hasCurrentRunningImport && !hasSuccessfulImport);
    const showSync = !(currentOrganization === null || currentOrganization === void 0 ? void 0 : currentOrganization.hasSalesforceData) &&
        (hasCurrentRunningImport || hasSuccessfulImport);
    if (!showConfirm && !showSync) {
        return react_1.default.createElement(general_1.GatewayContent, null);
    }
    return (react_1.default.createElement(react_router_dom_1.Routes, null,
        showConfirm && react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(general_2.ConfirmOrganizationView, { onRestart: handleRestart }), path: sonar_ts_constants_1.FULL_ROUTES.ORGANIZATION_CONFIRM }),
        showConfirm && react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(react_router_dom_1.Navigate, { to: sonar_ts_constants_1.FULL_ROUTES.ORGANIZATION_CONFIRM }), path: '*' }),
        showSync && react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(SyncOrganizationView_1.SyncOrganizationView, null), path: sonar_ts_constants_1.FULL_ROUTES.ORGANIZATION_SYNC }),
        showSync && react_1.default.createElement(react_router_dom_1.Route, { element: react_1.default.createElement(react_router_dom_1.Navigate, { to: sonar_ts_constants_1.FULL_ROUTES.ORGANIZATION_SYNC }), path: '*' })));
};
exports.OrganizationView = OrganizationView;
