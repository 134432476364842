"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TaskSelector = void 0;
const CreateTask_1 = require("./CreateTask");
const routes_1 = require("@lib/routes");
const utility_1 = require("@lib/utility");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const PopoverSelector_1 = require("@ui/PopoverSelector");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const TaskSelector = ({ children, className, initiativeId, onOpenChange, onSelect, trigger = 'hover' }) => {
    const [filter, setFilter] = (0, react_1.useState)('');
    const { tasks } = (0, _hooks_1.useInitiativeTasks)(initiativeId, true);
    const matchesFilter = (0, react_1.useCallback)((task) => {
        if (!filter) {
            return true;
        }
        return task.name.toLowerCase().includes(filter.toLowerCase().trim());
    }, [filter]);
    const formattedTasks = (0, react_1.useMemo)(() => tasks.filter(matchesFilter).map((task) => ({
        id: task.initiativeTaskId,
        name: task.name
    })), [matchesFilter, tasks]);
    return (react_1.default.createElement(PopoverSelector_1.PopoverSelector, { className: className, footer: react_1.default.createElement(CreateTask_1.CreateTask, { initiativeId: initiativeId, onFinish: onSelect }), itemRender: ({ name }) => name, listItems: formattedTasks, manageRoute: (0, routes_1.getInitiativeRoute)(initiativeId), onDeselect: utility_1.noop, onOpenChange: onOpenChange, onSearch: setFilter, onSelect: ({ id }) => onSelect(id), placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.SEARCH_TASKS, singleSelect: true, trigger: trigger }, children));
};
exports.TaskSelector = TaskSelector;
