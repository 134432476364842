"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSonarSelector = useSonarSelector;
exports.useSonarShallowSelector = useSonarShallowSelector;
const react_redux_1 = require("react-redux");
function useSonarSelector(selectorFn, equalityFn) {
    return (0, react_redux_1.useSelector)(selectorFn, equalityFn);
}
function useSonarShallowSelector(selectorFn) {
    return useSonarSelector(selectorFn, react_redux_1.shallowEqual);
}
