"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUsers = void 0;
const hooks_1 = require("@lib/hooks");
const sonar_core_1 = require("@sonar-software/sonar-core");
const empty = [];
/**
 * Subscribes to and returns the collection of users. Will load related data upon mount if data is not
 * available or forced via `refresh`.
 * @param refresh Determines if the component should force load the latest data upon mount. Defaults to `false`.
 * @returns Array of {@link UserReference}.
 */
exports.useUsers = (0, hooks_1.createAsyncCollectionStateWithPreloadHook)({
    loadAction: sonar_core_1.coreActions.requestGetCoreUsersAll,
    selector: (state) => state.core.users.all.get,
    useTransform: (result) => result.data || empty
});
