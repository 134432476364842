"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchItems = exports.targetObjectTypeOrder = void 0;
require("./SearchItems.scss");
const sonar_analytics_1 = require("@sonar-software/sonar-analytics");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const NoData_1 = require("@ui/NoData");
const SearchItem_1 = require("./SearchItem");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const selectors_1 = require("./arch/selectors");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
exports.targetObjectTypeOrder = [
    sonar_ts_types_1.SonarObjectType.SalesforceEntity,
    sonar_ts_types_1.SonarObjectType.SalesforceField,
    sonar_ts_types_1.SonarObjectType.SalesforceWorkflowRule,
    sonar_ts_types_1.SonarObjectType.SalesforceFieldUpdate,
    sonar_ts_types_1.SonarObjectType.SalesforceWorkflowAlert,
    sonar_ts_types_1.SonarObjectType.SalesforceValidationRule,
    sonar_ts_types_1.SonarObjectType.SalesforceProcessBuilder,
    sonar_ts_types_1.SonarObjectType.SalesforceFlow,
    sonar_ts_types_1.SonarObjectType.SalesforceApexClass,
    sonar_ts_types_1.SonarObjectType.SalesforceApexTrigger,
    sonar_ts_types_1.SonarObjectType.SalesforcePageLayout,
    sonar_ts_types_1.SonarObjectType.SalesforceReport,
    sonar_ts_types_1.SonarObjectType.SalesforceEmailTemplate,
    sonar_ts_types_1.SonarObjectType.PardotEntity,
    sonar_ts_types_1.SonarObjectType.PardotField,
    sonar_ts_types_1.SonarObjectType.PardotForm,
    sonar_ts_types_1.SonarObjectType.PardotLandingPage,
    sonar_ts_types_1.SonarObjectType.PardotList,
    sonar_ts_types_1.SonarObjectType.PardotPageAction,
    sonar_ts_types_1.SonarObjectType.PardotProfile,
    sonar_ts_types_1.SonarObjectType.PardotAutomation,
    sonar_ts_types_1.SonarObjectType.PardotEmailTemplate,
    sonar_ts_types_1.SonarObjectType.PardotEmail
];
function getSortedDataKeys(data) {
    if (!data) {
        return [];
    }
    const keys = Object.keys(data).filter((item) => exports.targetObjectTypeOrder.indexOf(item) > -1);
    return keys.sort((a, b) => exports.targetObjectTypeOrder.indexOf(a) - exports.targetObjectTypeOrder.indexOf(b));
}
const SearchItems = ({ currentType, onTypeSelection }) => {
    const { startPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const logEvent = (0, _hooks_1.useAmplitude)();
    const isSearchLoading = (0, _hooks_1.useSonarSelector)(selectors_1.isSonarObjectSearchLoadingSelector);
    const searchData = (0, _hooks_1.useSonarSelector)(selectors_1.sonarObjectsSearchDataSelector);
    const searchDataKeys = (0, react_1.useMemo)(() => getSortedDataKeys(searchData), [searchData]);
    const handleClick = (0, react_1.useCallback)((item, type) => {
        const { displayName, sonarObjectId } = item;
        logEvent(sonar_analytics_1.AMPLITUDE_EVENT.SEARCH_RESULT_CLICKED, {
            type
        });
        startPeriscope({
            id: sonarObjectId,
            name: displayName,
            type
        });
    }, [logEvent, startPeriscope]);
    if (isSearchLoading) {
        return react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, null);
    }
    let searchItems;
    if (!searchData || !searchDataKeys.length) {
        if (currentType) {
            searchItems =
                react_1.default.createElement("div", { className: 'header' },
                    react_1.default.createElement("span", null, (0, sonar_ts_lib_1.getSonarObjectTypeAndSourceLabelPlural)(currentType)),
                    react_1.default.createElement(NoData_1.NoData, { className: 'search-items-empty', imageName: 'default' }));
        }
        else {
            searchItems = react_1.default.createElement(NoData_1.NoData, { className: 'search-items-empty', imageName: 'default' });
        }
    }
    else {
        searchItems = searchDataKeys.filter(sonar_ts_lib_1.isSonarObjectTypeSearchAllowed).map((type) => {
            const items = searchData[type];
            const header = (0, sonar_ts_lib_1.getSonarObjectTypeAndSourceLabelPlural)(type);
            const showSeeMore = !currentType;
            return (react_1.default.createElement("div", { className: 'search-type-grouping', key: type },
                react_1.default.createElement("div", { className: 'header' },
                    react_1.default.createElement("span", null, header),
                    showSeeMore &&
                        react_1.default.createElement("span", { className: 'show-more-link', onClick: () => onTypeSelection(type) }, sonar_ts_constants_1.COPY_GENERIC.SHOW_MORE)),
                items.map((item) => react_1.default.createElement(SearchItem_1.SearchItem, { item: item, key: item.sonarObjectId, onClick: handleClick, type: type }))));
        });
    }
    return react_1.default.createElement("div", { id: 'search-items' }, searchItems);
};
exports.SearchItems = SearchItems;
