"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PardotFieldMetadataTab = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const pardot_picklist_values_1 = require("@periscopes/general/pardot-picklist-values");
const _hooks_1 = require("@hooks");
const react_if_1 = require("react-if");
const metadata_1 = require("@periscopes/general/metadata");
const react_1 = __importStar(require("react"));
const PardotFieldMetadataTab = ({ index, picklistValueCount, sonarObjectId }) => {
    const pardotFieldMetadataUri = `v3/pardot/fields/${sonarObjectId}/field-metadata`;
    const picklistPardotFieldUri = `v3/pardot/fields/${sonarObjectId}/picklist-field-metadata`;
    const { loading, resource } = (0, _hooks_1.useApiResource)(pardotFieldMetadataUri);
    const { syncBehavior } = resource !== null && resource !== void 0 ? resource : {};
    const metadataEntries = (0, react_1.useMemo)(() => {
        const entries = [
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.SYNC_BEHAVIOR,
                type: 'string',
                value: syncBehavior
            }
        ];
        return entries;
    }, [syncBehavior]);
    if (loading) {
        return react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, null);
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(metadata_1.MetadataContainer, { entries: metadataEntries, title: sonar_ts_constants_1.COPY_SPECIFIC.FIELD_INFORMATION }),
        react_1.default.createElement(react_if_1.When, { condition: picklistValueCount > 0 },
            react_1.default.createElement(pardot_picklist_values_1.PardotPicklistValueList, { parentIndex: index, uri: picklistPardotFieldUri }))));
};
exports.PardotFieldMetadataTab = PardotFieldMetadataTab;
