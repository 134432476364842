"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GraphConfigEditor = void 0;
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const GraphConfigEditor = ({ onChange, value }) => {
    const [isValid, setIsValid] = (0, react_1.useState)(true);
    return react_1.default.createElement(antd_1.Input.TextArea, { autoSize: true, defaultValue: JSON.stringify(value)
            .replaceAll('{', '{\r\n\t')
            .replaceAll(',"', ',\r\n\t"')
            .replaceAll('}', '\r\n}'), onChange: (e) => {
            try {
                const result = JSON.parse(e.target.value);
                setIsValid(true);
                onChange(result);
            }
            catch (ex) {
                setIsValid(false);
                console.log(ex);
            }
        }, spellCheck: false, status: isValid ? undefined : 'error' });
};
exports.GraphConfigEditor = GraphConfigEditor;
