"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforcePicklistSearchableSelectionTable = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const PicklistSortOptions_1 = require("./PicklistSortOptions");
const tables_1 = require("@ui/tables");
const __1 = require("..");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const _1 = require(".");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const SalesforcePicklistSearchableSelectionTable = ({ parentIndex, resourceIdsUri, uri }) => {
    const scrollableListRef = (0, react_1.useRef)(null);
    const { loading, resource, queryParams, updateParams } = (0, _hooks_1.useQueryResource)({
        defaultParams: Object.assign(Object.assign({}, __1.paginationDefaultQueryParams), { sortBy: PicklistSortOptions_1.picklistSortOptions[0].key, sortDirection: PicklistSortOptions_1.picklistSortOptions[0].direction }),
        fallbackParams: __1.paginationFallbackQueryParams,
        uri
    });
    const selectionData = (0, _hooks_1.useSelectionTable)({
        getRowKey: __1.getRowKey,
        queryParams,
        resourceIdsUri,
        results: resource === null || resource === void 0 ? void 0 : resource.results,
        selectionType: sonar_ts_types_1.SonarObjectType.SalesforcePicklistValue
    });
    const { openPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const handlePicklistValueClick = (0, react_1.useCallback)((picklistValue) => {
        const { displayName, sonarObjectId } = picklistValue;
        openPeriscope({
            id: sonarObjectId,
            name: displayName,
            openedFromIndex: parentIndex,
            type: sonar_ts_types_1.SonarObjectType.SalesforcePicklistValue
        });
    }, [openPeriscope, parentIndex]);
    const renderPicklistValue = (0, react_1.useCallback)((picklistValue) => react_1.default.createElement(_1.PeriscopePicklistValue, { key: picklistValue.sonarObjectId, onPicklistValueClick: handlePicklistValueClick, picklistValue: picklistValue }), [handlePicklistValueClick]);
    const totalCount = resource === null || resource === void 0 ? void 0 : resource.totalCount;
    const results = resource === null || resource === void 0 ? void 0 : resource.results;
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(_1.PicklistValueSearch, { onChange: updateParams }),
        react_1.default.createElement(tables_1.SelectionTable, { dataSource: results, loading: loading, ref: scrollableListRef, renderRow: renderPicklistValue, rowKey: __1.getRowKey, selectionData: selectionData, selectionType: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.PICKLIST_VALUES }),
        react_1.default.createElement(__1.PeriscopePagination, { loading: loading, onChange: updateParams, queryParams: queryParams, scrollRef: scrollableListRef, totalCount: Number(totalCount) })));
};
exports.SalesforcePicklistSearchableSelectionTable = SalesforcePicklistSearchableSelectionTable;
