"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v3ReportSortOptions = exports.reportSortOptions = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
exports.reportSortOptions = [
    {
        direction: 'descending',
        key: 'lastRunDate',
        label: sonar_ts_constants_1.COPY_SPECIFIC.LAST_RUN_DATE
    },
    {
        direction: 'ascending',
        key: 'name',
        label: sonar_ts_constants_1.COPY_SPECIFIC.NAME
    }
];
exports.v3ReportSortOptions = [
    Object.assign(Object.assign({}, exports.reportSortOptions[0]), { direction: sonar_ts_types_1.SonarV3SortDirection.Descending }),
    Object.assign(Object.assign({}, exports.reportSortOptions[1]), { direction: sonar_ts_types_1.SonarV3SortDirection.Ascending })
];
