"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSalesforceAuth = useSalesforceAuth;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = require("react");
const localhost = 'localhost';
const authUrl = 'https://login.salesforce.com/services/oauth2/authorize';
const sandboxAuthUrl = 'https://test.salesforce.com/services/oauth2/authorize';
const { salesforceClientId } = window.sonar;
function buildRedirect(redirectRoute) {
    const currentUrl = new sonar_ts_lib_1.DeconstructedURL(window.location.href);
    const { domain, subdomain } = currentUrl;
    currentUrl.pathname = redirectRoute;
    if (domain === localhost && subdomain) {
        currentUrl.subdomain = '';
    }
    if (domain !== localhost && subdomain && subdomain !== 'app') {
        currentUrl.subdomain = 'app';
    }
    return currentUrl.toString();
}
function buildTarget(forSandbox, redirectRoute, state) {
    const targetUrl = new URL(forSandbox ? sandboxAuthUrl : authUrl);
    const params = targetUrl.searchParams;
    params.append('response_type', 'code');
    params.append('client_id', salesforceClientId);
    params.append('prompt', 'login');
    params.append('redirect_uri', buildRedirect(redirectRoute));
    if (state) {
        params.append(sonar_ts_constants_1.QUERY.STATE, state);
    }
    return targetUrl.toString();
}
function useSalesforceAuth(redirectRoute, state) {
    const authRedirect = (0, react_1.useCallback)(() => {
        window.location.href = buildTarget(false, redirectRoute, state);
    }, [redirectRoute, state]);
    const sandboxAuthRedirect = (0, react_1.useCallback)(() => {
        window.location.href = buildTarget(true, redirectRoute, state);
    }, [redirectRoute, state]);
    return {
        authRedirect,
        sandboxAuthRedirect
    };
}
