"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.GroupedChangesChart = void 0;
const css = __importStar(require("css/exports.module.scss"));
require("./GroupedChangesChart.scss");
const selectors_1 = require("./@arch/selectors");
const NoData_1 = require("@ui/NoData");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const plots_1 = require("@ant-design/plots");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const wholeNumbersOnly = (val) => (Number(val) < Math.ceil(Number(val)) ? null : val);
const chartConfig = {
    color: [css.addition, css.modification, css.deletion],
    data: [],
    height: 400,
    isGroup: true,
    legend: {
        layout: 'horizontal',
        position: 'bottom'
    },
    seriesField: 'name',
    slider: {
        end: 1,
        start: 0
    },
    xField: 'date',
    yAxis: {
        label: {
            formatter: wholeNumbersOnly
        }
    },
    yField: 'value'
};
const formatTimestamp = (timestamp, format) => new Date(timestamp).toLocaleDateString('en-us', Object.assign(Object.assign({}, format), { timeZone: 'UTC' }));
const getDate = (timestamp, grouping) => {
    switch (grouping) {
        case sonar_core_1.TimelineGroupingType.Year:
            return formatTimestamp(timestamp, { year: 'numeric' });
        case sonar_core_1.TimelineGroupingType.Month:
            return formatTimestamp(timestamp, { month: 'short' });
        default:
            return formatTimestamp(timestamp, {
                day: 'numeric',
                month: 'short'
            });
    }
};
const GroupedChangesChart = ({ grouping, onChange }) => {
    const groupedSummary = (0, _hooks_1.useSonarSelector)(selectors_1.getTimelineGroupedSummarySelector.data);
    const disabled = !(groupedSummary === null || groupedSummary === void 0 ? void 0 : groupedSummary.changes.length);
    const data = (0, react_1.useMemo)(() => groupedSummary === null || groupedSummary === void 0 ? void 0 : groupedSummary.changes.reduce((chartData, { date: timestamp, discovered, modified, vanished }) => {
        const date = getDate(timestamp, grouping);
        return [
            ...chartData,
            {
                date,
                name: sonar_ts_constants_1.TIMELINE_COPY.ADDITIONS,
                value: discovered
            },
            {
                date,
                name: sonar_ts_constants_1.TIMELINE_COPY.MODIFICATIONS,
                value: modified
            },
            {
                date,
                name: sonar_ts_constants_1.TIMELINE_COPY.DELETIONS,
                value: vanished
            }
        ];
    }, []
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [groupedSummary === null || groupedSummary === void 0 ? void 0 : groupedSummary.changes]);
    const handleOnChange = (0, react_1.useCallback)((e) => {
        onChange({ timelineGroupingType: e.target.value });
    }, [onChange]);
    return (react_1.default.createElement("div", { className: 'grouped-changes-chart' },
        react_1.default.createElement(antd_1.Radio.Group, { disabled: disabled, onChange: handleOnChange, options: Object.values(sonar_core_1.TimelineGroupingType), optionType: 'button', size: 'small', value: grouping }),
        react_1.default.createElement(react_if_1.If, { condition: disabled },
            react_1.default.createElement(react_if_1.Then, null,
                react_1.default.createElement(NoData_1.NoData, { description: sonar_ts_constants_1.TIMELINE_COPY.NO_CHANGES_MADE_DURING_TIMEFRAME })),
            react_1.default.createElement(react_if_1.Else, null,
                react_1.default.createElement(plots_1.Column, Object.assign({ className: 'chart' }, chartConfig, { data: data !== null && data !== void 0 ? data : [] }))))));
};
exports.GroupedChangesChart = GroupedChangesChart;
