"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PardotCompletionAction = void 0;
const css = __importStar(require("css/exports.module.scss"));
require("./PardotCompletionAction.scss");
const events_1 = require("@lib/events");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const records_1 = require("@ui/records");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const { Title } = antd_1.Typography;
const PardotCompletionAction = ({ entity, onCompletionActionClick }) => {
    const { displayName, description, isActive, sonarObjectType } = entity;
    const completionActionReferenceType = (0, sonar_ts_lib_1.lowerFirst)(entity.sonarObjectType);
    const clickable = Boolean(sonarObjectType);
    const handleOnClick = (0, react_1.useMemo)(() => (0, events_1.ifClickHandler)(onCompletionActionClick, entity), [entity, onCompletionActionClick]);
    const formattedDisplayName = (0, react_1.useMemo)(() => {
        if (!(displayName === null || displayName === void 0 ? void 0 : displayName.includes('\n'))) {
            return displayName;
        }
        return displayName === null || displayName === void 0 ? void 0 : displayName.split('\n').map((entry) => {
            const [title, value] = entry.split(':');
            if (!value) {
                return title.trim();
            }
            const formattedTitle = `${title === null || title === void 0 ? void 0 : title.trim()}: `;
            return (react_1.default.createElement(react_1.default.Fragment, { key: entry },
                react_1.default.createElement("strong", null, formattedTitle),
                value,
                react_1.default.createElement("br", null)));
        });
    }, [displayName]);
    return (react_1.default.createElement("div", { className: 'pardot-completion-action' },
        react_1.default.createElement(antd_1.Row, { align: 'middle', gutter: Number(css.size4), justify: 'space-between', wrap: false },
            react_1.default.createElement(antd_1.Col, { flex: 'none' },
                react_1.default.createElement(Title, { className: 'completion-action-label', level: 4 }, description))),
        react_1.default.createElement(records_1.SonarRecordData, { isActive: isActive, name: formattedDisplayName, onClick: clickable ? handleOnClick : undefined, sonarObjectType: completionActionReferenceType })));
};
exports.PardotCompletionAction = PardotCompletionAction;
