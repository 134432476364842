"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCustomViews = void 0;
const hooks_1 = require("@lib/hooks");
const sonar_core_1 = require("@sonar-software/sonar-core");
const empty = [];
/**
 * Subscribes to and returns the collection of Data Dictionary custom view references.
 * Will load related data upon mount if data is not available or forced via `refresh`.
 * @param refresh Determines if the component should force load the latest data upon mount. Defaults to `false`.
 * @returns Array of {@link DataDictionaryViewReference}.
 */
exports.useCustomViews = (0, hooks_1.createAsyncStateWithPreloadHook)({
    loadAction: sonar_core_1.coreActions.requestGetCoreDataDictionaryViewReferences,
    selector: (state) => state.core.dataDictionary.views.references.get,
    useTransform: (result) => { var _a; return ((_a = result.data) === null || _a === void 0 ? void 0 : _a.customViews) || empty; }
});
