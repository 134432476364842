"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PotentialIssuesHeader = void 0;
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const react_router_dom_1 = require("react-router-dom");
const titleMap = {
    [sonar_ts_constants_1.FULL_ROUTES.ISSUES]: sonar_ts_constants_1.COPY_SPECIFIC.POTENTIAL_ISSUES,
    [sonar_ts_constants_1.FULL_ROUTES.ISSUES_ARCHIVED]: sonar_ts_constants_1.COPY_SPECIFIC.ARCHIVED_ISSUES,
    [sonar_ts_constants_1.FULL_ROUTES.ISSUES_SNOOZED]: sonar_ts_constants_1.COPY_SPECIFIC.SNOOZED_ISSUES
};
const PotentialIssuesHeader = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const location = (0, react_router_dom_1.useLocation)();
    const onIssues = location.pathname === sonar_ts_constants_1.FULL_ROUTES.ISSUES;
    const onArchived = location.pathname === sonar_ts_constants_1.FULL_ROUTES.ISSUES_ARCHIVED;
    const onSnoozed = location.pathname === sonar_ts_constants_1.FULL_ROUTES.ISSUES_SNOOZED;
    const handleClick = (0, react_1.useCallback)((e) => {
        const target = e.target;
        const parentButton = target.parentElement;
        if (parentButton && parentButton.value) {
            navigate(parentButton.value);
        }
    }, [navigate]);
    return (react_1.default.createElement(antd_1.Row, { align: 'middle', className: 'h1', justify: 'space-between' },
        titleMap[location.pathname],
        react_1.default.createElement(antd_1.Space, { size: 'small' },
            react_1.default.createElement(react_if_1.When, { condition: onIssues || onArchived },
                react_1.default.createElement(antd_1.Button, { id: 'view-snoozed-issues', onClick: handleClick, type: 'link', value: sonar_ts_constants_1.FULL_ROUTES.ISSUES_SNOOZED }, sonar_ts_constants_1.COPY_SPECIFIC.VIEW_SNOOZED)),
            react_1.default.createElement(react_if_1.When, { condition: onIssues || onSnoozed },
                react_1.default.createElement(antd_1.Button, { id: 'view-archived-issues', onClick: handleClick, type: 'link', value: sonar_ts_constants_1.FULL_ROUTES.ISSUES_ARCHIVED }, sonar_ts_constants_1.COPY_SPECIFIC.VIEW_ARCHIVED)),
            react_1.default.createElement(react_if_1.When, { condition: onArchived || onSnoozed },
                react_1.default.createElement(antd_1.Button, { id: 'view-potential-issues', onClick: handleClick, type: 'link', value: sonar_ts_constants_1.FULL_ROUTES.ISSUES }, sonar_ts_constants_1.COPY_SPECIFIC.VIEW_POTENTIAL_ISSUES)))));
};
exports.PotentialIssuesHeader = PotentialIssuesHeader;
