"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MonitorNotifications = void 0;
require("./MonitorNotifications.scss");
const lodash_1 = require("lodash");
const sonar_core_1 = require("@sonar-software/sonar-core");
const SonarStakeholder_1 = require("@ui/SonarStakeholder");
const text_1 = require("@ui/text");
const StakeholderSelector_1 = require("@ui/StakeholderSelector");
const _hooks_1 = require("@hooks");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const MonitorNotifications = ({ editing, fieldId, stakeholderIds = [], schedule }) => {
    const [emails, setEmails] = (0, react_1.useState)(stakeholderIds);
    const previousIds = (0, _hooks_1.usePrevious)(stakeholderIds, stakeholderIds);
    const emailsSelected = emails.length > 0;
    const handleChange = (0, react_1.useCallback)((ids) => {
        setEmails(ids);
    }, []);
    (0, react_1.useEffect)(() => {
        if (!(0, lodash_1.isEqual)(stakeholderIds, previousIds)) {
            setEmails(stakeholderIds);
        }
    }, [stakeholderIds, previousIds]);
    return (react_1.default.createElement("div", { className: 'app-monitor-notifications' },
        react_1.default.createElement(text_1.SonarText, { className: 'app-monitor-notifications-title', weight: 'semibold' }, sonar_ts_constants_1.SETTINGS_PULSE_COPY.NOTIFICATION_SETTINGS),
        react_1.default.createElement("div", { className: 'app-monitor-notifications-schedule' },
            react_1.default.createElement(react_if_1.If, { condition: editing },
                react_1.default.createElement(react_if_1.Then, null,
                    react_1.default.createElement(antd_1.Form.Item, { className: 'monitor-email-to', id: 'monitor-email-to', initialValue: emails, label: sonar_ts_constants_1.COPY_SPECIFIC.EMAIL, name: fieldId === undefined ? 'stakeholderIds' : [fieldId, 'stakeholderIds'], validateTrigger: ['onChange', 'onBlur'] },
                        react_1.default.createElement(StakeholderSelector_1.StakeholderSelector, { direction: 'horizontal', mode: 'multiple', onChange: handleChange, placeholder: sonar_ts_constants_1.COPY_SPECIFIC.SELECT_USER, value: emails })),
                    react_1.default.createElement(react_if_1.When, { condition: emailsSelected },
                        react_1.default.createElement(antd_1.Form.Item, { className: 'monitor-schedule-options', hidden: true, initialValue: sonar_core_1.MonitoringSchedule.Immediately, name: fieldId === undefined ?
                                'notificationSchedule' :
                                [fieldId, 'notificationSchedule'] },
                            react_1.default.createElement(antd_1.Radio.Group, { className: 'monitor-schedule-options-items', options: Object.values(sonar_core_1.MonitoringSchedule), size: 'small', value: schedule !== null && schedule !== void 0 ? schedule : sonar_core_1.MonitoringSchedule.Immediately })))),
                react_1.default.createElement(react_if_1.Else, null,
                    react_1.default.createElement(react_if_1.If, { condition: emailsSelected },
                        react_1.default.createElement(react_if_1.Then, null,
                            react_1.default.createElement("div", { className: 'monitor-email-to' },
                                react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.COPY_SPECIFIC.EMAIL),
                                react_1.default.createElement(antd_1.Space, { className: 'monitor-schedule-selection', direction: 'horizontal', wrap: true }, emails === null || emails === void 0 ? void 0 : emails.map((id) => react_1.default.createElement(SonarStakeholder_1.SonarStakeholder, { key: id, showTooltip: true, stakeholderId: id }))))),
                        react_1.default.createElement(react_if_1.Else, null,
                            react_1.default.createElement(text_1.SonarText, { className: 'app-monitor-no-notifications' }, sonar_ts_constants_1.SETTINGS_PULSE_COPY.NO_NOTIFICATIONS))))))));
};
exports.MonitorNotifications = MonitorNotifications;
