"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserAvatar = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const SonarAvatar_1 = require("./SonarAvatar");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const fiveMinutes = 5 * 60 * 1000;
function requiresRefresh(profilePictureTimestamp) {
    if (!profilePictureTimestamp) {
        return true;
    }
    return Date.now() - fiveMinutes > profilePictureTimestamp;
}
const UserAvatar = ({ className, large, shape, userId }) => {
    const { isGuest } = (0, _hooks_1.useCurrentUser)();
    const { firstName, lastName, profilePicture, profilePictureTimestamp } = (0, _hooks_1.useUserReference)(userId);
    const getProfilePicture = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreUsersByIdPicture);
    (0, react_1.useEffect)(() => {
        if (userId !== -1 && !isGuest && requiresRefresh(profilePictureTimestamp)) {
            getProfilePicture({ userId });
        }
    }, [getProfilePicture, isGuest, profilePictureTimestamp, userId]);
    const initials = (0, sonar_ts_lib_1.getInitials)(firstName, lastName);
    return (react_1.default.createElement(SonarAvatar_1.SonarAvatar, { className: className, isPlaceholder: userId === -1, large: large, shape: shape, src: profilePicture }, initials));
};
exports.UserAvatar = UserAvatar;
