"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInitiatives = void 0;
const hooks_1 = require("@lib/hooks");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const KeyPagination_1 = require("@ui/KeyPagination");
const sonar_core_1 = require("@sonar-software/sonar-core");
const empty = {
    results: [],
    totalCount: 0
};
/**
 * Subscribes to and returns the requested paginated collection of initiatives. Will load related data upon
 * mount if data is not available or forced via `refresh`. Also provides processing flags and a request action.
 * @param refresh Determines if the component should force load the latest data upon mount. Defaults to `false`.
 * @param initialRequest The request to make for initial data load, or force load via `refresh`, instead of the default.
 * @returns A {@link UseInitiativesResult} instance with the related `data`, if the data `isReceived`,
 * if the data `isRequested`, and the `request` action.
 */
exports.useInitiatives = (0, hooks_1.createAsyncStateWithPreloadHook)({
    defaultRequest: Object.assign(Object.assign({}, KeyPagination_1.keyPaginationDefaultQueryParams), { assignedTos: [], sortBy: sonar_ts_constants_1.INITIATIVES_TABLE_KEYS.CREATED_ON, sortDirection: 'descending', statuses: [], title: '' }),
    loadAction: sonar_core_1.coreActions.requestGetCoreInitiatives,
    selector: (state) => state.core.initiatives.get,
    useTransform: (result) => (Object.assign(Object.assign({}, result), { data: result.data || empty }))
});
