"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useJiraStatuses = void 0;
const hooks_1 = require("@lib/hooks");
const sonar_integrations_1 = require("@sonar-software/sonar-integrations");
const empty = [];
/**
 * Subscribes to and returns the collection of jira Statuses. Will load related data upon mount if data is not
 * available or forced via `refresh`.
 * @param refresh Determines if the component should force load the latest data upon mount. Defaults to `false`.
 * @returns Array of {@link GetJiraStatusResponse}.
 */
exports.useJiraStatuses = (0, hooks_1.createAsyncStateWithPreloadHook)({
    loadAction: sonar_integrations_1.integrationsActions.requestGetJiraStatuses,
    selector: (state) => state.integrations.jira.statuses.get,
    useTransform: (result) => result.data || empty
});
