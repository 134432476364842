"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PicklistValueScope = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const general_1 = require("../../../general");
const _hooks_1 = require("@hooks");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const PicklistValueScope = ({ data, index }) => {
    const { openPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const { parentField, parentEntity } = data;
    const handleFieldClick = (0, react_1.useCallback)(() => {
        openPeriscope({
            id: parentField.sonarObjectId,
            name: parentField.displayName,
            openedFromIndex: index,
            type: sonar_ts_types_1.SonarObjectType.SalesforceField
        });
    }, [index, openPeriscope, parentField.displayName, parentField.sonarObjectId]);
    const handleEntityClick = (0, react_1.useCallback)(() => {
        openPeriscope({
            id: parentEntity.sonarObjectId,
            name: parentEntity.displayName,
            openedFromIndex: index,
            type: sonar_ts_types_1.SonarObjectType.SalesforceEntity
        });
    }, [index, openPeriscope, parentEntity.displayName, parentEntity.sonarObjectId]);
    return (react_1.default.createElement(general_1.PeriscopeTablessContent, null,
        sonar_ts_constants_1.PERISCOPE_PICKLIST_COPY.DESCRIPTION_PREFIX,
        react_1.default.createElement("b", { className: 'clickable', onClick: handleFieldClick }, parentField.displayName),
        sonar_ts_constants_1.PERISCOPE_PICKLIST_COPY.DESCRIPTION_MIDDLE,
        react_1.default.createElement("b", { className: 'clickable', onClick: handleEntityClick }, parentEntity.displayName),
        sonar_ts_constants_1.PERISCOPE_PICKLIST_COPY.DESCRIPTION_SUFFIX));
};
exports.PicklistValueScope = PicklistValueScope;
