"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNotifications = void 0;
const hooks_1 = require("@lib/hooks");
const sonar_notifications_1 = require("@sonar-software/sonar-notifications");
const empty = [];
exports.useNotifications = (0, hooks_1.createAsyncCollectionStateWithPreloadHook)({
    loadAction: sonar_notifications_1.notificationsActions.requestGetNotifications,
    selector: (state) => state.notifications.get,
    useTransform: (result) => result.data || empty
});
