"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.JiraStatusMapping = void 0;
require("./JiraStatusMapping.scss");
const icons_1 = require("@ant-design/icons");
const notifications_1 = require("@ui/notifications");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const StatusSelector_1 = require("../../initiatives/StatusSelector");
const text_1 = require("@ui/text");
const antd_1 = require("antd");
const sonar_integrations_1 = require("@sonar-software/sonar-integrations");
const selectors_1 = require("./@arch/selectors");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const JiraStatusMapping = ({ status }) => {
    const { customerId } = (0, _hooks_1.useCurrentUser)();
    const postJiraStatus = (0, _hooks_1.useAction)(sonar_integrations_1.integrationsActions.requestPostJiraStatuses);
    const isReceived = (0, _hooks_1.useSonarSelector)(selectors_1.jiraSummarySelectors.isReceived);
    const handleStatusChange = (0, react_1.useCallback)((jiraStatusId) => (initiativeStatusId) => {
        postJiraStatus({
            customerId,
            initiativeStatusId,
            jiraStatusId
        });
    }, [customerId, postJiraStatus]);
    const defaultValue = status.initiativeStatusId ? String(status.initiativeStatusId) : 'Select Sonar Status';
    return (react_1.default.createElement("div", { className: 'jira-status-mapping' },
        react_1.default.createElement(antd_1.Row, { key: status.jiraStatusId },
            react_1.default.createElement(antd_1.Col, { span: 8 },
                react_1.default.createElement(text_1.SonarText, null, status.jiraStatusLabel)),
            react_1.default.createElement(antd_1.Col, { span: 5 },
                react_1.default.createElement(icons_1.ArrowRightOutlined, null)),
            react_1.default.createElement(antd_1.Col, { span: 11 },
                react_1.default.createElement(StatusSelector_1.InitiativeStatusSelector, { defaultValue: defaultValue, loading: !isReceived, noConfetti: true, onChange: handleStatusChange(status.jiraStatusId) }))),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, failed: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, resetRawAction: sonar_integrations_1.integrationsActions.resetPostJiraStatuses, stateSelector: selectors_1.jiraStatusSelectors.state })));
};
exports.JiraStatusMapping = JiraStatusMapping;
