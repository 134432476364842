"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applicationMaintenanceSyncSlice = void 0;
const xuder_1 = require("@sonar-software/xuder");
const applicationMaintenanceActionName = 'ApplicationMaintenance';
const defaultApplicationMaintenanceState = { data: { locked: false } };
exports.applicationMaintenanceSyncSlice = (0, xuder_1.createUpdateSyncSlice)({
    name: applicationMaintenanceActionName,
    state: defaultApplicationMaintenanceState
});
