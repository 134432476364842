"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CurrentPasswordModal = void 0;
require("./CurrentPasswordModal.scss");
const form_items_1 = require("./form-items");
const modals_1 = require("./modals");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const CurrentPasswordModal = ({ disabled, error, errorMessage, failed, failedMessage, onCancel, onOk, open }) => {
    const formRef = (0, react_1.useRef)(null);
    const handleOnOkClick = (0, react_1.useCallback)(() => {
        const { current } = formRef;
        if (current) {
            const currentPassword = current.getFieldValue('currentPassword');
            onOk(currentPassword);
            current.resetFields();
        }
    }, [onOk]);
    return (react_1.default.createElement(modals_1.SonarModal, { centered: true, className: 'current-password-modal', onCancel: onCancel, onOk: handleOnOkClick, open: open, title: 'Password Confirmation' },
        react_1.default.createElement(antd_1.Form, { ref: formRef },
            error && react_1.default.createElement(antd_1.Alert, { message: errorMessage, type: 'error' }),
            failed && react_1.default.createElement(antd_1.Alert, { message: failedMessage, type: 'error' }),
            react_1.default.createElement(form_items_1.CurrentPassword, { disabled: disabled, id: 'modal-password' }))));
};
exports.CurrentPasswordModal = CurrentPasswordModal;
