"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchItem = void 0;
const css = __importStar(require("css/exports.module.scss"));
require("./SearchItem.scss");
const icons_1 = require("@ui/icons");
const VersionNumber_1 = require("@ui/VersionNumber");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const lib_1 = require("./arch/lib");
const antd_1 = require("antd");
const delimiter = '.';
function getItemDisplayName(item, type) {
    const { displayName, parentDisplayName, internalName } = item;
    if ((0, lib_1.shouldItemShowApiName)(type)) {
        return (react_1.default.createElement(antd_1.Tooltip, { mouseEnterDelay: 0.5, open: displayName.length + internalName.length > 33 ? undefined : false, placement: 'right', title: internalName },
            react_1.default.createElement(antd_1.Space, { size: Number(css.size8) },
                react_1.default.createElement("span", { className: 'display' }, displayName),
                react_1.default.createElement("span", { className: 'secondary-text' }, internalName))));
    }
    if ((0, lib_1.shouldItemShowParent)(type)) {
        return (react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("span", { className: 'secondary-text' }, parentDisplayName),
            react_1.default.createElement("span", { className: 'dot' }, delimiter),
            react_1.default.createElement("span", { className: 'display' }, displayName)));
    }
    return react_1.default.createElement("span", { className: 'display' }, displayName);
}
function getActiveIndicator(status) {
    if (status === undefined) {
        return undefined;
    }
    return status === sonar_core_1.RuleStatus.Active || status === sonar_core_1.EmailTemplateStatus.Published;
}
const SearchItem = ({ item, onClick, type }) => {
    const status = item === null || item === void 0 ? void 0 : item.status;
    const handleClick = (0, react_1.useCallback)(() => {
        if (onClick) {
            onClick(item, type);
        }
    }, [item, onClick, type]);
    return (react_1.default.createElement("div", { className: 'search-item', onClick: handleClick },
        react_1.default.createElement("span", { className: 'name' },
            getItemDisplayName(item, type),
            react_1.default.createElement(VersionNumber_1.VersionNumber, { number: item.versionNumber })),
        react_1.default.createElement(icons_1.ActiveIndicator, { isActive: getActiveIndicator(status) })));
};
exports.SearchItem = SearchItem;
