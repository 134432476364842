"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createSonarSelector = createSonarSelector;
exports.asyncStateStatusSelector = asyncStateStatusSelector;
exports.asyncStateStatusCodeSelector = asyncStateStatusCodeSelector;
exports.createDataSelector = createDataSelector;
exports.createDataExistsSelector = createDataExistsSelector;
exports.createStatusSelector = createStatusSelector;
exports.createStatusCodeSelector = createStatusCodeSelector;
exports.createCollectionSelector = createCollectionSelector;
exports.createIsIdleSelector = createIsIdleSelector;
exports.createIsRequestedSelector = createIsRequestedSelector;
exports.createIsReceivedSelector = createIsReceivedSelector;
exports.createIsErroredSelector = createIsErroredSelector;
exports.createIsFailedSelector = createIsFailedSelector;
exports.createIsErroredOrFailedSelector = createIsErroredOrFailedSelector;
exports.createAsyncSelectors = createAsyncSelectors;
const xuder_1 = require("@sonar-software/xuder");
function createSonarSelector(selector) {
    return selector;
}
function asyncStateStatusSelector(selector) {
    return (state) => selector(state).actionStatus;
}
function asyncStateStatusCodeSelector(selector) {
    return (state) => selector(state).statusCode;
}
function createDataSelector(selector) {
    return createSonarSelector((state) => selector(state).data);
}
function createDataExistsSelector(selector) {
    return createSonarSelector((state) => Boolean(selector(state).data));
}
function createStatusSelector(selector) {
    return createSonarSelector(asyncStateStatusSelector(selector));
}
function createStatusCodeSelector(selector) {
    return createSonarSelector(asyncStateStatusCodeSelector(selector));
}
function createCollectionSelector(selector) {
    return createSonarSelector((state) => { var _a; return (_a = selector(state)) === null || _a === void 0 ? void 0 : _a.results; });
}
function createIsIdleSelector(selector) {
    return createSonarSelector((state) => (0, xuder_1.isIdle)(selector(state)));
}
function createIsRequestedSelector(selector) {
    return createSonarSelector((state) => (0, xuder_1.isRequested)(selector(state)));
}
function createIsReceivedSelector(selector) {
    return createSonarSelector((state) => (0, xuder_1.isReceived)(selector(state)));
}
function createIsErroredSelector(selector) {
    return createSonarSelector((state) => (0, xuder_1.isErrored)(selector(state)));
}
function createIsFailedSelector(selector) {
    return createSonarSelector((state) => (0, xuder_1.isFailed)(selector(state)));
}
function createIsErroredOrFailedSelector(selector) {
    const erroredSelector = createIsErroredSelector(selector);
    const failedSelector = createIsFailedSelector(selector);
    return (state) => erroredSelector(state) || failedSelector(state);
}
function createAsyncSelectors(selector) {
    return {
        data: createDataSelector(selector),
        dataExists: createDataExistsSelector(selector),
        isErrored: createIsErroredSelector(selector),
        isFailed: createIsFailedSelector(selector),
        isIdle: createIsIdleSelector(selector),
        isReceived: createIsReceivedSelector(selector),
        isRequested: createIsRequestedSelector(selector),
        state: selector,
        status: createStatusSelector(selector),
        statusCode: createStatusCodeSelector(selector)
    };
}
