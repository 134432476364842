"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickAccessComplianceCategories = void 0;
const icons_1 = require("@ui/icons");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const DataDictionarySearch_1 = require("./DataDictionarySearch");
const complianceCategories = Object.entries(sonar_core_1.ComplianceCategory).filter(([, id]) => typeof id === 'number');
const menuItems = [{
        children: complianceCategories.map(([, id]) => ({
            group: DataDictionarySearch_1.SearchOptionGroup.Compliance,
            key: id,
            label: react_1.default.createElement(icons_1.ComplianceCategories, { categoryFlags: Number(id) }),
            value: id
        })),
        key: 'title',
        label: sonar_ts_constants_1.COPY_SPECIFIC.COMPLIANCE,
        type: 'group'
    }];
const QuickAccessComplianceCategories = ({ onSelect }) => {
    const handleOnClick = (0, react_1.useCallback)((info) => {
        var _a;
        const [categoryName] = (_a = complianceCategories.find(([, id]) => id === Number(info.key))) !== null && _a !== void 0 ? _a : [];
        onSelect({
            complianceCategory: Number(info.key),
            displayName: categoryName
        });
    }, [onSelect]);
    return (react_1.default.createElement(antd_1.Menu, { className: 'quick-access-menu', items: menuItems, mode: 'inline', onClick: handleOnClick }));
};
exports.QuickAccessComplianceCategories = QuickAccessComplianceCategories;
