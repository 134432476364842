"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforceSyncWatcher = void 0;
const sonar_imports_1 = require("@sonar-software/sonar-imports");
const notifications_1 = require("./notifications");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const REFRESH_INTERVAL = 10 * 1000;
const SalesforceSyncWatcher = ({ showNotifications }) => {
    const { organizationId } = (0, _hooks_1.useAuthToken)();
    const getLatest = (0, _hooks_1.useAction)(sonar_imports_1.importsActions.requestGetImportsLatestSalesforce);
    const getLatestIfAble = (0, react_1.useCallback)(() => {
        if (!organizationId) {
            return;
        }
        getLatest();
    }, [getLatest, organizationId]);
    (0, _hooks_1.useOnMount)(() => {
        getLatestIfAble();
    });
    (0, _hooks_1.useInterval)(() => {
        getLatestIfAble();
    }, REFRESH_INTERVAL);
    if (!showNotifications) {
        return null;
    }
    return react_1.default.createElement(notifications_1.SyncStatusNotification, null);
};
exports.SalesforceSyncWatcher = SalesforceSyncWatcher;
