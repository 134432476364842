// extracted by mini-css-extract-plugin
var _1 = "#48b3d3";
var _2 = "#485bd3";
var _3 = "#203686";
var _4 = "#2a5599";
var _5 = "#3e94c0";
var _6 = "10";
var _7 = "#18216b";
var _8 = "#3474ac";
var _9 = "#121850";
var _a = "#78c6d0";
var _b = "#a2ba86";
var _c = "#7bad9f";
var _d = "#002140";
var _e = "#fff";
export { _1 as "apexClasses", _2 as "apexTriggers", _3 as "fields", _4 as "flows", _5 as "formulaFields", _6 as "legendFontSize", _7 as "lookupFields", _8 as "objects", _9 as "picklistValues", _a as "processBuilders", _b as "validationRules", _c as "workflowRules", _d as "xAxisStroke", _e as "yAxisStroke" }
