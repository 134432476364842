"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.QuickAccessViews = void 0;
require("./QuickAccessViews.scss");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const QuickAccessViewButton_1 = require("./QuickAccessViewButton");
const text_1 = require("@ui/text");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const DataDictionarySearch_1 = require("./DataDictionarySearch");
const _hooks_1 = require("@hooks");
const viewsGroup = {
    key: 'title',
    label: sonar_ts_constants_1.COPY_SPECIFIC.SAVED_VIEWS,
    type: 'group'
};
const QuickAccessViews = ({ onSelect }) => {
    const [showAllViews, setShowAllViews] = (0, _hooks_1.useBoolean)(false);
    const customViews = (0, _hooks_1.useCustomViews)();
    const apps = (0, _hooks_1.usePulseApps)();
    const getViews = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreDataDictionaryViewReferences);
    (0, _hooks_1.useOnMount)(() => {
        getViews({
            count: 50,
            ordering: 'LastUsed~d'
        });
    });
    const views = (0, react_1.useMemo)(() => {
        var _a;
        return [Object.assign(Object.assign({}, viewsGroup), { children: ((_a = (showAllViews ? customViews : customViews.slice(0, 10))) !== null && _a !== void 0 ? _a : [])
                    .map((view) => ({
                    group: DataDictionarySearch_1.SearchOptionGroup.View,
                    key: view.id,
                    label: react_1.default.createElement(QuickAccessViewButton_1.QuickAccessViewButton, { viewId: view.id }),
                    value: view.id
                })) })];
    }, [showAllViews, customViews]);
    const handleOnClick = (0, react_1.useCallback)((info) => {
        var _a, _b, _c;
        const view = customViews.find((customView) => customView.id === Number(info.key));
        const sonarObjectType = (view === null || view === void 0 ? void 0 : view.sonarObjectTypeId) ?
            (0, sonar_ts_lib_1.getSonarObjectTypeFromId)(view.sonarObjectTypeId) : undefined;
        const displayName = (_a = view === null || view === void 0 ? void 0 : view.sonarObjectName) !== null && _a !== void 0 ? _a : (_b = apps.find((app) => app.applicationId === (view === null || view === void 0 ? void 0 : view.applicationId))) === null || _b === void 0 ? void 0 : _b.name;
        onSelect({
            applicationId: view === null || view === void 0 ? void 0 : view.applicationId,
            complianceCategory: view === null || view === void 0 ? void 0 : view.complianceCategory,
            displayName,
            sonarObjectId: view === null || view === void 0 ? void 0 : view.sonarObjectId,
            sonarObjectType,
            tagId: view === null || view === void 0 ? void 0 : view.tagId,
            viewId: Number(info.key),
            viewName: (_c = view === null || view === void 0 ? void 0 : view.name) !== null && _c !== void 0 ? _c : ''
        });
    }, [apps, customViews, onSelect]);
    return (react_1.default.createElement("div", null,
        react_1.default.createElement(antd_1.Menu, { className: 'quick-access-menu', items: views, mode: 'inline', onClick: handleOnClick }),
        react_1.default.createElement(react_if_1.When, { condition: !customViews.length },
            react_1.default.createElement("div", { className: 'quick-access-views-placeholder' },
                react_1.default.createElement(text_1.SonarText, null, sonar_ts_constants_1.DATA_DICTIONARY_COPY.CUSTOMIZED_VIEWS_DISPLAYED_HERE),
                react_1.default.createElement(text_1.SonarText, null,
                    sonar_ts_constants_1.DATA_DICTIONARY_COPY.FOR_MORE_ON_SAVED_VIEWS,
                    react_1.default.createElement("a", { className: 'help-article-link', href: sonar_ts_constants_1.LINKS.DATA_DICTIONARY_HELP_ARTICLE, target: '_blank' }, sonar_ts_constants_1.DATA_DICTIONARY_COPY.DATA_DICTIONARY_HELP_ARTICLE)))),
        react_1.default.createElement(react_if_1.When, { condition: Number(customViews.length) > 10 },
            react_1.default.createElement(antd_1.Button, { className: 'show-all', onClick: setShowAllViews.toggle, type: 'link' }, showAllViews ? sonar_ts_constants_1.COPY_GENERIC.SHOW_LESS : sonar_ts_constants_1.COPY_GENERIC.SHOW_ALL))));
};
exports.QuickAccessViews = QuickAccessViews;
