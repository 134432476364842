"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StakeholderNameCellEditor = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const text_1 = require("@ui/text");
const sonar_http_1 = require("@sonar-software/sonar-http");
const _hooks_1 = require("@hooks");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const rules = [
    {
        max: sonar_ts_constants_1.STAKEHOLDER_NAME.MAX_LENGTH,
        message: sonar_ts_constants_1.STAKEHOLDER_NAME.REQUIRED_MESSAGE,
        min: sonar_ts_constants_1.STAKEHOLDER_NAME.MIN_LENGTH,
        pattern: sonar_ts_constants_1.STAKEHOLDER_NAME.PATTERN,
        required: true
    }
];
function updateName(stakeholderId, name) {
    return __awaiter(this, void 0, void 0, function* () {
        const result = yield (0, sonar_core_1.patchStakeholder)({
            name,
            stakeholderId
        });
        if (result.status === sonar_http_1.ErrorOrFailStatusCode.Conflict) {
            return {
                message: sonar_ts_constants_1.NOTIFICATIONS_COPY.STAKEHOLDER_CONFLICT,
                success: false
            };
        }
        if (result.status !== sonar_http_1.SuccessStatusCode.NoContent) {
            return _hooks_1.genericProcessingFailure;
        }
        return { success: true };
    });
}
const StakeholderNameCellEditor = ({ stakeholderId }) => {
    const { name } = (0, _hooks_1.useStakeholderReference)(stakeholderId);
    const { reset, start, state, stop } = (0, _hooks_1.useProcessing)();
    const update = (0, _hooks_1.useUpdateStakeholderReference)();
    const handleSave = (0, react_1.useCallback)((newValue) => __awaiter(void 0, void 0, void 0, function* () {
        if (name === newValue) {
            return;
        }
        start();
        const updateResult = yield updateName(stakeholderId, newValue);
        if (updateResult.success) {
            update({
                name: newValue,
                stakeholderId
            });
        }
        stop(updateResult);
    }), [name, start, stakeholderId, stop, update]);
    return (react_1.default.createElement(text_1.PlaintextCellEditor, { id: `stakeholder-name-${stakeholderId}`, isProcessing: state.isProcessing, maxLength: sonar_ts_constants_1.STAKEHOLDER_NAME.MAX_LENGTH, onSave: handleSave, onStartEditing: reset, processingResult: state.result, rules: rules, skipSpellcheck: true, value: name }));
};
exports.StakeholderNameCellEditor = StakeholderNameCellEditor;
