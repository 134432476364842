"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PardotEmailTemplate = void 0;
require("./PardotEmailTemplate.scss");
const events_1 = require("@lib/events");
const records_1 = require("@ui/records");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const PardotEmailTemplate = ({ entity, onEmailTemplateClick }) => {
    const { displayName, isActive, sonarObjectId, sonarObjectType } = entity;
    const handleOnClick = (0, react_1.useMemo)(() => (0, events_1.ifClickHandler)(onEmailTemplateClick, entity), [entity, onEmailTemplateClick]);
    return (react_1.default.createElement("div", { className: 'pardot-email-template' },
        react_1.default.createElement(records_1.SonarRecord, { isActive: isActive, name: displayName, onClick: handleOnClick, sonarObjectId: sonarObjectId, sonarObjectType: sonarObjectType !== null && sonarObjectType !== void 0 ? sonarObjectType : sonar_ts_types_1.SonarObjectType.PardotEmailTemplate })));
};
exports.PardotEmailTemplate = PardotEmailTemplate;
