"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ResetPasswordView = void 0;
const general_1 = require("@ui/general");
const selectors_1 = require("@lib/selectors");
const general_2 = require("../general");
const form_items_1 = require("@ui/form-items");
const sonar_security_1 = require("@sonar-software/sonar-security");
const text_1 = require("@ui/text");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const react_router_dom_1 = require("react-router-dom");
const rules = [
    {
        max: sonar_ts_constants_1.PASSWORD.MAX_LENGTH,
        message: sonar_ts_constants_1.MESSAGES_COPY.PLEASE_ENTER_NEW_PASSWORD,
        min: sonar_ts_constants_1.PASSWORD.MIN_LENGTH,
        pattern: sonar_ts_constants_1.PASSWORD.PATTERN,
        required: true,
        type: 'string'
    }
];
const resetPasswordSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.security.authentication.resetPassword.post);
const ResetPasswordView = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const { token } = (0, react_router_dom_1.useParams)();
    const isErrored = (0, _hooks_1.useSonarSelector)(resetPasswordSelectors.isErrored);
    const isFailed = (0, _hooks_1.useSonarSelector)(resetPasswordSelectors.isFailed);
    const isRequested = (0, _hooks_1.useSonarSelector)(resetPasswordSelectors.isRequested);
    const isSuccessful = (0, _hooks_1.useSonarSelector)(resetPasswordSelectors.isReceived);
    const requestPasswordReset = (0, _hooks_1.useAction)(sonar_security_1.securityActions.requestPostSecurityAuthenticationResetPassword);
    const resetPasswordReset = (0, _hooks_1.useAction)(sonar_security_1.securityActions.resetPostSecurityAuthenticationResetPassword);
    const disabled = isRequested || isSuccessful;
    const goToLogin = (0, react_1.useCallback)(() => {
        navigate(sonar_ts_constants_1.FULL_ROUTES.LOGIN);
    }, [navigate]);
    const handleOnFinish = (0, react_1.useCallback)((values) => {
        requestPasswordReset({
            newPassword: values.password,
            token: token !== null && token !== void 0 ? token : ''
        });
    }, [requestPasswordReset, token]);
    const handleOnValuesChange = (0, react_1.useCallback)(() => {
        if (isErrored || isFailed) {
            resetPasswordReset();
        }
    }, [isErrored, isFailed, resetPasswordReset]);
    (0, react_1.useEffect)(() => {
        if (isSuccessful) {
            setTimeout(() => {
                goToLogin();
            }, 3500);
        }
    }, [goToLogin, isSuccessful]);
    return (react_1.default.createElement(general_2.GatewayCard, { id: 'reset-password', title: sonar_ts_constants_1.RESET_PASSWORD_COPY.RESET_PASSWORD_HEADER },
        react_1.default.createElement(antd_1.Form, { onFinish: handleOnFinish, onValuesChange: handleOnValuesChange },
            react_1.default.createElement(text_1.SonarText, { className: 'sub-text' }, sonar_ts_constants_1.RESET_PASSWORD_COPY.RESET_PASSWORD_SUBTEXT),
            react_1.default.createElement(general_1.AsyncStateAlert, { errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_RESET_PASSWORD, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_RESET_PASSWORD, received: sonar_ts_constants_1.NOTIFICATIONS_COPY.SUCCESS_RESET_PASSWORD, stateSelector: resetPasswordSelectors.state }),
            react_1.default.createElement(antd_1.Form.Item, { id: 'reset-password-new', name: 'password', rules: rules },
                react_1.default.createElement(form_items_1.NewPassword, { disabled: disabled, size: 'large' })),
            react_1.default.createElement(antd_1.Row, { justify: 'space-between' },
                react_1.default.createElement(antd_1.Button, { disabled: disabled, id: 'reset-password-return-button', onClick: goToLogin }, sonar_ts_constants_1.COPY_SPECIFIC.BACK_TO_LOGIN),
                react_1.default.createElement(antd_1.Button, { htmlType: 'submit', id: 'reset-password-submit-button', loading: disabled, type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.RESET_PASSWORD)))));
};
exports.ResetPasswordView = ResetPasswordView;
