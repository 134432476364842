"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollShadow = void 0;
require("./ScrollShadow.scss");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const ScrollShadow = ({ children, scroller, shadow }) => {
    const containerRef = (0, react_1.useRef)(null);
    const { scrollTop, watchScroll } = (0, _hooks_1.useScroll)();
    (0, _hooks_1.useOnMount)(() => {
        var _a, _b, _c, _d;
        (_b = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(scroller)) === null || _b === void 0 ? void 0 : _b.addEventListener('scroll', watchScroll);
        (_d = (_c = containerRef.current) === null || _c === void 0 ? void 0 : _c.querySelector(shadow)) === null || _d === void 0 ? void 0 : _d.classList.add('scroll-shadow');
    });
    (0, react_1.useEffect)(() => {
        var _a, _b;
        (_b = (_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.querySelector(shadow)) === null || _b === void 0 ? void 0 : _b.classList.toggle('drop-shadow', scrollTop > 0);
    }, [scrollTop, shadow]);
    if (!children) {
        return null;
    }
    return react_1.default.cloneElement(children, { ref: containerRef });
};
exports.ScrollShadow = ScrollShadow;
