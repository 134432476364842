"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitiativeMediaList = void 0;
require("./MediaList.scss");
const notifications_1 = require("@ui/notifications");
const selectors_1 = require("@lib/selectors");
const MediaListItem_1 = require("./MediaListItem");
const LinkModal_1 = require("@ui/LinkModal");
const react_router_dom_1 = require("react-router-dom");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const FileModal_1 = require("@ui/FileModal");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getFilesDataSelector = (0, selectors_1.createSonarSelector)((state) => state.core.initiatives.files.get.data);
const getLinksDataSelector = (0, selectors_1.createSonarSelector)((state) => state.core.initiatives.links.get.data);
const postFilesStateSelector = (0, selectors_1.createSonarSelector)((state) => state.core.initiatives.files.post);
const InitiativeMediaList = ({ listSelected, showList }) => {
    const [selectedItem, setSelectedItem] = (0, react_1.useState)();
    const params = (0, react_router_dom_1.useParams)();
    const initiativeId = Number(params.id);
    const files = (0, _hooks_1.useSonarSelector)(getFilesDataSelector);
    const links = (0, _hooks_1.useSonarSelector)(getLinksDataSelector);
    const updateFile = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreInitiativesFilesById);
    const updateLink = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreInitiativesLinksById);
    const handleCloseModal = () => {
        setSelectedItem(null);
    };
    const handleUpdateFile = (updatedFile) => {
        if (selectedItem) {
            const { name } = updatedFile;
            const { id } = selectedItem;
            updateFile({
                id,
                initiativeId,
                name
            });
        }
        handleCloseModal();
    };
    const handleUpdateLink = (updatedLink) => {
        if (selectedItem) {
            const { title, url } = updatedLink;
            const { id } = selectedItem;
            updateLink({
                id,
                initiativeId,
                title,
                url
            });
        }
        handleCloseModal();
    };
    const dataSource = listSelected === 'files' ? files === null || files === void 0 ? void 0 : files.results : links === null || links === void 0 ? void 0 : links.results;
    const showFileModal = Boolean(selectedItem === null || selectedItem === void 0 ? void 0 : selectedItem.contentType);
    const showLinkModal = Boolean(selectedItem && !showFileModal);
    const renderItem = (item) => react_1.default.createElement(MediaListItem_1.InitiativeMediaListItem, { listItem: item, setSelectedItem: setSelectedItem });
    return (react_1.default.createElement(react_1.default.Fragment, null,
        links && files &&
            react_1.default.createElement(antd_1.Collapse, { activeKey: showList ? [1] : [], className: 'media-list', ghost: true, items: [{
                        children: react_1.default.createElement(antd_1.List, { dataSource: dataSource, itemLayout: 'horizontal', locale: { emptyText: sonar_ts_constants_1.COPY_SPECIFIC.CLICK_TO_ADD_LINKS_AND_FILES_TO_INITIATIVE }, renderItem: renderItem, size: 'small' }),
                        key: 1,
                        label: '',
                        showArrow: false
                    }] }),
        react_1.default.createElement(FileModal_1.FileModal, { file: selectedItem, onCancel: handleCloseModal, onOk: handleUpdateFile, open: showFileModal }),
        react_1.default.createElement(LinkModal_1.LinkModal, { link: selectedItem, onCancel: handleCloseModal, onOk: handleUpdateLink, open: showLinkModal }),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_GET_INITIATIVES_BY_ID_FILES_DOWNLOAD, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_GET_INITIATIVES_BY_ID_FILES_DOWNLOAD, resetRawAction: sonar_core_1.coreActions.resetPostCoreInitiativesFiles, stateSelector: postFilesStateSelector })));
};
exports.InitiativeMediaList = InitiativeMediaList;
