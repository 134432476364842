"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchablePeriscopeEmailAlertList = void 0;
const PeriscopeEmailAlertList_1 = require("./PeriscopeEmailAlertList");
const _hooks_1 = require("@hooks");
const __1 = require("..");
const PeriscopeEmailAlertSearch_1 = require("./PeriscopeEmailAlertSearch");
const react_1 = __importStar(require("react"));
const SearchablePeriscopeEmailAlertList = ({ parentIndex, uri }) => {
    const { loading, resource, queryParams, updateParams } = (0, _hooks_1.useQueryResource)({
        defaultParams: Object.assign({}, __1.paginationDefaultQueryParams),
        fallbackParams: __1.paginationFallbackQueryParams,
        uri
    });
    const renderEmailAlerts = (0, react_1.useCallback)((emailAlerts) => react_1.default.createElement(PeriscopeEmailAlertList_1.PeriscopeEmailAlertList, { emailAlerts: emailAlerts, parentIndex: parentIndex }), [parentIndex]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(PeriscopeEmailAlertSearch_1.PeriscopeEmailAlertSearch, { onChange: updateParams, queryParams: queryParams }),
        react_1.default.createElement(__1.PeriscopePaginatedList, { list: renderEmailAlerts, loading: loading, onChange: updateParams, queryParams: queryParams, resource: resource })));
};
exports.SearchablePeriscopeEmailAlertList = SearchablePeriscopeEmailAlertList;
