"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.reducers = void 0;
const sonar_admin_1 = require("@sonar-software/sonar-admin");
const application_1 = require("./application");
const redux_1 = require("redux");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_imports_1 = require("@sonar-software/sonar-imports");
const sonar_integrations_1 = require("@sonar-software/sonar-integrations");
const sonar_issues_1 = require("@sonar-software/sonar-issues");
const sonar_notifications_1 = require("@sonar-software/sonar-notifications");
const sonar_security_1 = require("@sonar-software/sonar-security");
exports.reducers = (0, redux_1.combineReducers)({
    admin: sonar_admin_1.adminSlice.adminReducers,
    application: application_1.applicationReducers,
    core: sonar_core_1.coreSlice.coreReducers,
    imports: sonar_imports_1.importsSlice.importsReducers,
    integrations: sonar_integrations_1.integrationsSlice.integrationReducers,
    issues: sonar_issues_1.issuesSlice.issuesReducers,
    notifications: sonar_notifications_1.notificationsSlice.notificationsReducers,
    security: sonar_security_1.securitySlice.securityReducers
});
