"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRemoveFromInitiative = useRemoveFromInitiative;
const selectors_1 = require("@lib/selectors");
const useAction_1 = require("../useAction");
const useSonarSelector_1 = require("../useSonarSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.initiatives.sonarObjects.delete);
/**
 * Subscribes to the remove from initiative processing. Provides actions to request and reset, and flags for status.
 * @returns A {@link UseRemoveFromInitiativeResult} with processing flags and actions.
 */
function useRemoveFromInitiative() {
    const request = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestDeleteCoreInitiativesSonarObjects);
    const reset = (0, useAction_1.useAction)(sonar_core_1.coreActions.resetDeleteCoreInitiativesSonarObjects);
    const isProcessing = (0, useSonarSelector_1.useSonarSelector)(selectors.isRequested);
    const isSuccessful = (0, useSonarSelector_1.useSonarSelector)(selectors.isReceived);
    return {
        isProcessing,
        isSuccessful,
        request,
        reset
    };
}
useRemoveFromInitiative.selectors = selectors;
