"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SignupView = void 0;
require("./SignupView.scss");
const selectors_1 = require("@lib/selectors");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_http_1 = require("@sonar-software/sonar-http");
const general_1 = require("../general");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const sonar_security_1 = require("@sonar-software/sonar-security");
const general_2 = require("@ui/general");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const antd_1 = require("antd");
const form_items_1 = require("@ui/form-items");
const links_1 = require("@ui/links");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const conflictMessage = sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_EMAIL_ALREADY_TAKEN(window.origin.concat('/', sonar_ts_constants_1.BASE_ROUTES.LOGIN));
const alertStatusCodeOptions = [
    {
        options: {
            message: react_1.default.createElement(general_2.SanitizedHtml, { as: 'span', html: conflictMessage }),
            type: 'error'
        },
        statusCode: sonar_http_1.ErrorOrFailStatusCode.Conflict
    }
];
const enrollSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.security.authentication.enroll.post);
const SignupView = () => {
    const [accepted, setAccepted] = (0, react_1.useState)(false);
    const { isErrored, isFailed, isReceived, isRequested } = (0, _hooks_1.useAsyncState)(enrollSelectors);
    const signup = (0, _hooks_1.useAction)(sonar_security_1.securityActions.requestPostSecurityAuthenticationEnroll);
    const resetSignup = (0, _hooks_1.useAction)(sonar_security_1.securityActions.resetPostSecurityAuthenticationEnroll);
    (0, react_1.useEffect)(() => {
        if (isReceived) {
            localStorage.removeItem(sonar_ts_types_1.LocalStorageKey.LastUsedOrg);
            window.location.replace(sonar_ts_constants_1.FULL_ROUTES.ONBOARDING_CONNECT);
        }
    }, [isReceived]);
    const handleValuesChange = (0, react_1.useCallback)(() => {
        if (isErrored || isFailed) {
            resetSignup();
        }
    }, [isErrored, isFailed, resetSignup]);
    const handleAcceptChange = (0, react_1.useCallback)((e) => {
        setAccepted(e.target.checked);
    }, []);
    const handleFinish = (0, react_1.useCallback)((values) => {
        var _a;
        const { companyName, email, firstName, lastName, password } = values;
        const currentUrl = new sonar_ts_lib_1.DeconstructedURL(window.location.href);
        const region = (_a = currentUrl.searchParams.get(sonar_ts_constants_1.QUERY.REGION)) !== null && _a !== void 0 ? _a : '';
        signup({
            customerName: companyName,
            email,
            firstName,
            lastName,
            password,
            shardCategory: region
        });
    }, [signup]);
    return (react_1.default.createElement(general_1.GatewayCard, { id: 'sign-up', title: sonar_ts_constants_1.SIGN_UP_COPY.SIGN_UP_FOR_SONAR },
        react_1.default.createElement(antd_1.Form, { onFinish: handleFinish, onValuesChange: handleValuesChange },
            react_1.default.createElement(general_2.AsyncStateAlert, { byStatusCode: alertStatusCodeOptions, errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_LOGIN, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_LOGIN, stateSelector: enrollSelectors.state }),
            react_1.default.createElement(form_items_1.CompanyName, { disabled: isRequested, id: 'sign-up-company-name', size: 'large' }),
            react_1.default.createElement(form_items_1.FirstName, { disabled: isRequested, id: 'sign-up-first-name', size: 'large' }),
            react_1.default.createElement(form_items_1.LastName, { disabled: isRequested, id: 'sign-up-last-name', size: 'large' }),
            react_1.default.createElement(form_items_1.Email, { autoComplete: sonar_ts_constants_1.AUTOCOMPLETE.USERNAME, disabled: isRequested, id: 'sign-up-email', size: 'large' }),
            react_1.default.createElement(form_items_1.ConfirmPassword, { disabled: isRequested, id: 'sign-up-password', size: 'large' }),
            react_1.default.createElement(antd_1.Row, { className: 'terms-row' },
                react_1.default.createElement(antd_1.Checkbox, { checked: accepted, onChange: handleAcceptChange },
                    sonar_ts_constants_1.SIGN_UP_COPY.PARTIAL_AGREEMENT,
                    react_1.default.createElement(links_1.TermsOfServiceLink, null),
                    sonar_ts_constants_1.COPY_GENERIC.AND_SPACED,
                    react_1.default.createElement("br", null),
                    react_1.default.createElement(links_1.PrivacyPolicyLink, null))),
            react_1.default.createElement(antd_1.Row, null,
                react_1.default.createElement(antd_1.Button, { block: true, disabled: !accepted, htmlType: 'submit', id: 'sign-up-button', loading: isRequested, size: 'large', type: 'primary' }, sonar_ts_constants_1.SIGN_UP_COPY.SIGN_UP)))));
};
exports.SignupView = SignupView;
