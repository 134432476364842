"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOptimisticUpdate = useOptimisticUpdate;
const selectors_1 = require("@lib/selectors");
const useAction_1 = require("./useAction");
const react_1 = require("react");
const useSonarSelector_1 = require("./useSonarSelector");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonarObjectOptimisiticSelector = (0, selectors_1.createSonarSelector)((state) => state.core.sonarObjects.optimistic);
const sonarObjectRefsSelector = (0, selectors_1.createSonarSelector)((state) => state.core.sonarObjects.byIdMap);
function useOptimisticUpdate(sonarObjectId) {
    var _a, _b;
    const refreshSalesforceData = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestPostCoreSonarObjectsSalesforceUpdateable);
    const removeOptimisticChanges = (0, useAction_1.useAction)(sonar_core_1.coreActions.removeSonarObjectsOptimisticChange);
    const optimisticChanges = (0, useSonarSelector_1.useSonarSelector)((state) => sonarObjectOptimisiticSelector(state).data);
    const sonarObjectRefs = (0, useSonarSelector_1.useSonarSelector)((state) => sonarObjectRefsSelector(state).data);
    const clear = (0, react_1.useCallback)(() => {
        var _a;
        const sonarObjectIds = Object.keys((_a = optimisticChanges === null || optimisticChanges === void 0 ? void 0 : optimisticChanges.sonarObjectIds) !== null && _a !== void 0 ? _a : {}).map(Number)
            .filter((id) => sonarObjectRefs === null || sonarObjectRefs === void 0 ? void 0 : sonarObjectRefs[id]);
        refreshSalesforceData({ sonarObjectIds });
        setTimeout(removeOptimisticChanges, 1000);
    }, [optimisticChanges === null || optimisticChanges === void 0 ? void 0 : optimisticChanges.sonarObjectIds, refreshSalesforceData, removeOptimisticChanges, sonarObjectRefs]);
    const hasChange = (_a = (sonarObjectId && (optimisticChanges === null || optimisticChanges === void 0 ? void 0 : optimisticChanges.sonarObjectIds[sonarObjectId]))) !== null && _a !== void 0 ? _a : false;
    const changes = hasChange ? (_b = optimisticChanges === null || optimisticChanges === void 0 ? void 0 : optimisticChanges.salesforce) !== null && _b !== void 0 ? _b : {} : {};
    return {
        changes,
        clear
    };
}
