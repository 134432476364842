"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDirectParent = getDirectParent;
exports.getClosestCardHeader = getClosestCardHeader;
exports.getClosestCardBody = getClosestCardBody;
exports.getClosestTableBody = getClosestTableBody;
exports.getClosestTableRow = getClosestTableRow;
exports.getClosestTableCell = getClosestTableCell;
exports.getClosestSelectionTable = getClosestSelectionTable;
exports.getClosestButton = getClosestButton;
exports.getClosestPopup = getClosestPopup;
exports.getClosestRow = getClosestRow;
exports.getClosestViewArea = getClosestViewArea;
exports.getDocumentBody = getDocumentBody;
function getDirectParent(trigger) {
    return trigger.parentElement;
}
function getClosestCardHeader(trigger) {
    return trigger.closest('.ant-card-head');
}
function getClosestCardBody(trigger) {
    return trigger.closest('.ant-card-body');
}
function getClosestTableBody(trigger) {
    return trigger.closest('.ant-table-tbody');
}
function getClosestTableRow(trigger) {
    return trigger.closest('tr');
}
function getClosestTableCell(trigger) {
    return trigger.closest('td');
}
function getClosestSelectionTable(trigger) {
    return trigger.closest('.selection-table');
}
function getClosestButton(trigger) {
    return trigger.closest('button');
}
function getClosestPopup(trigger) {
    return trigger.closest('#ant-popover');
}
function getClosestRow(trigger) {
    return trigger.closest('.ant-row');
}
function getClosestViewArea(trigger) {
    return trigger.closest('.view-area');
}
function getDocumentBody() {
    return document.body;
}
