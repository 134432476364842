"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AdminReferenceDebugger = void 0;
const _hooks_1 = require("@hooks");
const AdminReferenceDebugger = () => {
    const { isAdmin } = (0, _hooks_1.useAuthToken)();
    const sonarObjectReference = (0, _hooks_1.useUpdateSonarObjectReference)();
    (0, _hooks_1.useOnMount)(() => {
        if (isAdmin) {
            window.sonar.sonarObjectReference = sonarObjectReference;
        }
    });
    return null;
};
exports.AdminReferenceDebugger = AdminReferenceDebugger;
