"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimelineSynopsis = void 0;
const css = __importStar(require("css/exports.module.scss"));
require("./TimelineSynopsis.scss");
const lib_1 = require("./@arch/lib");
const selectors_1 = require("./@arch/selectors");
const icons_1 = require("@ant-design/icons");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_if_1 = require("react-if");
const react_1 = __importStar(require("react"));
const sonar_core_1 = require("@sonar-software/sonar-core");
const _hooks_1 = require("@hooks");
const emptyResponse = {
    additions: 0,
    admins: 0,
    automations: 0,
    deletions: 0,
    fields: 0,
    modifications: 0,
    objects: 0
};
const TimelineSynopsis = ({ hideSystem, onChangeTypeClicked, onChangeTypeHovered }) => {
    var _a;
    const synopsis = (0, _hooks_1.useSonarSelector)(selectors_1.getTimelineSynopsisSelector.data);
    const data = (0, react_1.useMemo)(() => (Object.assign(Object.assign({}, emptyResponse), synopsis)), [synopsis]);
    const organization = (0, _hooks_1.useCurrentOrganization)();
    const hasEntities = data.objects !== 0 || data.fields !== 0 || data.automations !== 0;
    const hasChanges = data.additions !== 0 || data.modifications !== 0 || data.deletions !== 0;
    return (react_1.default.createElement("div", { className: 'timeline-synopsis' },
        react_1.default.createElement("div", { className: 'statistics-section' },
            react_1.default.createElement(antd_1.Row, { gutter: [Number(css.size24), Number(css.size24)], wrap: false },
                react_1.default.createElement(antd_1.Col, { key: 'admins' },
                    react_1.default.createElement(antd_1.Statistic, { title: 'Admins', value: data.admins })),
                react_1.default.createElement(antd_1.Col, { key: 'additions', onClick: onChangeTypeClicked.bind(null, sonar_core_1.TimelineChangeType.Discovered), onMouseEnter: onChangeTypeHovered.bind(null, sonar_core_1.TimelineChangeType.Discovered), onMouseLeave: onChangeTypeHovered.bind(null, undefined) },
                    react_1.default.createElement(antd_1.Statistic, { className: (0, lib_1.getChangeRecordClassName)(sonar_core_1.TimelineChangeType.Discovered, data.additions), prefix: react_1.default.createElement(icons_1.ArrowUpOutlined, null), title: 'Additions', value: data.additions })),
                react_1.default.createElement(antd_1.Col, { key: 'modifications', onClick: onChangeTypeClicked.bind(null, sonar_core_1.TimelineChangeType.Modified), onMouseEnter: onChangeTypeHovered.bind(null, sonar_core_1.TimelineChangeType.Modified), onMouseLeave: onChangeTypeHovered.bind(null, undefined) },
                    react_1.default.createElement(antd_1.Statistic, { className: (0, lib_1.getChangeRecordClassName)(sonar_core_1.TimelineChangeType.Modified, data.modifications), prefix: react_1.default.createElement(icons_1.SwapOutlined, null), title: 'Modifications', value: data.modifications })),
                react_1.default.createElement(antd_1.Col, { key: 'deletions', onClick: onChangeTypeClicked.bind(null, sonar_core_1.TimelineChangeType.Vanished), onMouseEnter: onChangeTypeHovered.bind(null, sonar_core_1.TimelineChangeType.Vanished), onMouseLeave: onChangeTypeHovered.bind(null, undefined) },
                    react_1.default.createElement(antd_1.Statistic, { className: (0, lib_1.getChangeRecordClassName)(sonar_core_1.TimelineChangeType.Vanished, data.deletions), prefix: react_1.default.createElement(icons_1.ArrowDownOutlined, null), title: 'Deletions', value: data.deletions })))),
        react_1.default.createElement("div", { className: 'message' },
            react_1.default.createElement(react_if_1.When, { condition: hideSystem }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_EXCLUDING),
            react_1.default.createElement("span", { className: 'highlighted' }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_ADMIN_COUNT(data.admins)),
            react_1.default.createElement(react_if_1.If, { condition: data.admins === 1 },
                react_1.default.createElement(react_if_1.Then, null, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_HAS_MADE),
                react_1.default.createElement(react_if_1.Else, null, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_HAVE_MADE)),
            react_1.default.createElement("span", { className: 'highlighted' }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_CHANGE_COUNT(data.additions + data.modifications + data.deletions)),
            sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_TO,
            react_1.default.createElement("span", { className: 'highlighted' }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_ORGANIZATION((_a = organization === null || organization === void 0 ? void 0 : organization.name) !== null && _a !== void 0 ? _a : '')),
            sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_PERIOD,
            react_1.default.createElement(react_if_1.When, { condition: hasEntities || hasChanges }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_THIS_INCLUDES),
            react_1.default.createElement(react_if_1.When, { condition: data.objects !== 0 },
                react_1.default.createElement("span", { className: 'highlighted' }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_OBJECT_COUNT(data.objects))),
            react_1.default.createElement(react_if_1.When, { condition: data.fields !== 0 },
                react_1.default.createElement(react_if_1.When, { condition: data.objects !== 0 },
                    react_1.default.createElement(react_if_1.If, { condition: data.automations === 0 },
                        react_1.default.createElement(react_if_1.Then, null, sonar_ts_constants_1.COPY_GENERIC.AND_SPACED),
                        react_1.default.createElement(react_if_1.Else, null, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_COMMA))),
                react_1.default.createElement("span", { className: 'highlighted' }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_FIELD_COUNT(data.fields))),
            react_1.default.createElement(react_if_1.When, { condition: data.automations !== 0 },
                react_1.default.createElement(react_if_1.When, { condition: data.objects !== 0 && data.fields !== 0 }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_COMMA),
                react_1.default.createElement(react_if_1.When, { condition: data.objects !== 0 || data.fields !== 0 }, sonar_ts_constants_1.COPY_GENERIC.AND_SPACED),
                react_1.default.createElement("span", { className: 'highlighted' }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_AUTOMATION_COUNT(data.automations))),
            react_1.default.createElement(react_if_1.When, { condition: hasEntities && hasChanges }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_WITH),
            react_1.default.createElement(react_if_1.When, { condition: data.additions !== 0 },
                react_1.default.createElement("span", { className: 'highlighted' }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_ADDITIONS(data.additions))),
            react_1.default.createElement(react_if_1.When, { condition: data.modifications !== 0 },
                react_1.default.createElement(react_if_1.When, { condition: data.additions !== 0 },
                    react_1.default.createElement(react_if_1.If, { condition: data.deletions === 0 },
                        react_1.default.createElement(react_if_1.Then, null, sonar_ts_constants_1.COPY_GENERIC.AND_SPACED),
                        react_1.default.createElement(react_if_1.Else, null, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_COMMA))),
                react_1.default.createElement("span", { className: 'highlighted' }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_MODIFICATIONS(data.modifications))),
            react_1.default.createElement(react_if_1.When, { condition: data.deletions !== 0 },
                react_1.default.createElement(react_if_1.When, { condition: data.additions !== 0 && data.modifications !== 0 }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_COMMA),
                react_1.default.createElement(react_if_1.When, { condition: data.additions !== 0 || data.modifications !== 0 }, sonar_ts_constants_1.COPY_GENERIC.AND_SPACED),
                react_1.default.createElement("span", { className: 'highlighted' }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_DELETIONS(data.deletions))),
            react_1.default.createElement(react_if_1.When, { condition: data.additions !== 0 || data.modifications !== 0 || data.deletions !== 0 }, sonar_ts_constants_1.TIMELINE_COPY.STATISTICS_PERIOD))));
};
exports.TimelineSynopsis = TimelineSynopsis;
