"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesForceSummary = void 0;
const sonar_analytics_1 = require("@sonar-software/sonar-analytics");
const antd_1 = require("antd");
const lodash_1 = require("lodash");
const selectors_1 = require("@lib/selectors");
const DailySyncToggle_1 = require("./DailySyncToggle");
const features_1 = require("@ui/features");
const sonar_core_1 = require("@sonar-software/sonar-core");
const sonar_imports_1 = require("@sonar-software/sonar-imports");
const LastSyncSummary_1 = require("./LastSyncSummary");
const PardotSync_1 = require("./PardotSync");
const general_1 = require("@ui/general");
const react_router_dom_1 = require("react-router-dom");
const react_if_1 = require("react-if");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const hasImportsSelector = (0, selectors_1.createSonarSelector)((state) => { var _a, _b, _c, _d, _e; return ((_e = (_d = (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.imports) === null || _a === void 0 ? void 0 : _a.latest) === null || _b === void 0 ? void 0 : _b.get) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.length) !== null && _e !== void 0 ? _e : 0) > 0; });
const SalesForceSummary = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const logEvent = (0, _hooks_1.useAmplitude)();
    const organization = (0, _hooks_1.useCurrentOrganization)();
    const anyImports = (0, _hooks_1.useSonarSelector)(hasImportsSelector);
    const requestGetImportsLatestAction = (0, _hooks_1.useAction)(sonar_imports_1.importsActions.requestGetImportsLatest);
    (0, _hooks_1.useOnMount)(() => {
        requestGetImportsLatestAction({});
    });
    const handleClick = (0, react_1.useCallback)(() => {
        logEvent(sonar_analytics_1.AMPLITUDE_EVENT.VIEW_DIFFERENCES_CLICKED);
        navigate(sonar_ts_constants_1.FULL_ROUTES.TIMELINE);
    }, [logEvent, navigate]);
    const lastSyncSummaryText = `${(0, lodash_1.capitalize)(organization === null || organization === void 0 ? void 0 : organization.name)} ${sonar_ts_constants_1.COPY_SPECIFIC.LAST_SYNC_SUMMARY}`;
    return (react_1.default.createElement(general_1.SonarCard, { className: 'salesforce-summary', extra: react_1.default.createElement(react_if_1.When, { condition: anyImports },
            react_1.default.createElement(features_1.FeatureBoundary, { feature: sonar_core_1.FeatureFlag.Timelines },
                react_1.default.createElement(antd_1.Button, { id: 'view-detailed-changes-button', onClick: handleClick }, sonar_ts_constants_1.COPY_SPECIFIC.VIEW_DETAILED_CHANGES))), title: react_1.default.createElement(react_1.default.Fragment, null,
            react_1.default.createElement("span", { className: 'h2' }, lastSyncSummaryText),
            react_1.default.createElement(PardotSync_1.PardotSync, null)) },
        react_1.default.createElement(LastSyncSummary_1.LastSyncSummary, null),
        react_1.default.createElement(DailySyncToggle_1.DailySyncToggle, null)));
};
exports.SalesForceSummary = SalesForceSummary;
