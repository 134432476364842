"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInterval = useInterval;
const react_1 = require("react");
function wait(delay) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve) => setTimeout(resolve, delay));
}
function timeout(handler, delay) {
    return __awaiter(this, void 0, void 0, function* () {
        yield wait(delay);
        handler();
    });
}
function useInterval(callback, delay) {
    const callbackRef = (0, react_1.useRef)(callback);
    const activeRef = (0, react_1.useRef)(true);
    callbackRef.current = callback;
    (0, react_1.useEffect)(() => {
        if (delay === null) {
            return;
        }
        const handle = () => __awaiter(this, void 0, void 0, function* () {
            if (!activeRef.current) {
                return;
            }
            const callbackResult = callbackRef.current();
            const resultAsPromise = callbackResult;
            if (typeof (resultAsPromise === null || resultAsPromise === void 0 ? void 0 : resultAsPromise.then) === 'function') {
                yield resultAsPromise;
            }
            timeout(handle, delay);
        });
        timeout(handle, delay);
        return () => {
            activeRef.current = false;
        };
    }, [delay]);
}
