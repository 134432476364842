"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.unauthorizedErrorWatcher = exports.serviceUnavailableErrorWatcher = exports.softResetApplicationWatcher = exports.hardResetApplicationWatcher = void 0;
const actions_1 = require("./auth/actions");
const sonar_core_1 = require("@sonar-software/sonar-core");
const effects_1 = require("redux-saga/effects");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const actions_2 = require("./maintenance/actions");
const actions_3 = require("./actions");
const xuder_1 = require("@sonar-software/xuder");
exports.hardResetApplicationWatcher = (0, xuder_1.createWatcherSaga)([
    {
        action: actions_3.ApplicationActionType.HardReset,
        actionHandler: function* () {
            try {
                yield (0, sonar_ts_lib_1.removeJWTToken)();
                yield (0, effects_1.put)((0, actions_1.clearAuth)());
                yield (0, effects_1.put)((0, actions_3.resetApplication)());
            }
            catch (e) {
                yield (0, effects_1.put)((0, xuder_1.error)(e.message));
            }
        },
        type: xuder_1.SagaYieldType.Latest
    }
]);
exports.softResetApplicationWatcher = (0, xuder_1.createWatcherSaga)([
    {
        action: actions_3.ApplicationActionType.SoftReset,
        actionHandler: function* () {
            try {
                yield (0, effects_1.put)((0, actions_3.resetApplication)());
                yield (0, effects_1.put)(sonar_core_1.coreActions.requestGetCoreOrganizationsAll());
                yield (0, effects_1.put)(sonar_core_1.coreActions.requestGetCoreOnboardingProgress());
                yield (0, effects_1.put)(sonar_core_1.coreActions.requestGetCoreTags());
                yield (0, effects_1.put)((0, actions_3.resetApplicationComplete)());
            }
            catch (e) {
                yield (0, effects_1.put)((0, xuder_1.error)(e.message));
            }
        },
        type: xuder_1.SagaYieldType.Latest
    }
]);
exports.serviceUnavailableErrorWatcher = (0, xuder_1.createWatcherSaga)([
    {
        action: xuder_1.ServiceUnavailableActionType,
        actionHandler: function* () {
            try {
                yield (0, effects_1.put)((0, actions_2.updateApplicationMaintenance)({ locked: true }));
            }
            catch (e) {
                yield (0, effects_1.put)((0, xuder_1.error)(e.message));
            }
        },
        type: xuder_1.SagaYieldType.Latest
    }
]);
exports.unauthorizedErrorWatcher = (0, xuder_1.createWatcherSaga)([
    {
        action: xuder_1.UnauthorizedErrorActionType,
        actionHandler: function* () {
            try {
                yield (0, effects_1.put)((0, actions_3.hardResetApplication)());
            }
            catch (e) {
                yield (0, effects_1.put)((0, xuder_1.error)(e.message));
            }
        },
        type: xuder_1.SagaYieldType.Latest
    }
]);
