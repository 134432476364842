"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PardotLandingPageMetadataTab = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const pardot_forms_1 = require("@periscopes/general/pardot-forms");
const metadata_1 = require("@periscopes/general/metadata");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const PardotLandingPageMetadataTab = ({ index, sonarObjectId }) => {
    const { openPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const { loading, metadata } = (0, _hooks_1.usePeriscopeMetadata)(sonarObjectId, sonar_ts_types_1.SonarObjectType.PardotLandingPage);
    const handleFormClick = (0, react_1.useCallback)(() => {
        var _a, _b;
        if (!((_a = metadata === null || metadata === void 0 ? void 0 : metadata.form) === null || _a === void 0 ? void 0 : _a.sonarObjectId)) {
            return;
        }
        const { form } = metadata;
        openPeriscope({
            id: form.sonarObjectId,
            name: form.displayName,
            openedFromIndex: index,
            type: (_b = form.sonarObjectType) !== null && _b !== void 0 ? _b : sonar_ts_types_1.SonarObjectType.PardotForm
        });
    }, [index, openPeriscope, metadata]);
    const metadataEntries = (0, react_1.useMemo)(() => {
        if (!metadata) {
            return [];
        }
        const entries = [
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.CAMPAIGN,
                type: 'string',
                value: metadata.campaign
            },
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.TITLE,
                type: 'string',
                value: metadata.title
            },
            {
                label: sonar_ts_constants_1.COPY_SPECIFIC.LINK,
                type: 'string',
                value: metadata.link
            }
        ];
        return entries;
    }, [metadata]);
    if (loading || !metadata) {
        return react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, null);
    }
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(metadata_1.MetadataContainer, { entries: metadataEntries, title: sonar_ts_constants_1.COPY_SPECIFIC.LANDING_PAGE_INFORMATION }),
        metadata.form && react_1.default.createElement(pardot_forms_1.PardotForm, { entity: metadata.form, onFormClick: handleFormClick })));
};
exports.PardotLandingPageMetadataTab = PardotLandingPageMetadataTab;
