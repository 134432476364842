"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RedirectWatcher = void 0;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = require("react");
const react_router_dom_1 = require("react-router-dom");
const _hooks_1 = require("@hooks");
function removeQueryParam(options) {
    const { navigate, onRemove, param, route, searchParams } = options;
    if (searchParams.has(param)) {
        const paramValue = searchParams.get(param);
        searchParams.delete(param);
        const baseRoute = (0, sonar_ts_lib_1.isFunction)(route) ? route(paramValue) : route;
        const searchString = searchParams.toString();
        navigate(`${baseRoute}${searchString && `?${searchString}`}`);
        if (onRemove) {
            onRemove(paramValue);
        }
    }
}
const RedirectWatcher = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const { pathname } = (0, react_router_dom_1.useLocation)();
    const { progress } = (0, _hooks_1.useOnboardingProgress)();
    const switchOrganization = (0, _hooks_1.useSwitchOrganization)();
    const selectedOrganization = (0, _hooks_1.useCurrentOrganization)();
    const salesforceOrganizations = (0, _hooks_1.useSalesforceOrganizations)();
    const { search } = window.location;
    (0, react_1.useEffect)(() => {
        const searchParams = new URLSearchParams(search);
        removeQueryParam({
            navigate,
            onRemove: (orgId) => {
                switchOrganization({ organizationId: Number(orgId) });
            },
            param: sonar_ts_constants_1.QUERY.TARGET_ORGANIZATION,
            route: pathname,
            searchParams
        });
        removeQueryParam({
            navigate,
            param: sonar_ts_constants_1.QUERY.TARGET_ROUTE,
            route: (targetRoute) => targetRoute,
            searchParams
        });
    }, [navigate, pathname, search, switchOrganization]);
    (0, react_1.useEffect)(() => {
        var _a, _b;
        const hasValidRootOrganization = (progress === null || progress === void 0 ? void 0 : progress.organizationId) && progress.isSalesforceSynced;
        const hasValidOrganizations = salesforceOrganizations.length;
        if (!selectedOrganization && (hasValidRootOrganization || hasValidOrganizations)) {
            switchOrganization({ organizationId: (_b = (_a = salesforceOrganizations[0]) === null || _a === void 0 ? void 0 : _a.id) !== null && _b !== void 0 ? _b : progress === null || progress === void 0 ? void 0 : progress.organizationId });
        }
    }, [
        progress === null || progress === void 0 ? void 0 : progress.isSalesforceSynced,
        progress === null || progress === void 0 ? void 0 : progress.organizationId,
        salesforceOrganizations,
        selectedOrganization,
        switchOrganization
    ]);
    return null;
};
exports.RedirectWatcher = RedirectWatcher;
