"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ApplicationActionType = void 0;
exports.hardResetApplication = hardResetApplication;
exports.resetApplication = resetApplication;
exports.resetApplicationComplete = resetApplicationComplete;
exports.softResetApplication = softResetApplication;
var ApplicationActionType;
(function (ApplicationActionType) {
    ApplicationActionType["HardReset"] = "hardReset";
    ApplicationActionType["Reset"] = "reset";
    ApplicationActionType["ResetComplete"] = "resetComplete";
    ApplicationActionType["SoftReset"] = "softReset";
})(ApplicationActionType || (exports.ApplicationActionType = ApplicationActionType = {}));
function hardResetApplication() {
    return { type: ApplicationActionType.HardReset };
}
function resetApplication() {
    return { type: ApplicationActionType.Reset };
}
function resetApplicationComplete() {
    return { type: ApplicationActionType.ResetComplete };
}
function softResetApplication() {
    return { type: ApplicationActionType.SoftReset };
}
