"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CreateInitiative = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const general_1 = require("@ui/general");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const CreateInitiative = ({ noPadding, onFinish, source }) => {
    const { id: userId } = (0, _hooks_1.useCurrentUser)();
    const { isProcessing, isSuccessful, result, request } = (0, _hooks_1.useCreateInitiative)();
    const isCreatingRef = (0, react_1.useRef)(false);
    const onFinishRef = (0, react_1.useRef)(onFinish);
    onFinishRef.current = onFinish;
    (0, react_1.useEffect)(() => {
        if (isSuccessful && result && isCreatingRef.current) {
            isCreatingRef.current = false;
            if (onFinishRef.current) {
                onFinishRef.current(result);
            }
        }
    }, [isProcessing, onFinish, isSuccessful, result]);
    return (react_1.default.createElement(general_1.QuickAdd, { className: 'create-initiative', noPadding: noPadding, onSubmit: (value) => {
            isCreatingRef.current = true;
            request({
                assignedTo: userId,
                description: '',
                initiativeStatusId: sonar_core_1.InitiativeStatus.New,
                initiativeTypeId: sonar_core_1.InitiativeType.Improvement,
                source,
                title: value
            });
        }, processing: isProcessing, toggleProps: {
            className: 'create-initiative-button',
            id: 'initiatives-create-open-form-button'
        }, typeLabel: sonar_ts_constants_1.COPY_SPECIFIC.INITIATIVE }));
};
exports.CreateInitiative = CreateInitiative;
