"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.applicationSidebarSyncSlice = void 0;
const xuder_1 = require("@sonar-software/xuder");
const defaultApplicationSidebarState = { data: { closed: false } };
exports.applicationSidebarSyncSlice = (0, xuder_1.createUpdateSyncSlice)({
    name: 'ApplicationSidebar',
    state: defaultApplicationSidebarState,
    transform: (action) => {
        const { closed = true } = action !== null && action !== void 0 ? action : {};
        return {
            closed
        };
    }
});
