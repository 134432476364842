"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.EntityRelatedObjectsTab = void 0;
const general_1 = require("../../../general");
const related_objects_1 = require("../../../general/related-objects");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const EntityRelatedObjectsTab = ({ index, sonarObjectId }) => {
    const uri = `/v2/salesforce/SfEntityDefinitions/${sonarObjectId}/related-objects`;
    const { resource, loading } = (0, _hooks_1.useApiResource)(uri);
    const refs = Object.values(resource !== null && resource !== void 0 ? resource : []);
    const renderRelatedObjects = (0, react_1.useCallback)((entityReferences) => react_1.default.createElement(related_objects_1.PeriscopeRelatedObjectsList, { parentIndex: index, relatedObjects: entityReferences }), [index]);
    return react_1.default.createElement(general_1.PeriscopeList, { list: renderRelatedObjects, loading: loading, results: refs !== null && refs !== void 0 ? refs : [] });
};
exports.EntityRelatedObjectsTab = EntityRelatedObjectsTab;
