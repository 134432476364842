"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitiativeMedia = void 0;
const css = __importStar(require("css/exports.module.scss"));
require("./Index.scss");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const MediaList_1 = require("./MediaList");
const JiraIssuesButton_1 = require("./jira/JiraIssuesButton");
const LinkModal_1 = require("@ui/LinkModal");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const icons_1 = require("@ant-design/icons");
const notifications_1 = require("../notifications");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getFilesDataSelector = (0, selectors_1.createSonarSelector)((state) => state.core.initiatives.files.get.data);
const getLinksDataSelector = (0, selectors_1.createSonarSelector)((state) => state.core.initiatives.links.get.data);
const InitiativeMedia = ({ initiativeId }) => {
    const [listSelected, setListSelected] = (0, react_1.useState)('');
    const [showLinkModal, setShowLinkModal] = (0, react_1.useState)(false);
    const [showList, setShowList] = (0, react_1.useState)(false);
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const files = (0, _hooks_1.useSonarSelector)(getFilesDataSelector);
    const links = (0, _hooks_1.useSonarSelector)(getLinksDataSelector);
    const getFiles = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreInitiativesFiles);
    const postFile = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCoreInitiativesFiles);
    const getLinks = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreInitiativesLinks);
    const postLink = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCoreInitiativesLinks);
    (0, _hooks_1.useOnMount)(() => {
        getFiles({
            count: 100,
            initiativeId,
            offset: 0
        });
        getLinks({
            count: 100,
            initiativeId
        });
    });
    const handleSetShow = (toShow) => {
        const toggleOff = showList && toShow === listSelected;
        if (toggleOff) {
            setShowList(false);
            setTimeout(() => {
                setListSelected('');
            }, 200);
        }
        else {
            setListSelected(toShow);
            setShowList(true);
        }
    };
    const handleShowFilesList = () => {
        setListSelected('files');
        setShowList(true);
    };
    const handleOnFileSave = (options) => {
        const file = options.file;
        if (file.size <= 25000000) {
            postFile({
                file,
                initiativeId,
                name: file.name
            });
        }
        else {
            antd_1.notification.error({
                description: sonar_ts_constants_1.NOTIFICATIONS_COPY.ATTACHMENTS_MUST_BE_LESS_THAN_25MB,
                message: sonar_ts_constants_1.COPY_GENERIC.MAX_FILE_SIZE_EXCEEDED,
                placement: 'bottomRight'
            });
        }
    };
    const handleCloseLinkModal = () => {
        setShowLinkModal(false);
    };
    const handleSaveLink = (values) => {
        const { title, url } = values;
        postLink({
            initiativeId,
            title,
            url: (0, sonar_ts_lib_1.makeValidUrl)(url)
        });
        handleCloseLinkModal();
    };
    const handleShowLinkModal = () => {
        setListSelected('links');
        setShowLinkModal(true);
        setShowList(true);
    };
    const linksCount = links === null || links === void 0 ? void 0 : links.results.length;
    const filesCount = files === null || files === void 0 ? void 0 : files.results.length;
    const externalLinksText = `${sonar_ts_constants_1.COPY_SPECIFIC.EXTERNAL_LINKS}${linksCount ? ` (${linksCount})` : ''}`;
    const attachedFilesText = `${sonar_ts_constants_1.COPY_SPECIFIC.ATTACHED_FILES}${filesCount ? ` (${filesCount})` : ''}`;
    return (react_1.default.createElement("div", { id: 'initiatives-media' },
        react_1.default.createElement(antd_1.Radio.Group, { value: listSelected },
            react_1.default.createElement(antd_1.Space, { size: Number(css.size8) },
                react_1.default.createElement(antd_1.Space, { size: Number(css.size2), style: { whiteSpace: 'nowrap' } },
                    react_1.default.createElement(antd_1.Radio.Button, { disabled: !(links === null || links === void 0 ? void 0 : links.results), id: 'initiative-links-button', onClick: handleSetShow.bind(this, 'links'), value: 'links' }, externalLinksText),
                    react_1.default.createElement(react_if_1.When, { condition: !isReadOnly },
                        react_1.default.createElement(antd_1.Button, { icon: react_1.default.createElement(icons_1.LinkOutlined, null), loading: !(links === null || links === void 0 ? void 0 : links.results), onClick: handleShowLinkModal, style: { paddingBottom: '1px' }, type: listSelected === 'links' ? 'primary' : 'default' }))),
                react_1.default.createElement(antd_1.Space, { size: Number(css.size2), style: { whiteSpace: 'nowrap' } },
                    react_1.default.createElement(antd_1.Radio.Button, { disabled: !(files === null || files === void 0 ? void 0 : files.results), id: 'initiative-files-button', onClick: handleSetShow.bind(this, 'files'), value: 'files' }, attachedFilesText),
                    react_1.default.createElement(react_if_1.When, { condition: !isReadOnly },
                        react_1.default.createElement(antd_1.Upload, { customRequest: handleOnFileSave, showUploadList: false },
                            react_1.default.createElement(antd_1.Button, { disabled: !(files === null || files === void 0 ? void 0 : files.results), icon: react_1.default.createElement(icons_1.PaperClipOutlined, null), loading: !(files === null || files === void 0 ? void 0 : files.results), onClick: handleShowFilesList, type: listSelected === 'files' ? 'primary' : 'default' })))),
                react_1.default.createElement(antd_1.Space, null,
                    react_1.default.createElement(JiraIssuesButton_1.JiraIssuesButton, { initiativeId: initiativeId })),
                react_1.default.createElement(LinkModal_1.LinkModal, { onCancel: handleCloseLinkModal, onOk: handleSaveLink, open: showLinkModal }),
                showList &&
                    react_1.default.createElement(antd_1.Button, { id: 'initiative-hide-list-button', onClick: handleSetShow.bind(this, listSelected), type: 'link' }, sonar_ts_constants_1.COPY_SPECIFIC.HIDE_LIST))),
        react_1.default.createElement(MediaList_1.InitiativeMediaList, { listSelected: listSelected, showList: showList }),
        react_1.default.createElement(notifications_1.PostInitiativeFileNotifications, null),
        react_1.default.createElement(notifications_1.PostInitiativeLinkNotifications, null)));
};
exports.InitiativeMedia = InitiativeMedia;
