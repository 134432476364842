"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReferenceSummary = void 0;
const css = __importStar(require("css/exports.module.scss"));
const refCss = __importStar(require("./ReferenceSummary.module.scss"));
require("./ReferenceSummary.scss");
const sonar_analytics_1 = require("@sonar-software/sonar-analytics");
const DashboardCard_1 = require("./DashboardCard");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const recharts_1 = require("recharts");
const react_if_1 = require("react-if");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const displayedEntities = ['Account', 'Lead', 'Contact', 'Opportunity'];
const displayedRefCounts = {
    apexClasses: {
        color: refCss.apexClasses,
        label: sonar_ts_constants_1.REFERENCE_SUMMARY_COPY.APEX_CLASSES
    },
    apexTriggers: {
        color: refCss.apexTriggers,
        label: sonar_ts_constants_1.REFERENCE_SUMMARY_COPY.APEX_TRIGGERS
    },
    fields: {
        color: refCss.lookupFields,
        label: sonar_ts_constants_1.REFERENCE_SUMMARY_COPY.LOOKUP_FIELDS
    },
    flows: {
        color: refCss.flows,
        label: sonar_ts_constants_1.REFERENCE_SUMMARY_COPY.FLOWS
    },
    formulaFields: {
        color: refCss.formulaFields,
        label: sonar_ts_constants_1.REFERENCE_SUMMARY_COPY.FORMULA_FIELDS
    },
    processBuilders: {
        color: refCss.processBuilders,
        label: sonar_ts_constants_1.REFERENCE_SUMMARY_COPY.PROCESS_BUILDERS
    },
    validationRules: {
        color: refCss.validationRules,
        label: sonar_ts_constants_1.REFERENCE_SUMMARY_COPY.VALIDATION_RULES
    },
    workflowRules: {
        color: refCss.workflowRules,
        label: sonar_ts_constants_1.REFERENCE_SUMMARY_COPY.WORKFLOW_RULES
    }
};
function getSampleObjectsData() {
    return __awaiter(this, void 0, void 0, function* () {
        const response = yield (0, sonar_core_1.getSalesforceRefCountsByEntity)({ names: displayedEntities });
        if (!response || !response.data) {
            return [];
        }
        const chartData = [];
        Object.keys(response.data).forEach((entityKey) => {
            var _a;
            const entityData = (_a = response.data) === null || _a === void 0 ? void 0 : _a[entityKey];
            if (!entityData) {
                return;
            }
            const mappedData = {};
            for (const key of (0, sonar_ts_lib_1.objectKeys)(displayedRefCounts)) {
                const refCountConfig = displayedRefCounts[key];
                const refCountData = entityData[key];
                mappedData[refCountConfig.label] = refCountData !== null && refCountData !== void 0 ? refCountData : 0;
            }
            chartData.push(Object.assign({ displayName: entityData.displayName, internalName: entityData.internalName, sonarObjectId: entityData.sonarObjectId }, mappedData));
        });
        return chartData;
    });
}
const ReferenceSummary = () => {
    const { startPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const [sfRefCountsChartData, setSfRefCountsChartData] = react_1.default.useState();
    const loadData = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        const data = yield getSampleObjectsData();
        setSfRefCountsChartData(data);
    }), [setSfRefCountsChartData]);
    (0, _hooks_1.useOnMount)(() => {
        loadData();
    });
    return (react_1.default.createElement(DashboardCard_1.DashboardCard, { className: 'reference-summary', title: sonar_ts_constants_1.REFERENCE_SUMMARY_COPY.CHART_HEADER },
        react_1.default.createElement("div", { className: 'chart-container' },
            react_1.default.createElement(react_if_1.If, { condition: sfRefCountsChartData !== undefined && sfRefCountsChartData !== null },
                react_1.default.createElement(react_if_1.Then, null,
                    react_1.default.createElement(recharts_1.BarChart, { barGap: 1, barSize: 40, data: sfRefCountsChartData, height: 400, margin: {
                            bottom: 0,
                            left: 5,
                            right: 0,
                            top: 40
                        }, width: 540 },
                        react_1.default.createElement(recharts_1.CartesianGrid, { horizontal: false, vertical: false }),
                        react_1.default.createElement(recharts_1.XAxis, { axisLine: false, dataKey: 'displayName', stroke: refCss.xAxisStroke, tickSize: 0 }),
                        react_1.default.createElement(recharts_1.YAxis, { label: {
                                angle: -90,
                                value: sonar_ts_constants_1.REFERENCE_SUMMARY_COPY.REFERENCE_COUNT
                            }, stroke: refCss.yAxisStroke, tickSize: 0 }),
                        react_1.default.createElement(recharts_1.Tooltip, null),
                        react_1.default.createElement(recharts_1.Legend, { wrapperStyle: {
                                fontSize: Number(refCss.legendFontSize),
                                padding: Number(css.size8)
                            } }),
                        Object.keys(displayedRefCounts).map((key) => react_1.default.createElement(recharts_1.Bar, { dataKey: displayedRefCounts[key].label, fill: displayedRefCounts[key].color, key: key, onClick: (datum) => {
                                startPeriscope({
                                    from: sonar_analytics_1.AMPLITUDE_PERISCOPE_EVENT_SOURCE.DASHBOARD_VIEW,
                                    id: datum.sonarObjectId,
                                    name: datum.displayName,
                                    type: sonar_ts_types_1.SonarObjectType.SalesforceEntity
                                });
                            }, stackId: 'a' })))),
                react_1.default.createElement(react_if_1.Else, null,
                    react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, null))))));
};
exports.ReferenceSummary = ReferenceSummary;
