"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentDisplay = void 0;
require("./CommentDisplay.scss");
const CommentAuthor_1 = require("./CommentAuthor");
const compatible_1 = require("@ant-design/compatible");
const Editor_1 = require("@ui/Editor");
const general_1 = require("@ui/general");
const SonarUser_1 = require("@ui/SonarUser");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const CommentDisplay = ({ comment }) => {
    const [isEditing, toggleEditing] = (0, _hooks_1.useToggle)(false);
    const remove = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCoreInitiativesCommentsById);
    const update = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreInitiativesCommentsById);
    const { addedBy, addedOn, body, bodyHtml, id, initiativeId } = comment;
    const handleDelete = (0, react_1.useCallback)(() => remove({
        id,
        initiativeId
    }), [id, initiativeId, remove]);
    const handleOnSaveClick = (0, react_1.useCallback)((updatedBody, updatedBodyHtml, taggedUsers) => {
        update({
            body: updatedBody,
            bodyHtml: updatedBodyHtml,
            id: comment.id,
            initiativeId: comment.initiativeId,
            taggedUsers
        });
        toggleEditing();
    }, [comment.id, comment.initiativeId, toggleEditing, update]);
    const displayContent = isEditing ?
        react_1.default.createElement(Editor_1.Editor, { bordered: true, content: body, editing: true, enableMentions: true, onCancel: toggleEditing, onSave: handleOnSaveClick }) :
        react_1.default.createElement(general_1.SanitizedHtml, { html: bodyHtml });
    return (react_1.default.createElement(compatible_1.Comment, { author: react_1.default.createElement(CommentAuthor_1.CommentAuthor, { authorUserId: addedBy, commentId: id, createDate: addedOn, onDelete: handleDelete, onEdit: toggleEditing }), avatar: react_1.default.createElement(SonarUser_1.SonarUser, { userId: addedBy, withFullName: false }), className: 'initiative-comment', content: displayContent }));
};
exports.CommentDisplay = CommentDisplay;
