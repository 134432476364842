"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLatestImportCounts = useLatestImportCounts;
const selectors_1 = require("@lib/selectors");
const sonar_imports_1 = require("@sonar-software/sonar-imports");
const _hooks_1 = require("@hooks");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.imports.latest.counts);
function useLatestImportCounts(refresh = false) {
    const getImportCounts = (0, _hooks_1.useAction)(sonar_imports_1.importsActions.requestGetImportsLatestCounts);
    const importCounts = (0, _hooks_1.useSonarSelector)(selectors.data);
    (0, _hooks_1.useOnMount)(() => {
        if (refresh) {
            getImportCounts();
        }
    });
    return importCounts !== null && importCounts !== void 0 ? importCounts : {};
}
useLatestImportCounts.selectors = selectors;
