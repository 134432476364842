"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.v3FieldSortOptions = exports.fieldSortOptions = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
exports.fieldSortOptions = [
    {
        direction: 'ascending',
        key: 'name',
        label: sonar_ts_constants_1.COPY_SPECIFIC.NAME
    },
    {
        direction: 'descending',
        key: 'refcount',
        label: sonar_ts_constants_1.COPY_SPECIFIC.REFERENCE_COUNT
    },
    {
        direction: 'ascending',
        key: 'type',
        label: sonar_ts_constants_1.COPY_SPECIFIC.FIELD_TYPE
    },
    {
        direction: 'descending',
        key: 'tagcount',
        label: sonar_ts_constants_1.COPY_SPECIFIC.TAG_COUNT
    },
    {
        direction: 'descending',
        disabledTooltip: sonar_ts_constants_1.COPY_SPECIFIC.DOWNLOAD_FIELDSPY,
        key: 'populationPercentage',
        label: sonar_ts_constants_1.COPY_SPECIFIC.POPULATION_PERCENTAGE
    }
];
exports.v3FieldSortOptions = [
    Object.assign(Object.assign({}, exports.fieldSortOptions[0]), { direction: sonar_ts_types_1.SonarV3SortDirection.Ascending }),
    Object.assign(Object.assign({}, exports.fieldSortOptions[1]), { direction: sonar_ts_types_1.SonarV3SortDirection.Descending }),
    Object.assign(Object.assign({}, exports.fieldSortOptions[2]), { direction: sonar_ts_types_1.SonarV3SortDirection.Ascending }),
    Object.assign(Object.assign({}, exports.fieldSortOptions[3]), { direction: sonar_ts_types_1.SonarV3SortDirection.Descending }),
    Object.assign(Object.assign({}, exports.fieldSortOptions[4]), { direction: sonar_ts_types_1.SonarV3SortDirection.Descending })
];
