"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PulseAppName = void 0;
var PulseAppName;
(function (PulseAppName) {
    PulseAppName["ActOn"] = "ActOn";
    PulseAppName["AddressTools"] = "AddressTools";
    PulseAppName["AdobeSign"] = "Adobe Sign";
    PulseAppName["Adroll"] = "Adroll";
    PulseAppName["AdWords"] = "AdWords Conversion Import";
    PulseAppName["Aeon2"] = "Aeon2";
    PulseAppName["AgileVizArt"] = "AgileVizArt";
    PulseAppName["AgileVizArtChatter"] = "AgileVizArt Chatter";
    PulseAppName["Altify"] = "Altify";
    PulseAppName["AmazonAppFlow"] = "Amazon AppFlow Embedded Login App";
    PulseAppName["Ambition"] = "Ambition";
    PulseAppName["Apollo"] = "Apollo";
    PulseAppName["ArticleQuality"] = "Article Quality";
    PulseAppName["AscendixSearch"] = "Ascendix Search";
    PulseAppName["AskNicely"] = "AskNicely";
    PulseAppName["Avature"] = "Avature";
    PulseAppName["AzureDataFactory"] = "Azure DataFactory";
    PulseAppName["AzurePurview"] = "Azure Purview";
    PulseAppName["Beynd"] = "Beynd";
    PulseAppName["Bizible"] = "Bizible";
    PulseAppName["Blackthorn"] = "Blackthorn | Connected App";
    PulseAppName["Brainshark"] = "Brainshark";
    PulseAppName["BulkObjectCreator"] = "Bulk Object Creator";
    PulseAppName["Calendly"] = "Calendly";
    PulseAppName["CarrierDownloads"] = "Carrier Downloads";
    PulseAppName["Celigo"] = "Celigo";
    PulseAppName["Celigo2"] = "Celigo 2";
    PulseAppName["Celoxis"] = "Celoxis";
    PulseAppName["Certain"] = "Certain";
    PulseAppName["ChiliPiper"] = "Chili Piper - Sales Acceleration 3.0";
    PulseAppName["ChurnZero"] = "Churn Zero";
    PulseAppName["Clari"] = "Clari Copilot";
    PulseAppName["Clearbit"] = "Clearbit";
    PulseAppName["ClientSuccess"] = "ClientSuccess";
    PulseAppName["ClosePlan"] = "ClosePlan";
    PulseAppName["CloudingoAgent"] = "Cloudingo Agent";
    PulseAppName["Coefficient"] = "Coefficient";
    PulseAppName["Cognisim"] = "Cognism Chrome";
    PulseAppName["Collibra"] = "Collibra";
    PulseAppName["CommVault"] = "CommVault";
    PulseAppName["Concur"] = "Concur";
    PulseAppName["ConcurConnector"] = "Concur Connector (US2)";
    PulseAppName["ConfigWorkbookTool"] = "Config Workbook Tool";
    PulseAppName["CongaApprovals"] = "Conga Approvals";
    PulseAppName["ConnectAndSell"] = "ConnectAndSell";
    PulseAppName["ContactClean"] = "Contact Clean";
    PulseAppName["Copado"] = "Copado";
    PulseAppName["CopadoDevOps"] = "Copado DevOps (N.A.)";
    PulseAppName["Correlated"] = "Correlated Labs Salesforce Connection";
    PulseAppName["CPOMS"] = "CPOMS";
    PulseAppName["Crayon"] = "Crayon";
    PulseAppName["Crossbeam"] = "Crossbeam";
    PulseAppName["CrossbeamSalesforce"] = "Crossbeam Salesforce App";
    PulseAppName["Crowdvocate"] = "Crowdvocate";
    PulseAppName["CTUCloneThisUser"] = "CTU: Clone This User";
    PulseAppName["Cvent"] = "Cvent";
    PulseAppName["DataAnalytics"] = "Data Analytics";
    PulseAppName["DataChef"] = "Data Chef";
    PulseAppName["DataDotCom"] = "Data.com";
    PulseAppName["DataFox"] = "DataFox";
    PulseAppName["DataLoader"] = "Data Loader";
    PulseAppName["DataLoaderPartner"] = "Dataloader Partner";
    PulseAppName["DataLoaderIO"] = "dataloader.io_v2";
    PulseAppName["DataMask"] = "Data Mask";
    PulseAppName["Dealhub"] = "Dealhub";
    PulseAppName["DefHC"] = "DefHC";
    PulseAppName["Delighted"] = "Delighted";
    PulseAppName["Demandbase"] = "Demandbase";
    PulseAppName["DeskDotCom"] = "Desk.com";
    PulseAppName["Dialpad"] = "Dialpad";
    PulseAppName["DiscoverOrg"] = "DiscoverOrg";
    PulseAppName["DiscoveryDataLink"] = "Discovery DataLink";
    PulseAppName["DistrEngineNC2"] = "Distr. Engine - NC2";
    PulseAppName["Docusign"] = "Docusign";
    PulseAppName["DocusignConnect"] = "DocuSign/Connect/";
    PulseAppName["Dooly"] = "Dooly";
    PulseAppName["Drift"] = "Drift";
    PulseAppName["DunAndBradstreet"] = "Dun & Bradstreet";
    PulseAppName["DuplicateCheck"] = "Duplicate Check";
    PulseAppName["EditQuotas"] = "Edit Quotas";
    PulseAppName["enosiX"] = "enosiX";
    PulseAppName["EnosixSDK"] = "Enosix SDK";
    PulseAppName["enosixSurface"] = "enosix Surface";
    PulseAppName["FieldFootprint"] = "Field Footprint";
    PulseAppName["FinancialForce"] = "FinancialForce";
    PulseAppName["FinancialForceERP"] = "FinancialForce ERP";
    PulseAppName["FinancialForceInv"] = "FinancialForce Inv.";
    PulseAppName["Five9"] = "Five9";
    PulseAppName["Fivetran"] = "Fivetran Data Loader";
    PulseAppName["FlowActionsBasePack"] = "FlowActionsBasePack";
    PulseAppName["FluxCapacity"] = "Flux Capacity";
    PulseAppName["Folderize"] = "Folderize";
    PulseAppName["FormAssembly"] = "FormAssembly";
    PulseAppName["FullCircle"] = "Full Circle";
    PulseAppName["G2Crowd"] = "G2Crowd";
    PulseAppName["Gainsight"] = "Gainsight";
    PulseAppName["GainsightApp"] = "Gainsight App";
    PulseAppName["Genesys"] = "Genesys";
    PulseAppName["Geopointe"] = "Geopointe";
    PulseAppName["Gong"] = "Gong";
    PulseAppName["GongIntegration"] = "Gong Integration App";
    PulseAppName["GridBuddy"] = "GridBuddy";
    PulseAppName["Groove"] = "Groove";
    PulseAppName["Heap"] = "Heap";
    PulseAppName["HelloSign"] = "HelloSign";
    PulseAppName["HGData"] = "HG Data";
    PulseAppName["Highspot"] = "Highspot";
    PulseAppName["Hightouch"] = "Hightouch";
    PulseAppName["Hubspot"] = "Hubspot";
    PulseAppName["Impartner"] = "Impartner";
    PulseAppName["ImpartnerMDF"] = "Impartner MDF";
    PulseAppName["InMoment"] = "InMoment";
    PulseAppName["IntacctAdvanced"] = "Intacct Advanced";
    PulseAppName["IntacctContract"] = "Intacct Contract";
    PulseAppName["IntegratorIo"] = "Integrator.io";
    PulseAppName["Intercom"] = "Intercom";
    PulseAppName["IntercombySalesforce"] = "Salesforce by Intercom";
    PulseAppName["Ironclad"] = "Ironclad";
    PulseAppName["Jira"] = "Jira Integration";
    PulseAppName["JiraSalesforce"] = "Salesforce & JIRA Cloud Connector";
    PulseAppName["Khoros"] = "Khoros";
    PulseAppName["Kimble"] = "Kimble";
    PulseAppName["KingswaySoft"] = "KingswaySoft/SSISIntegrationToolkit/";
    PulseAppName["Kixie"] = "Kixie";
    PulseAppName["LeanData"] = "LeanData";
    PulseAppName["LeanDataOL"] = "LeanDataOL";
    PulseAppName["LightningSharing"] = "LightningSharing";
    PulseAppName["LinkedIn"] = "LinkedIn";
    PulseAppName["LMSILT"] = "LMS ILT";
    PulseAppName["LMSLearning"] = "LMS Learning";
    PulseAppName["LookupHelper"] = "Lookup Helper";
    PulseAppName["Loop"] = "Loop";
    PulseAppName["Loopio"] = "Loopio";
    PulseAppName["Lusha"] = "Lusha Push to Salesforce";
    PulseAppName["Magentrix"] = "Magentrix";
    PulseAppName["MagicMover"] = "Magic Mover";
    PulseAppName["Mailchimp"] = "Mailchimp";
    PulseAppName["MarketingCloud"] = "Marketing Cloud";
    PulseAppName["Marketo"] = "Marketo";
    PulseAppName["MarketoLeadMgm"] = "Marketo Lead Mgm";
    PulseAppName["Maxio"] = "Maxio";
    PulseAppName["MilestonesPM"] = "Milestones PM+";
    PulseAppName["MissionControl"] = "Mission Control";
    PulseAppName["Modigie"] = "Modigie";
    PulseAppName["Monday"] = "Monday";
    PulseAppName["MovableInk"] = "Movable Ink: Anubis Data Extractor";
    PulseAppName["MSCI"] = "MSCI";
    PulseAppName["MSCIEntitlements"] = "OneMSCI Entitlement Platform\u00A0";
    PulseAppName["MSCIOne"] = "MSCI ONE API";
    PulseAppName["MSCIIAM"] = "MSCI IAM API";
    PulseAppName["MSCIApps"] = "MSCI Apps Permissioning";
    PulseAppName["MSCIService"] = "MSCI API Service";
    PulseAppName["MSCIAnalytics"] = "MSCI Analytics API Permissioning";
    PulseAppName["MultiViewCalendar"] = "Multi-View Calendar";
    PulseAppName["myViews"] = "myViews";
    PulseAppName["NavigateButton"] = "NavigateButton";
    PulseAppName["nCino"] = "nCino";
    PulseAppName["NetSuiteConnector"] = "NetSuite Connector";
    PulseAppName["NICEinContact"] = "NICE inContact";
    PulseAppName["NPSP"] = "NPSP";
    PulseAppName["Okta"] = "Okta";
    PulseAppName["OktaImports"] = "Okta Imports";
    PulseAppName["OktaWorkflows"] = "Okta Workflows";
    PulseAppName["OneClickUpdate"] = "One Click Update";
    PulseAppName["OneTrust"] = "OneTrust";
    PulseAppName["OnRamp"] = "OnRamp v3";
    PulseAppName["OrgChartPlus"] = "OrgChartPlus";
    PulseAppName["Outreach"] = "Outreach";
    PulseAppName["PagerDuty"] = "PagerDuty";
    PulseAppName["PandaDoc"] = "PandaDoc";
    PulseAppName["Pardot"] = "Pardot";
    PulseAppName["PardotAPI"] = "Pardot API\u00A0";
    PulseAppName["Pendo"] = "Pendo Salesforce Integration";
    PulseAppName["PeopleAI"] = "PeopleAI";
    PulseAppName["PowerBI"] = "PowerBI";
    PulseAppName["Productiv"] = "Productiv";
    PulseAppName["Propel"] = "Propel";
    PulseAppName["Qualified"] = "Qualified";
    PulseAppName["Qualtrics"] = "Qualtrics";
    PulseAppName["RallySupportManager"] = "Rally Support Manager";
    PulseAppName["Reachdesk"] = "Reachdesk";
    PulseAppName["ReferenceEdge"] = "ReferenceEdge";
    PulseAppName["Reveal"] = "Reveal Connect";
    PulseAppName["RightRevRevRec"] = "RightRev Rev Rec";
    PulseAppName["Rippling"] = "Rippling";
    PulseAppName["RLAccess"] = "RL Access";
    PulseAppName["RMKRViewCentral"] = "RMKR ViewCentral";
    PulseAppName["RollupHelper"] = "Rollup Helper";
    PulseAppName["RollupSummary"] = "Rollup Summary";
    PulseAppName["S4G"] = "S4G";
    PulseAppName["SalesforceBilling"] = "Salesforce Billing";
    PulseAppName["SalesforceIQ"] = "SalesforceIQ";
    PulseAppName["SalesforceIQInbox"] = "SalesforceIQ Inbox";
    PulseAppName["SalesforceMaps"] = "Salesforce Maps";
    PulseAppName["SalesHoodPlugin"] = "SalesHood Plugin";
    PulseAppName["SalesInsights"] = "Sales Insights";
    PulseAppName["Salesloft"] = "Salesloft";
    PulseAppName["Scratchpad"] = "Scratchpad";
    PulseAppName["SearchUnify"] = "SearchUnify";
    PulseAppName["Seismic"] = "Seismic";
    PulseAppName["SFAdminConApps"] = "SF Admin Con Apps";
    PulseAppName["SFAgileAccelerator"] = "SF Agile Accelerator";
    PulseAppName["SFChatterApps"] = "SF Chatter Apps";
    PulseAppName["SFCommunities"] = "SF Communities";
    PulseAppName["SFConnectedApps"] = "SF Connected Apps";
    PulseAppName["SFDCChannelOrder"] = "SFDC Channel Order";
    PulseAppName["Sifdata"] = "Sifdata";
    PulseAppName["Simpplr"] = "Simpplr";
    PulseAppName["SixSense"] = "6sense";
    PulseAppName["SixSenseOauth"] = "sfdc_6sense_oauth_app";
    PulseAppName["Skilljar"] = "Skilljar";
    PulseAppName["Slack"] = "Slack";
    PulseAppName["SNforSFDC"] = "SN for SFDC";
    PulseAppName["Sonar"] = "Sonar";
    PulseAppName["Squivr"] = "Squivr";
    PulseAppName["SteelbrickCPQ"] = "Steelbrick CPQ";
    PulseAppName["Superhuman"] = "Superhuman";
    PulseAppName["SurveyMonkey"] = "SurveyMonkey for Salesforce";
    PulseAppName["Tableau"] = "Tableau";
    PulseAppName["TableauViz"] = "Tableau Viz";
    PulseAppName["TableauDesktop"] = "Tableau Desktop";
    PulseAppName["TableauOnline"] = "Tableau Online for Salesforce";
    PulseAppName["TableauServer"] = "Tableau Server";
    PulseAppName["Taskray"] = "Taskray";
    PulseAppName["TechTargetPriority"] = "TechTarget Priority";
    PulseAppName["TemplateVizArt"] = "Template VizArt";
    PulseAppName["Terminus"] = "Terminus";
    PulseAppName["TractionHierarchies"] = "Traction Hierarchies";
    PulseAppName["Trailhead"] = "Trailhead";
    PulseAppName["Trayio"] = "Tray.io";
    PulseAppName["TrustRadius"] = "TrustRadius";
    PulseAppName["Twitter"] = "Twitter";
    PulseAppName["UIPath"] = "UIPath";
    PulseAppName["UIPathProdVM1"] = "UIPath ProdVM1";
    PulseAppName["UIPathProdVM2"] = "UIPath ProdVM2";
    PulseAppName["UIPathProdVM3"] = "UIPath ProdVM3";
    PulseAppName["UIPathProdVM4"] = "UIPath ProdVM4";
    PulseAppName["UIPathProdVM5"] = "UIPath ProdVM5";
    PulseAppName["UIPathProdVM6"] = "UIPath ProdVM6";
    PulseAppName["UIPathProdVM7"] = "UIPath ProdVM7";
    PulseAppName["UIPathProdVM8"] = "UIPath ProdVM8";
    PulseAppName["UIPathTestVM1"] = "UIPath TestVM1";
    PulseAppName["UniqueEntry"] = "Unique Entry";
    PulseAppName["UserGems"] = "UserGems";
    PulseAppName["Valooto"] = "Valooto";
    PulseAppName["ValueCloud"] = "Value Cloud";
    PulseAppName["Varonis"] = "Varonis";
    PulseAppName["Vista"] = "Vista";
    PulseAppName["VisualAntidote"] = "VisualAntidote";
    PulseAppName["VivunInc"] = "Vivun Inc";
    PulseAppName["Vonage"] = "Vonage";
    PulseAppName["WebinfinityPRM"] = "Webinfinity PRM";
    PulseAppName["Workato"] = "Workato";
    PulseAppName["WorkatoConnector"] = "Workato connector";
    PulseAppName["Xactly"] = "Exactly";
    PulseAppName["XactlyExpress"] = "Xactly Express";
    PulseAppName["zAgileConnect"] = "zAgileConnect";
    PulseAppName["Zapier"] = "Zapier";
    PulseAppName["Zendesk"] = "Zendesk";
    PulseAppName["ZendeskIntegration"] = "Salesforce Integration for Zendesk";
    PulseAppName["Zenkraft"] = "Zenkraft";
    PulseAppName["ZenKraftBulk"] = "ZenKraft Bulk";
    PulseAppName["ZigPR"] = "ZigPR";
    PulseAppName["Zoom"] = "Zoom";
    PulseAppName["ZoomInfo"] = "ZoomInfo";
    PulseAppName["ZoomInfoIntegration"] = "ZoomInfo Integration";
    PulseAppName["Zuora"] = "Zuora";
    PulseAppName["Zuora360"] = "Zuora 360";
    PulseAppName["ZuoraCRM"] = "Zuora CRM ID Provisioning Service";
    PulseAppName["ZuoraQuotes"] = "Zuora Quotes";
    PulseAppName["Zylo"] = "Zylo";
})(PulseAppName || (exports.PulseAppName = PulseAppName = {}));
