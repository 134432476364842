"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentUser = void 0;
const hooks_1 = require("@lib/hooks");
const sonar_core_1 = require("@sonar-software/sonar-core");
const empty = { isReadOnly: true };
/**
 * Subscribes to and returns the current user. Will load related data upon mount if data is not
 * available or forced via `refresh`.
 * @param refresh Determines if the component should force load the latest data upon mount. Defaults to `false`.
 * @returns Current {@link User} or empty readonly user.
 */
exports.useCurrentUser = (0, hooks_1.createAsyncStateWithPreloadHook)({
    loadAction: sonar_core_1.coreActions.requestGetCoreUsersCurrent,
    selector: (state) => state.core.users.current.get,
    useTransform: (result) => result.data || empty
});
