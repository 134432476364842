"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useImportStatus = useImportStatus;
const selectors_1 = require("../../lib/selectors");
const useAction_1 = require("../useAction");
const useSonarSelector_1 = require("../useSonarSelector");
const sonar_imports_1 = require("@sonar-software/sonar-imports");
const react_1 = require("react");
const importStatusSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.imports.byId.status.get);
function useImportStatus(importId, latestLogs) {
    const status = (0, useSonarSelector_1.useSonarSelector)(importStatusSelectors.data);
    const loadStatus = (0, useAction_1.useAction)(sonar_imports_1.importsActions.requestGetImportsByIdStatus);
    const refresh = (0, react_1.useCallback)(() => {
        if (!importId) {
            return;
        }
        loadStatus({
            importId,
            latest: latestLogs
        });
    }, [importId, latestLogs, loadStatus]);
    (0, react_1.useEffect)(() => {
        if (importId) {
            refresh();
        }
    }, [importId, refresh]);
    return {
        refresh,
        status
    };
}
