"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GraphBehavior = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_router_1 = require("react-router");
const _hooks_1 = require("@hooks");
const graphin_1 = require("@antv/graphin");
const react_1 = require("react");
const GraphBehavior = () => {
    const { graph } = (0, react_1.useContext)(graphin_1.GraphinContext);
    const navigate = (0, react_router_1.useNavigate)();
    (0, _hooks_1.useOnMount)(() => {
        graph.on('combo:mouseenter', (evt) => {
            const { item } = evt;
            if (item) {
                graph.setItemState(item, 'active', true);
            }
        });
        graph.on('combo:mouseleave', (evt) => {
            const { item } = evt;
            if (item) {
                graph.setItemState(item, 'active', false);
            }
        });
        graph.on('canvas:click', () => {
            graph.getCombos().forEach((combo) => {
                graph.clearItemStates(combo);
            });
        });
        graph.on('node:click', (evt) => {
            const { item } = evt;
            const applicationId = item === null || item === void 0 ? void 0 : item.getID();
            if (Number(applicationId)) {
                navigate(`${sonar_ts_constants_1.FULL_ROUTES.PULSE}/${applicationId}`);
            }
        });
    });
    return null;
};
exports.GraphBehavior = GraphBehavior;
