"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAddStakeholder = useAddStakeholder;
const selectors_1 = require("@lib/selectors");
const useAction_1 = require("../useAction");
const useAsyncState_1 = require("../useAsyncState");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.stakeholders.post);
function useAddStakeholder() {
    const { data, isIdle, isReceived, isRequested } = (0, useAsyncState_1.useAsyncState)(selectors);
    const request = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestPostCoreStakeholders);
    const reset = (0, useAction_1.useAction)(sonar_core_1.coreActions.resetPostCoreStakeholders);
    return {
        isIdle,
        isReceived,
        isRequested,
        request,
        reset,
        response: data
    };
}
useAddStakeholder.selectors = selectors;
