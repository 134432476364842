"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useForm = useForm;
const antd_1 = require("antd");
const react_1 = require("react");
function useForm(options) {
    const { required } = options !== null && options !== void 0 ? options : {};
    const [isValid, setIsValid] = (0, react_1.useState)(false);
    const [form] = antd_1.Form.useForm();
    const requiredFields = (0, react_1.useRef)(required);
    const validate = (0, react_1.useCallback)(() => {
        setTimeout(() => {
            var _a;
            const values = form.getFieldsValue();
            const hasAllValues = Boolean((_a = requiredFields.current) === null || _a === void 0 ? void 0 : _a.every((name) => Boolean(values[name])));
            const hasErrors = form.getFieldsError().filter((entry) => entry.errors.length).length > 0;
            setIsValid(hasAllValues && !hasErrors);
        });
    }, [form]);
    const reset = (0, react_1.useCallback)(() => {
        form.resetFields();
        setIsValid(false);
    }, [form]);
    const formResult = (0, react_1.useMemo)(() => (Object.assign(Object.assign({}, form), { isValid,
        reset,
        validate })), [form, isValid, reset, validate]);
    return {
        Form: antd_1.Form,
        form: formResult
    };
}
