"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforceFieldSelectionTable = void 0;
const sonar_core_1 = require("@sonar-software/sonar-core");
const __1 = require("../");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const _1 = require("./");
const tables_1 = require("@ui/tables");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const SalesforceFieldSelectionTable = ({ parentIndex, resourceIdsUri, showParents, uri }) => {
    const { resource, loading } = (0, _hooks_1.useApiResource)(uri);
    const selectionData = (0, _hooks_1.useSelectionTable)({
        getRowKey: __1.getRowKey,
        resourceIdsUri,
        results: resource,
        selectionType: sonar_ts_types_1.SonarObjectType.SalesforceField
    });
    const updateReferences = (0, _hooks_1.useAction)(sonar_core_1.coreActions.updateSonarObjectReferences);
    (0, react_1.useEffect)(() => {
        if (resource) {
            updateReferences(resource.map((result) => ({
                displayName: result.displayName,
                ingestedBy: result.ingestedBy,
                parentDisplayName: result.parentDisplayName,
                parentSonarObjectId: result.parentSonarObjectId,
                populatedBy: result.populatedBy,
                relatedCounts: result.relatedCounts,
                sonarObjectId: result.sonarObjectId,
                sonarObjectType: sonar_ts_types_1.SonarObjectType.SalesforceField,
                tags: result.tagIds
            })));
        }
    }, [resource, updateReferences]);
    const renderField = (0, react_1.useCallback)((field) => react_1.default.createElement(_1.PeriscopeFieldRow, { field: field, key: field.sonarObjectId, parentIndex: parentIndex, showParents: showParents, useApiNames: false }), [parentIndex, showParents]);
    return (react_1.default.createElement(tables_1.SelectionTable, { dataSource: resource, forceShowSelection: resourceIdsUri ? undefined : false, loading: loading, renderRow: renderField, rowKey: __1.getRowKey, selectionData: selectionData, selectionType: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.FIELDS }));
};
exports.SalesforceFieldSelectionTable = SalesforceFieldSelectionTable;
