"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforceReauthenticationNotification = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const _hooks_1 = require("@hooks");
const react_router_dom_1 = require("react-router-dom");
const SalesforceReauthenticationNotification = () => {
    const location = (0, react_router_dom_1.useLocation)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const { errorNotification, successNotification } = (0, _hooks_1.useAppNotifications)();
    (0, _hooks_1.useOnMount)(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has(sonar_ts_constants_1.QUERY.SALESFORCE_REAUTHENTICATION)) {
            const wasSuccessful = queryParams.get(sonar_ts_constants_1.QUERY.SALESFORCE_REAUTHENTICATION) === 'true';
            queryParams.delete(sonar_ts_constants_1.QUERY.SALESFORCE_REAUTHENTICATION);
            navigate({ search: queryParams.toString() }, { replace: true });
            if (wasSuccessful) {
                successNotification(sonar_ts_constants_1.INTEGRATIONS_COPY.SALESFORCE_ORG_REAUTH_SUCCESS);
            }
            else {
                errorNotification(sonar_ts_constants_1.INTEGRATIONS_COPY.SALESFORCE_ORG_REAUTH_FAILURE);
            }
        }
    });
    return null;
};
exports.SalesforceReauthenticationNotification = SalesforceReauthenticationNotification;
