"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FullUserBoundary = void 0;
const _hooks_1 = require("@hooks");
const react_router_dom_1 = require("react-router-dom");
const FullUserBoundary = ({ redirect, children }) => {
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    if (isReadOnly) {
        if (!redirect) {
            return null;
        }
        navigate(redirect);
    }
    return children;
};
exports.FullUserBoundary = FullUserBoundary;
