"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fieldAccessOptions = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
exports.fieldAccessOptions = [
    {
        label: sonar_ts_constants_1.PERISCOPE_PROFILES_COPY.EDIT_ACCESS,
        value: 'true'
    },
    {
        label: sonar_ts_constants_1.PERISCOPE_PROFILES_COPY.READ_ACCESS,
        value: 'false'
    }
];
