"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UsersTable = void 0;
require("./UsersPanel.scss");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const KeyPagination_1 = require("@ui/KeyPagination");
const SonarTable_1 = require("@ui/SonarTable");
const UserTableSearch_1 = require("./UserTableSearch");
const UsersTableColumns_1 = require("./UsersTableColumns");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const getCustomerUsersSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.customers.byId.users.get);
const UsersTable = ({ defaultPageSize = 20, id, searchDetails }) => {
    const [emailFilter, setEmailFilter] = (0, react_1.useState)('');
    const customerUsers = (0, _hooks_1.useSonarSelector)(getCustomerUsersSelectors.data);
    const getCustomerUsers = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreCustomersByIdUsers);
    const handleEmailFilterChanged = (0, react_1.useCallback)((value) => {
        setEmailFilter(value);
    }, []);
    const getCustomerUsersPage = (0, react_1.useCallback)((count = defaultPageSize, paginationDirection = sonar_ts_types_1.PaginationDirection.Forward, key) => {
        if (searchDetails) {
            const { customerId, shardName } = searchDetails;
            getCustomerUsers({
                count: count !== null && count !== void 0 ? count : defaultPageSize,
                customerId,
                filter: emailFilter,
                key,
                paginationDirection,
                shardName
            });
        }
    }, [defaultPageSize, searchDetails, getCustomerUsers, emailFilter]);
    const handlePaginationChanged = (0, react_1.useCallback)((pagination) => {
        var _a;
        if (customerUsers) {
            const paginationDirection = (_a = pagination.paginationDirection) !== null && _a !== void 0 ? _a : sonar_ts_types_1.PaginationDirection.Forward;
            const key = pagination.paginationDirection === sonar_ts_types_1.PaginationDirection.Forward ?
                customerUsers === null || customerUsers === void 0 ? void 0 : customerUsers.nextKey :
                customerUsers === null || customerUsers === void 0 ? void 0 : customerUsers.prevKey;
            getCustomerUsersPage(pagination.count, paginationDirection, key);
        }
    }, [getCustomerUsersPage, customerUsers]);
    (0, react_1.useEffect)(() => {
        getCustomerUsersPage();
    }, [getCustomerUsersPage]);
    (0, react_1.useEffect)(() => {
        var _a;
        setEmailFilter((_a = searchDetails === null || searchDetails === void 0 ? void 0 : searchDetails.userEmail) !== null && _a !== void 0 ? _a : '');
    }, [searchDetails === null || searchDetails === void 0 ? void 0 : searchDetails.userEmail]);
    const tableColumns = (0, react_1.useMemo)(() => (0, UsersTableColumns_1.getUserTableColumns)(searchDetails === null || searchDetails === void 0 ? void 0 : searchDetails.shardName), [searchDetails === null || searchDetails === void 0 ? void 0 : searchDetails.shardName]);
    return (react_1.default.createElement("div", { className: 'admin-users-table' },
        react_1.default.createElement(UserTableSearch_1.UserTableSearch, { onSearch: handleEmailFilterChanged, value: emailFilter }),
        react_1.default.createElement(SonarTable_1.SonarTable, { columns: tableColumns, dataSource: customerUsers === null || customerUsers === void 0 ? void 0 : customerUsers.results, id: id, pagination: false, rowKey: (record) => (0, UsersTableColumns_1.getUserTableRowKey)(record, searchDetails === null || searchDetails === void 0 ? void 0 : searchDetails.shardName) }),
        react_1.default.createElement(KeyPagination_1.KeyPagination, { autoHide: true, defaultPageSize: defaultPageSize, onChange: handlePaginationChanged, resource: customerUsers })));
};
exports.UsersTable = UsersTable;
