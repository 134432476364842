"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforceAutomationSearchableSelectionTable = exports.automationToSonarObjectTypeMap = void 0;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const tables_1 = require("@ui/tables");
const _1 = require(".");
const __1 = require("..");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
exports.automationToSonarObjectTypeMap = {
    [sonar_ts_types_1.SfEntityAutomationType.EmailAlerts]: sonar_ts_types_1.SonarObjectType.SalesforceWorkflowAlert,
    [sonar_ts_types_1.SfEntityAutomationType.ApexClasses]: sonar_ts_types_1.SonarObjectType.SalesforceApexClass,
    [sonar_ts_types_1.SfEntityAutomationType.ApexTriggers]: sonar_ts_types_1.SonarObjectType.SalesforceApexTrigger,
    [sonar_ts_types_1.SfEntityAutomationType.EmailTemplate]: sonar_ts_types_1.SonarObjectType.SalesforceEmailTemplate,
    [sonar_ts_types_1.SfEntityAutomationType.FieldUpdates]: sonar_ts_types_1.SonarObjectType.SalesforceFieldUpdate,
    [sonar_ts_types_1.SfEntityAutomationType.Flows]: sonar_ts_types_1.SonarObjectType.SalesforceFlow,
    [sonar_ts_types_1.SfEntityAutomationType.FormulaFields]: sonar_ts_types_1.SonarObjectType.SalesforceField,
    [sonar_ts_types_1.SfEntityAutomationType.PageLayouts]: sonar_ts_types_1.SonarObjectType.SalesforcePageLayout,
    [sonar_ts_types_1.SfEntityAutomationType.ProcessBuilders]: sonar_ts_types_1.SonarObjectType.SalesforceProcessBuilder,
    [sonar_ts_types_1.SfEntityAutomationType.ValidationRules]: sonar_ts_types_1.SonarObjectType.SalesforceValidationRule,
    [sonar_ts_types_1.SfEntityAutomationType.Workflows]: sonar_ts_types_1.SonarObjectType.SalesforceWorkflowRule,
    [sonar_ts_types_1.SfEntityAutomationType.AllProcessAutomations]: null
};
const SalesforceAutomationSearchableSelectionTable = ({ childSonarObjectType, parentIndex, parentSonarObjectType, sonarObjectId, type, uri }) => {
    const scrollableListRef = (0, react_1.useRef)(null);
    const sonarObjectType = exports.automationToSonarObjectTypeMap[type || sonar_ts_types_1.SfEntityAutomationType.AllProcessAutomations];
    const selectionTypeLabel = type === sonar_ts_types_1.SfEntityAutomationType.AllProcessAutomations ?
        `${sonar_ts_constants_1.PERISCOPE_TAB_TYPES.PROCESS_AUTOMATION}s` :
        (0, sonar_ts_lib_1.getSonarObjectTypeLabelPlural)(sonarObjectType);
    const { loading, resource, queryParams, updateParams } = (0, _hooks_1.useQueryResource)({
        defaultParams: Object.assign(Object.assign({}, __1.paginationDefaultQueryParams), { hideInactive: true, type }),
        uri
    });
    const { resourceIdsUri } = (0, _hooks_1.useBulkSelectWorkflow)({
        childType: childSonarObjectType,
        parentType: parentSonarObjectType,
        sonarObjectId: sonarObjectId
    });
    const { type: automationType } = queryParams, queryParamsTypeRemoved = __rest(queryParams, ["type"]);
    const selectionData = (0, _hooks_1.useSelectionTable)({
        getRowKey: __1.getRowKey,
        queryParams: queryParamsTypeRemoved,
        resourceIdsUri: resourceIdsUri,
        results: resource,
        selectionType: sonarObjectType
    });
    const renderAutomations = (0, react_1.useCallback)((automation) => react_1.default.createElement(_1.PeriscopeAutomation, { automation: automation, parentIndex: parentIndex, showType: type === sonar_ts_types_1.SfEntityAutomationType.AllProcessAutomations }), [parentIndex, type]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(_1.AutomationSearch, { onChange: updateParams, queryParams: queryParams }),
        react_1.default.createElement(tables_1.SelectionTable, { dataSource: resource, loading: loading, ref: scrollableListRef, renderRow: renderAutomations, rowKey: __1.getRowKey, selectionData: selectionData, selectionType: selectionTypeLabel })));
};
exports.SalesforceAutomationSearchableSelectionTable = SalesforceAutomationSearchableSelectionTable;
