"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.analyticWatchers = void 0;
exports.initializeAmplitudeCustomer = initializeAmplitudeCustomer;
exports.initializeFullstoryCustomer = initializeFullstoryCustomer;
exports.initializePendoCustomer = initializePendoCustomer;
exports.initializeChurnZeroCustomer = initializeChurnZeroCustomer;
exports.initializeAnalyticsWatcherSaga = initializeAnalyticsWatcherSaga;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const effects_1 = require("redux-saga/effects");
const sonar_analytics_1 = require("@sonar-software/sonar-analytics");
const xuder_1 = require("@sonar-software/xuder");
const lib_1 = require("./lib");
const sonar_core_1 = require("@sonar-software/sonar-core");
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* initializeAmplitudeCustomer(currentUser, currentCustomer, organizationCount, userCount) {
    const data = (0, lib_1.convertUserToAmplitudeCustomerData)(currentUser, currentCustomer, organizationCount, userCount);
    yield (0, effects_1.put)(sonar_analytics_1.amplitudeActions.requestInitializeAmplitude(data));
    yield (0, effects_1.put)(sonar_analytics_1.amplitudeActions.requestAmplitudeEvent({ id: sonar_analytics_1.AMPLITUDE_EVENT.LOGIN_EVENT }));
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* initializeFullstoryCustomer(currentUser, currentCustomer) {
    const data = (0, lib_1.convertDataToFullstoryData)(currentUser, currentCustomer);
    yield (0, effects_1.put)(sonar_analytics_1.fullstoryActions.requestInitializeFullstory(data));
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* initializePendoCustomer(currentUser, currentCustomer) {
    const { shard } = (0, sonar_ts_lib_1.decodeJWT)();
    const visitor = (0, lib_1.convertUserToPendoVisitor)(currentUser, shard);
    const account = (0, lib_1.convertCustomerToPendoAccount)(currentCustomer, shard);
    yield (0, effects_1.put)(sonar_analytics_1.pendoActions.requestInitializePendo(account, visitor));
}
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* initializeChurnZeroCustomer(currentUser, currentCustomer) {
    var _a;
    yield (0, effects_1.put)(sonar_analytics_1.churnZeroActions.requestInitializeChurnZero({
        externalAccountId: (_a = currentCustomer.salesforceAccountId) !== null && _a !== void 0 ? _a : '',
        externalContactId: currentUser.email
    }));
}
const receiveCurrentUserAction = sonar_core_1.coreActions.receiveGetCoreUsersCurrent({});
const receiveCurrentCustomerAction = sonar_core_1.coreActions.receiveGetCoreCustomersCurrent({});
const receiveOrganizationsAction = sonar_core_1.coreActions.receiveGetCoreOrganizationsAll({ results: [] });
const receiveUsersAction = sonar_core_1.coreActions.receiveGetCoreUsersAll({ results: [] });
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function* initializeAnalyticsWatcherSaga() {
    var _a, _b, _c, _d;
    const initDependencies = yield (0, effects_1.all)([
        (0, effects_1.take)(receiveCurrentUserAction.type),
        (0, effects_1.take)(receiveCurrentCustomerAction.type),
        (0, effects_1.take)(receiveOrganizationsAction.type),
        (0, effects_1.take)(receiveUsersAction.type)
    ]);
    const currentUser = (_a = initDependencies[0]) === null || _a === void 0 ? void 0 : _a.data;
    const currentCustomer = (_b = initDependencies[1]) === null || _b === void 0 ? void 0 : _b.data;
    const allOrganizations = (_d = (_c = initDependencies[2]) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.results;
    const allUsers = initDependencies[3].data;
    const validOrganizations = allOrganizations.filter((org) => org.hasSalesforceData);
    yield (0, effects_1.call)(initializeAmplitudeCustomer, currentUser, currentCustomer, validOrganizations.length, allUsers.length);
    yield (0, effects_1.call)(initializeFullstoryCustomer, currentUser, currentCustomer);
    yield (0, effects_1.call)(initializePendoCustomer, currentUser, currentCustomer);
    yield (0, effects_1.call)(initializeChurnZeroCustomer, currentUser, currentCustomer);
}
exports.analyticWatchers = (0, xuder_1.combineAllWatchers)([
    sonar_analytics_1.amplitudeRedux.amplitudeWatchers,
    sonar_analytics_1.churnZeroRedux.churnZeroWatchers,
    sonar_analytics_1.fullstoryRedux.fullstoryWatchers,
    sonar_analytics_1.pendoRedux.pendoWatchers,
    initializeAnalyticsWatcherSaga
]);
