"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DisconnectButton = void 0;
const selectors_1 = require("@lib/selectors");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const organizationsSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.organizations.all.get);
const unlinkOrganizationSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.organizations.unlink.post);
const DisconnectButton = ({ organization }) => {
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const { infoNotification } = (0, _hooks_1.useAppNotifications)();
    const currentOrganization = (0, _hooks_1.useCurrentOrganization)();
    const showDisconnectButton = !isReadOnly && (currentOrganization === null || currentOrganization === void 0 ? void 0 : currentOrganization.id) !== organization.id;
    const isUnlinkSuccessful = (0, _hooks_1.useSonarSelector)(unlinkOrganizationSelectors.isReceived);
    const requestUnlink = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPostCoreOrganizationsUnlink);
    const resetUnlinkRequest = (0, _hooks_1.useAction)(sonar_core_1.coreActions.resetPostCoreOrganizationsUnlink);
    const organizations = (0, _hooks_1.useAsyncCollection)(organizationsSelectors.state);
    const organizationCount = organizations === null || organizations === void 0 ? void 0 : organizations.length;
    (0, react_1.useEffect)(() => {
        if (isUnlinkSuccessful) {
            resetUnlinkRequest();
        }
    }, [isUnlinkSuccessful, resetUnlinkRequest]);
    const handleRemoveOrganization = (0, react_1.useCallback)(() => {
        const { id, name } = organization;
        antd_1.Modal.confirm({
            content: sonar_ts_constants_1.SETTINGS_ORGANIZATION_COPY.DISCONNECT_MESSAGE,
            okType: 'danger',
            onOk() {
                if (organizationCount > 1) {
                    requestUnlink({ organizationId: id });
                }
                else {
                    infoNotification(sonar_ts_constants_1.SETTINGS_ORGANIZATION_COPY.DISCONNECT_WARNING);
                }
            },
            title: sonar_ts_constants_1.SETTINGS_ORGANIZATION_COPY.DISCONNECT_TITLE(name)
        });
    }, [infoNotification, organization, organizationCount, requestUnlink]);
    return (react_1.default.createElement(react_if_1.When, { condition: showDisconnectButton },
        react_1.default.createElement(antd_1.Button, { danger: true, onClick: () => {
                handleRemoveOrganization();
            }, type: 'text' }, sonar_ts_constants_1.SETTINGS_ORGANIZATION_COPY.DISCONNECT)));
};
exports.DisconnectButton = DisconnectButton;
