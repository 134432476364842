"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AccountInformation = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const antd_1 = require("antd");
const selectors_1 = require("@lib/selectors");
const form_items_1 = require("@ui/form-items");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const patchUserStateSelector = (0, selectors_1.createSonarSelector)((state) => state.core.users.current.patch);
const isUserUpdatingSelector = (0, selectors_1.createIsRequestedSelector)(patchUserStateSelector);
const buttonText = `${sonar_ts_constants_1.COPY_SPECIFIC.UPDATE} ${sonar_ts_constants_1.COPY_SPECIFIC.NAME}`;
const AccountInformation = () => {
    const { firstName, lastName, id } = (0, _hooks_1.useCurrentUser)();
    const isUserUpdating = (0, _hooks_1.useSonarSelector)(isUserUpdatingSelector);
    const updateUser = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreUsersCurrent);
    const handleFinish = (0, react_1.useCallback)((userUpdateOptions) => {
        if (!id) {
            return;
        }
        updateUser(Object.assign(Object.assign({}, userUpdateOptions), { userId: id }));
    }, [id, updateUser]);
    return (react_1.default.createElement("div", { id: 'account-information' },
        react_1.default.createElement(antd_1.Form, { initialValues: {
                firstName,
                lastName
            }, layout: 'vertical', name: 'account-information', onFinish: handleFinish, requiredMark: false },
            react_1.default.createElement(antd_1.Row, { gutter: 16 },
                react_1.default.createElement(antd_1.Col, { span: 12 },
                    react_1.default.createElement(form_items_1.FirstName, { disabled: false, id: 'user-first-name', label: sonar_ts_constants_1.COPY_SPECIFIC.FIRST_NAME })),
                react_1.default.createElement(antd_1.Col, { span: 12 },
                    react_1.default.createElement(form_items_1.LastName, { disabled: false, id: 'user-last-name', label: sonar_ts_constants_1.COPY_SPECIFIC.LAST_NAME }))),
            react_1.default.createElement(antd_1.Button, { disabled: isUserUpdating, htmlType: 'submit', id: 'update-user-name-submit-button', type: 'primary' }, buttonText))));
};
exports.AccountInformation = AccountInformation;
