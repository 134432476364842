"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useLatestSalesforceSync = useLatestSalesforceSync;
const useSonarSelector_1 = require("../useSonarSelector");
const selectors_1 = require("@lib/selectors");
const empty = [];
const latestSyncSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.imports.latest.salesforce);
const singleSyncSelector = (0, selectors_1.createSonarSelector)((state) => { var _a; return ((_a = latestSyncSelectors.data(state)) !== null && _a !== void 0 ? _a : empty)[0]; });
function compare(prev, next) {
    if ((!prev && next) || (prev && !next)) {
        return false;
    }
    if (!prev && !next) {
        return true;
    }
    return (prev.id === next.id &&
        prev.status === next.status &&
        prev.finishedOn === next.finishedOn &&
        prev.logs.length === next.logs.length);
}
function useLatestSalesforceSync() {
    return (0, useSonarSelector_1.useSonarSelector)(singleSyncSelector, compare);
}
