"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentOrganization = useCurrentOrganization;
const useAuthToken_1 = require("../useAuthToken");
const useOrganizations_1 = require("./useOrganizations");
/**
 * Subscribes to the current organization the current user is set to.
 * May be undefined if the auth token does not have an `organizationId` set, or there is no matching
 * organization in the current state.
 * @returns An {@link OrganizationMetadata} instance, or undefined.
 */
function useCurrentOrganization() {
    const { organizationId } = (0, useAuthToken_1.useAuthToken)();
    const organizations = (0, useOrganizations_1.useOrganizations)();
    if (!organizationId || !organizations.length) {
        return undefined;
    }
    return organizations.find((org) => org.id === organizationId);
}
