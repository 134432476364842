"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriscopeHeader = void 0;
const color = __importStar(require("./PeriscopeHeader.module.scss"));
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const PeriscopeClose_1 = require("./PeriscopeClose");
const PeriscopeSourceLabel_1 = require("./PeriscopeSourceLabel");
const PeriscopeTypeLabel_1 = require("./PeriscopeTypeLabel");
const react_1 = __importStar(require("react"));
const headerBackgroundColorMap = {
    [sonar_ts_constants_1.ORGANIZATION_TYPE_LABELS.PARDOT]: color.pardotBlue,
    [sonar_ts_constants_1.ORGANIZATION_TYPE_LABELS.SALESFORCE]: color.salesforceBlue,
    [sonar_ts_constants_1.ORGANIZATION_TYPE_LABELS.SONAR]: color.sonarBlue
};
const PeriscopeHeader = ({ headerLabel, index, sonarObjectType, subType, url }) => {
    const headerColor = (0, react_1.useMemo)(() => headerBackgroundColorMap[(0, sonar_ts_lib_1.getSonarObjectTypeSourceLabel)(sonarObjectType)], [sonarObjectType]);
    return (react_1.default.createElement("div", { className: 'periscope-header-wrapper', style: { background: headerColor } },
        react_1.default.createElement(PeriscopeTypeLabel_1.PeriscopeTypeLabel, { headerLabel: headerLabel, sonarObjectType: sonarObjectType, subType: subType }),
        react_1.default.createElement(PeriscopeSourceLabel_1.PeriscopeSourceLabel, { sonarObjectType: sonarObjectType, url: url }),
        react_1.default.createElement(PeriscopeClose_1.PeriscopeClose, { index: index })));
};
exports.PeriscopeHeader = PeriscopeHeader;
