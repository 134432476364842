"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchablePeriscopeFieldGroupList = void 0;
require("./SearchablePeriscopeFieldGroupList.scss");
const FieldSortOptions_1 = require("./FieldSortOptions");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const PeriscopeFieldGroupList_1 = require("./PeriscopeFieldGroupList");
const _hooks_1 = require("@hooks");
const _1 = require(".");
const __1 = require("..");
const react_1 = __importStar(require("react"));
const SearchablePeriscopeFieldGroupList = (_a) => {
    var { mapGroup, uri } = _a, rest = __rest(_a, ["mapGroup", "uri"]);
    const [transformedGroups, setTransformedGroups] = (0, react_1.useState)([]);
    const { loading, resource, queryParams, updateParams } = (0, _hooks_1.useQueryResource)({
        defaultParams: Object.assign(Object.assign({}, __1.paginationDefaultQueryParams), { sortBy: FieldSortOptions_1.fieldSortOptions[1].key, sortDirection: FieldSortOptions_1.fieldSortOptions[1].direction }),
        uri
    });
    (0, react_1.useEffect)(() => {
        if (resource) {
            setTransformedGroups(mapGroup ? resource.map(mapGroup) : resource);
        }
    }, [mapGroup, resource]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(_1.PeriscopeFieldSearch, { onChange: updateParams, queryParams: queryParams }),
        react_1.default.createElement("div", { className: 'searchable-periscope-field-group-list' },
            react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, { spinning: loading },
                react_1.default.createElement(PeriscopeFieldGroupList_1.PeriscopeFieldGroupList, Object.assign({}, rest, { groups: transformedGroups, useApiNames: Boolean(queryParams.useInternal) }))))));
};
exports.SearchablePeriscopeFieldGroupList = SearchablePeriscopeFieldGroupList;
