"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InvitationView = void 0;
const form_items_1 = require("@ui/form-items");
const selectors_1 = require("@lib/selectors");
const general_1 = require("../general");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const sonar_security_1 = require("@sonar-software/sonar-security");
const react_router_dom_1 = require("react-router-dom");
const general_2 = require("@ui/general");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const acceptInvitationSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.security.invitations.accept.post);
const InvitationView = () => {
    const { token } = (0, react_router_dom_1.useParams)();
    const isErrored = (0, _hooks_1.useSonarSelector)(acceptInvitationSelectors.isErrored);
    const isFailed = (0, _hooks_1.useSonarSelector)(acceptInvitationSelectors.isFailed);
    const isRequested = (0, _hooks_1.useSonarSelector)(acceptInvitationSelectors.isRequested);
    const isSuccessful = (0, _hooks_1.useSonarSelector)(acceptInvitationSelectors.isReceived);
    const request = (0, _hooks_1.useAction)(sonar_security_1.securityActions.requestPostSecurityInvitationsAccept);
    const reset = (0, _hooks_1.useAction)(sonar_security_1.securityActions.resetPostSecurityInvitationsAccept);
    const disabled = isRequested || isSuccessful;
    const { resource, loading } = (0, _hooks_1.useApiResource)(`v3/security/authentication/invite/status/${token}`);
    (0, react_1.useEffect)(() => {
        if (isSuccessful) {
            window.location.replace(sonar_ts_constants_1.FULL_ROUTES.DASHBOARD);
        }
    }, [isSuccessful]);
    const handleOnFinish = (0, react_1.useCallback)((values) => {
        request({
            password: values.password,
            verificationToken: token !== null && token !== void 0 ? token : ''
        });
    }, [request, token]);
    const handleOnValuesChange = (0, react_1.useCallback)(() => {
        if (isErrored || isFailed) {
            reset();
        }
    }, [isErrored, isFailed, reset]);
    if (loading) {
        return react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, null);
    }
    if (resource === null || resource === void 0 ? void 0 : resource.isExpired) {
        return (react_1.default.createElement(general_1.GatewayCard, { id: 'Invitation-Expired', title: sonar_ts_constants_1.COPY_SPECIFIC.LINK_EXPIRED },
            react_1.default.createElement("p", null,
                react_1.default.createElement(general_2.SanitizedHtml, { as: 'span', html: sonar_ts_constants_1.COPY_SPECIFIC.LINK_EXPIRED_DESCRIPTION(sonar_ts_constants_1.EMAILS.SUPPORT) }))));
    }
    return (react_1.default.createElement(general_1.GatewayCard, { id: 'Invitation', title: sonar_ts_constants_1.COPY_SPECIFIC.ACCEPT_INVITATION },
        react_1.default.createElement(antd_1.Form, { onFinish: handleOnFinish, onValuesChange: handleOnValuesChange },
            react_1.default.createElement(general_2.AsyncStateAlert, { errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_POST_AUTH_ACTIVATE_INVITED_USER, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_POST_AUTH_ACTIVATE_INVITED_USER, stateSelector: acceptInvitationSelectors.state }),
            react_1.default.createElement(form_items_1.ConfirmPassword, { disabled: disabled, id: 'invitiation-password', size: 'large' }),
            react_1.default.createElement(antd_1.Row, { justify: 'end' },
                react_1.default.createElement(antd_1.Button, { disabled: disabled, htmlType: 'submit', id: 'invitation-create-submit-button', type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.CREATE_SONAR_ACCOUNT)))));
};
exports.InvitationView = InvitationView;
