"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.globalSelectionSelector = void 0;
exports.useGlobalSelect = useGlobalSelect;
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("../lib/selectors");
const useAction_1 = require("./useAction");
const useSonarSelector_1 = require("./useSonarSelector");
const react_1 = require("react");
exports.globalSelectionSelector = (0, selectors_1.createSonarSelector)((state) => state.core.selections.read);
function useGlobalSelect() {
    const coreSelection = (0, useSonarSelector_1.useSonarSelector)(exports.globalSelectionSelector).data;
    const getGlobalSelection = (0, react_1.useCallback)((options) => {
        var _a, _b;
        const typesSet = new Set(options === null || options === void 0 ? void 0 : options.types);
        const excludeTypesSet = new Set(options === null || options === void 0 ? void 0 : options.excludeTypes);
        const result = [];
        const namespaceSelections = (_b = coreSelection === null || coreSelection === void 0 ? void 0 : coreSelection[(_a = options === null || options === void 0 ? void 0 : options.namespace) !== null && _a !== void 0 ? _a : 'global']) !== null && _b !== void 0 ? _b : {};
        for (const [id, { sonarObjectType }] of Object.entries(namespaceSelections)) {
            if ((!(options === null || options === void 0 ? void 0 : options.types) && !(options === null || options === void 0 ? void 0 : options.excludeTypes)) ||
                typesSet.has(sonarObjectType) ||
                !excludeTypesSet.has(sonarObjectType)) {
                result.push(Number(id));
            }
        }
        return result;
    }, [coreSelection]);
    const globalSelection = (0, react_1.useMemo)(() => getGlobalSelection(), [getGlobalSelection]);
    const addSelection = (0, useAction_1.useAction)(sonar_core_1.coreActions.addCoreSelections);
    const removeSelection = (0, useAction_1.useAction)(sonar_core_1.coreActions.removeCoreSelections);
    return {
        addSelection,
        getGlobalSelection,
        globalSelection,
        removeSelection,
        totalCount: globalSelection.length
    };
}
