"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentOrganizationSubdomain = useCurrentOrganizationSubdomain;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const useCurrentOrganization_1 = require("./useCurrentOrganization");
const defaultData = {
    instanceUrl: '',
    isSandbox: false
};
function useCurrentOrganizationSubdomain() {
    var _a;
    const { instanceUrl, isSandbox } = (_a = (0, useCurrentOrganization_1.useCurrentOrganization)()) !== null && _a !== void 0 ? _a : defaultData;
    if (!instanceUrl) {
        return '';
    }
    const deconstructed = new sonar_ts_lib_1.DeconstructedURL(instanceUrl);
    return isSandbox ? `${deconstructed.subdomain}.sandbox` : deconstructed.subdomain;
}
