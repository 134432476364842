"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
const React = __importStar(require("react"));
const general_1 = require("@ui/general");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const antd_1 = require("antd");
class ChangeRecords extends React.Component {
    constructor() {
        super(...arguments);
        this.renderChangeCard = (record) => {
            const { changeDetail, id } = record.change;
            return (React.createElement(antd_1.Card, { className: 'change', key: id },
                React.createElement(antd_1.Row, null, changeDetail && this.renderDetail(changeDetail)),
                this.renderCodeComparison(record.change)));
        };
        this.renderCodeComparison = (change) => {
            const { id, newValue, oldValue } = change;
            if (newValue || oldValue) {
                return (React.createElement(general_1.CodeComparison, { key: id, newCode: newValue !== null && newValue !== void 0 ? newValue : sonar_ts_constants_1.COPY_SPECIFIC.NONE_PARENTHETICAL, oldCode: oldValue !== null && oldValue !== void 0 ? oldValue : sonar_ts_constants_1.COPY_SPECIFIC.NONE_PARENTHETICAL }));
            }
            return false;
        };
        this.renderDetail = (changeDetail) => React.createElement("div", { className: 'diff' },
            React.createElement("span", { className: 'label' }, sonar_ts_constants_1.COPY_SPECIFIC.CHANGE_DETAIL),
            React.createElement("span", { className: 'value' }, changeDetail));
    }
    render() {
        const { record } = this.props;
        return React.createElement("div", { className: 'modifications' }, this.renderChangeCard(record));
    }
}
exports.default = ChangeRecords;
