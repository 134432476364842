"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useTagReference = useTagReference;
const selectors_1 = require("@lib/selectors");
const _hooks_1 = require("@hooks");
const selector = (0, selectors_1.createSonarSelector)((state) => state.core.tags.byIdMap);
function useTagReference(tagId) {
    var _a;
    const { data } = (0, _hooks_1.useSonarSelector)(selector);
    return (_a = data === null || data === void 0 ? void 0 : data[tagId]) !== null && _a !== void 0 ? _a : {};
}
