"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertUserToAmplitudeCustomerData = convertUserToAmplitudeCustomerData;
exports.convertDataToFullstoryData = convertDataToFullstoryData;
exports.convertUserToPendoVisitor = convertUserToPendoVisitor;
exports.convertCustomerToPendoAccount = convertCustomerToPendoAccount;
function convertUserToAmplitudeCustomerData(user, currentCustomer, orgCount, userCount) {
    const { email } = user;
    const { name, id } = currentCustomer;
    return {
        customer: `${name}-${id}`,
        email,
        orgCount,
        userCount
    };
}
function convertDataToFullstoryData(user, currentCustomer) {
    const { email, firstName, lastName, id: userId } = user;
    const { name, id } = currentCustomer;
    return {
        customerOrg: name,
        customerOrgId: String(id),
        email,
        name: `${firstName} ${lastName}`,
        uid: userId.toString()
    };
}
function convertUserToPendoVisitor(user, shard) {
    const { email, firstName, lastName, id } = user;
    return {
        email,
        // eslint-disable-next-line camelcase
        full_name: `${firstName} ${lastName}`,
        id: shard ? `${shard}:${id}` : id.toString()
    };
}
function convertCustomerToPendoAccount(customer, shard) {
    const { id, name, salesforceAccountId } = customer;
    return {
        id: shard ? `${shard}:${id}` : String(id),
        name,
        salesforceAccountId: salesforceAccountId !== null && salesforceAccountId !== void 0 ? salesforceAccountId : ''
    };
}
