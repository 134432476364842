"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InviteModal = void 0;
require("./InviteModal.scss");
const notifications_1 = require("@ui/notifications");
const antd_1 = require("antd");
const selectors_1 = require("@lib/selectors");
const sonar_security_1 = require("@sonar-software/sonar-security");
const modals_1 = require("@ui/modals");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const text_1 = require("@ui/text");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const inviteUserByIdSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.security.invitations.resend.post);
const InviteModal = ({ afterClose, inviteLink, onCancel, user, open }) => {
    const isResendRequesting = (0, _hooks_1.useSonarSelector)(inviteUserByIdSelectors.isRequested);
    const isResendSuccessful = (0, _hooks_1.useSonarSelector)(inviteUserByIdSelectors.isReceived);
    const requestResendInvite = (0, _hooks_1.useAction)(sonar_security_1.securityActions.requestPostSecurityInvitationsResend);
    const handleResendEmail = (0, react_1.useCallback)(() => {
        requestResendInvite({
            userId: user.id
        });
    }, [requestResendInvite, user.id]);
    (0, react_1.useEffect)(() => {
        if (isResendSuccessful) {
            onCancel();
        }
    }, [isResendSuccessful, onCancel]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(modals_1.SonarModal, { afterClose: afterClose, centered: true, className: 'invite-modal', footer: react_1.default.createElement(antd_1.Button, { key: 'submit', loading: isResendRequesting, onClick: handleResendEmail, type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.RESEND_EMAIL), onCancel: onCancel, open: open, title: sonar_ts_constants_1.COPY_SPECIFIC.USER_INVITATION_OPTIONS },
            react_1.default.createElement(text_1.SonarText, { className: 'sub-text' }, sonar_ts_constants_1.COPY_GENERIC.INVITE_SUBTEXT(user.email)),
            react_1.default.createElement(text_1.CopyText, { className: 'invite-link', text: inviteLink })),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_POST_USER_INVITE, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_POST_USER_INVITE, received: sonar_ts_constants_1.NOTIFICATIONS_COPY.SUCCESS_POST_USER_INVITE, resetRawAction: sonar_security_1.securityActions.resetPostSecurityInvitationsResend, stateSelector: inviteUserByIdSelectors.state })));
};
exports.InviteModal = InviteModal;
