"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQueryParams = useQueryParams;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const usePrevious_1 = require("./usePrevious");
const react_1 = require("react");
function useQueryParams(options) {
    const optionsRef = (0, react_1.useRef)(options);
    optionsRef.current = options;
    const [queryParams, setParams] = (0, react_1.useState)((options === null || options === void 0 ? void 0 : options.defaultParams) || {});
    const [newQueryParams, setNewQueryParams] = (0, react_1.useState)({});
    const previousQueryParams = (0, usePrevious_1.usePrevious)(queryParams, (options === null || options === void 0 ? void 0 : options.defaultParams) || {});
    (0, react_1.useEffect)(() => {
        var _a;
        if (((_a = optionsRef.current) === null || _a === void 0 ? void 0 : _a.onUpdate) && queryParams !== previousQueryParams) {
            optionsRef.current.onUpdate(newQueryParams, previousQueryParams, queryParams);
        }
    }, [newQueryParams, queryParams, previousQueryParams]);
    const updateParams = (0, react_1.useCallback)((paramsUpdate) => {
        const newParams = (0, sonar_ts_lib_1.isFunction)(paramsUpdate) ? paramsUpdate(queryParams) : paramsUpdate;
        const { fallbackParams, transform } = optionsRef.current || {};
        setNewQueryParams(newParams);
        setParams((prev) => {
            const combined = Object.assign(Object.assign(Object.assign({}, prev), fallbackParams), newParams);
            return Object.assign(Object.assign({}, combined), transform ? transform(newParams, queryParams, combined) : {});
        });
    }, [queryParams]);
    return {
        queryParams,
        updateParams
    };
}
