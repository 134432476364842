"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePeriscope = updatePeriscope;
exports.updatePeriscopeInfo = updatePeriscopeInfo;
const action_types_1 = require("./action_types");
function updatePeriscope(updatePeriscopeSync) {
    return {
        data: updatePeriscopeSync,
        type: action_types_1.ACTION_TYPES.UPDATE_PERISCOPE
    };
}
function updatePeriscopeInfo(request) {
    return {
        data: request,
        type: action_types_1.ACTION_TYPES.UPDATE_PERISCOPE_INFO
    };
}
