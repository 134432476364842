"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AsyncStateNotification = void 0;
const xuder_1 = require("@sonar-software/xuder");
const _hooks_1 = require("@hooks");
function getNotificationOptions(options) {
    if (typeof options === 'string') {
        return {
            description: options
        };
    }
    return options;
}
function statusToAlertType(status) {
    const stateStatus = status;
    switch (true) {
        case (0, xuder_1.isRequested)(stateStatus):
            return 'info';
        case (0, xuder_1.isErrored)(stateStatus):
        case (0, xuder_1.isFailed)(stateStatus):
            return 'error';
        case (0, xuder_1.isReceived)(stateStatus):
            return 'success';
        default:
            return 'error';
    }
}
const AsyncStateNotification = ({ byStatusCode, errored, failed, received, requested, resetRawAction, resetAction, stateSelector }) => {
    const { openNotification } = (0, _hooks_1.useAppNotifications)();
    (0, _hooks_1.useAsyncProcessing)({
        onReset: resetAction,
        onStatus: (status, options) => openNotification(statusToAlertType(status), options.description, options.options),
        onStatusCode: (status, options) => openNotification(statusToAlertType(status), options.description, options.options),
        resetAction: resetRawAction,
        stateSelector,
        statusCodeOptions: byStatusCode === null || byStatusCode === void 0 ? void 0 : byStatusCode.reduce((codes, entry) => {
            codes[entry.statusCode] = entry.options;
            return codes;
        }, {}),
        statusOptions: {
            errored: getNotificationOptions(errored),
            failed: getNotificationOptions(failed),
            received: getNotificationOptions(received),
            requested: getNotificationOptions(requested)
        },
        watchAllCalls: true
    });
    return null;
};
exports.AsyncStateNotification = AsyncStateNotification;
