"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CommentSection = void 0;
require("./CommentSection.scss");
const CommentList_1 = require("./CommentList");
const icons_1 = require("@ant-design/icons");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const selectors_1 = require("@lib/selectors");
const CreateCommentNotification_1 = require("./CreateCommentNotification");
const DeleteCommentNotification_1 = require("./DeleteCommentNotification");
const general_1 = require("../general");
const OffsetPaginator_1 = require("@ui/OffsetPaginator");
const UpdateCommentNotification_1 = require("./UpdateCommentNotification");
const sonar_core_1 = require("@sonar-software/sonar-core");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const commentsSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.initiatives.comments.get);
const CommentSection = ({ initiativeId }) => {
    var _a;
    const totalCount = (_a = (0, _hooks_1.useSonarSelector)(commentsSelectors.data)) === null || _a === void 0 ? void 0 : _a.totalCount;
    const get = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreInitiativesComments);
    const handleGet = (0, react_1.useCallback)((args) => get(Object.assign(Object.assign({}, args), { initiativeId })), [get, initiativeId]);
    const renderComments = (0, react_1.useCallback)((data) => react_1.default.createElement(CommentList_1.CommentList, { data: data, initiativeId: initiativeId }), [initiativeId]);
    return (react_1.default.createElement(general_1.InitiativeSection, { count: totalCount, icon: react_1.default.createElement(icons_1.CommentOutlined, null), id: 'initiative-comments-section', title: sonar_ts_constants_1.COPY_SPECIFIC.COMMENTS },
        react_1.default.createElement(OffsetPaginator_1.OffsetPaginator, { render: renderComments, request: handleGet, selector: commentsSelectors.data }),
        react_1.default.createElement(CreateCommentNotification_1.CreateCommentNotification, null),
        react_1.default.createElement(DeleteCommentNotification_1.DeleteCommentNotification, null),
        react_1.default.createElement(UpdateCommentNotification_1.UpdateCommentNotification, null)));
};
exports.CommentSection = CommentSection;
