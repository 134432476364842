"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSalesforceSync = useSalesforceSync;
const selectors_1 = require("@lib/selectors");
const useAction_1 = require("../useAction");
const useImportStatus_1 = require("./useImportStatus");
const useInterval_1 = require("../useInterval");
const useLatestSalesforceSync_1 = require("./useLatestSalesforceSync");
const useOnboardingProgress_1 = require("../useOnboardingProgress");
const useSonarSelector_1 = require("../useSonarSelector");
const sonar_imports_1 = require("@sonar-software/sonar-imports");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const react_1 = require("react");
function getCurrentStatus(current, latest) {
    if (!current) {
        return latest === null || latest === void 0 ? void 0 : latest.status;
    }
    if (!latest) {
        return current === null || current === void 0 ? void 0 : current.status;
    }
    if (latest.id >= current.id) {
        return latest.status;
    }
    return current.status;
}
const salesforceImportSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.imports.salesforce.start.post);
function useSalesforceSync(enableRefresh) {
    var _a;
    const startResult = (0, useSonarSelector_1.useSonarSelector)(salesforceImportSelectors.data);
    const isRequested = (0, useSonarSelector_1.useSonarSelector)(salesforceImportSelectors.isRequested);
    const latestSync = (0, useLatestSalesforceSync_1.useLatestSalesforceSync)();
    const { updateProgress, progress } = (0, useOnboardingProgress_1.useOnboardingProgress)();
    const { status, refresh } = (0, useImportStatus_1.useImportStatus)((_a = startResult === null || startResult === void 0 ? void 0 : startResult.id) !== null && _a !== void 0 ? _a : progress === null || progress === void 0 ? void 0 : progress.currentlyRunningSfdcImportId, 1);
    const startSync = (0, useAction_1.useAction)(sonar_imports_1.importsActions.requestPostImportsSalesforceStart);
    const resetSync = (0, useAction_1.useAction)(sonar_imports_1.importsActions.resetPostImportsSalesforceStart);
    (0, useInterval_1.useInterval)(() => {
        if (!enableRefresh || !status || (0, sonar_ts_lib_1.isSyncFailed)(status === null || status === void 0 ? void 0 : status.status) || (0, sonar_ts_lib_1.isSyncCompleted)(status === null || status === void 0 ? void 0 : status.status)) {
            return;
        }
        refresh();
    }, 5000);
    (0, react_1.useEffect)(() => {
        if (!progress || !(0, sonar_ts_lib_1.isSyncWaiting)(status === null || status === void 0 ? void 0 : status.status) || !(0, sonar_ts_lib_1.isSyncInProgress)(status === null || status === void 0 ? void 0 : status.status)) {
            return;
        }
        const runningImportId = (status === null || status === void 0 ? void 0 : status.id) || ((0, sonar_ts_lib_1.isSyncInProgress)(latestSync === null || latestSync === void 0 ? void 0 : latestSync.status) ? latestSync === null || latestSync === void 0 ? void 0 : latestSync.id : null);
        if (!progress.currentlyRunningSfdcImportId &&
            runningImportId &&
            runningImportId !== progress.latestSuccessfulSfdcImportId) {
            updateProgress({ currentlyRunningSfdcImportId: runningImportId });
        }
    }, [latestSync === null || latestSync === void 0 ? void 0 : latestSync.id, latestSync === null || latestSync === void 0 ? void 0 : latestSync.status, progress, status === null || status === void 0 ? void 0 : status.id, status === null || status === void 0 ? void 0 : status.status, updateProgress]);
    const isSyncing = (0, react_1.useMemo)(() => {
        const currentStatus = getCurrentStatus(status, latestSync);
        return (0, sonar_ts_lib_1.isSyncWaiting)(currentStatus) || (0, sonar_ts_lib_1.isSyncInProgress)(currentStatus);
    }, [latestSync, status]);
    const startSyncEmptyRequest = (0, react_1.useCallback)(() => {
        startSync({});
    }, [startSync]);
    return {
        importId: startResult === null || startResult === void 0 ? void 0 : startResult.id,
        isRequested,
        isSyncing,
        resetSync,
        startSync: startSyncEmptyRequest,
        status
    };
}
