"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AppUsageActivityCellEditor = void 0;
require("./AppDescriptionCellEditor.scss");
const app_form_1 = require("../app-form");
const EditBox_1 = require("@ui/EditBox");
const popups_1 = require("@lib/popups");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const AppUsageActivityCellEditor = ({ applicationId, activityPeriod }) => {
    const inputRef = (0, react_1.useRef)(null);
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const { request, reset } = (0, _hooks_1.useUpdatePulseApp)();
    const process = (0, _hooks_1.useAsyncProcessing)({
        resetAction: reset,
        stateSelector: _hooks_1.useUpdatePulseApp.selectors.state
    });
    const handleOnChange = (0, react_1.useCallback)((value) => {
        if (activityPeriod === value) {
            return;
        }
        process.watchNextCall();
        request({
            activityPeriod: value,
            applicationId
        });
    }, [applicationId, activityPeriod, process, request]);
    return (react_1.default.createElement(EditBox_1.EditBox, { className: 'app-group-cell-editor', isReadOnly: isReadOnly, isSpreadsheet: true, selectionRef: inputRef, state: process.state },
        react_1.default.createElement(app_form_1.AppActivityPeriodSelect, { defaultValue: activityPeriod, getPopupContainer: popups_1.getClosestTableBody, key: activityPeriod, onChange: handleOnChange, ref: inputRef })));
};
exports.AppUsageActivityCellEditor = AppUsageActivityCellEditor;
