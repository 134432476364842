"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitiativeTitleBar = void 0;
require("./TitleBar.scss");
const ArchiveButton_1 = require("./ArchiveButton");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const icons_1 = require("@ant-design/icons");
const text_1 = require("@ui/text");
const react_if_1 = require("react-if");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const InitiativeTitleBar = ({ initiativeId, title }) => {
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const [isEditing, setIsEditing] = (0, react_1.useState)(false);
    const patchInitiative = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreInitiativesById);
    const handleCancel = (0, react_1.useCallback)(() => {
        setIsEditing(false);
    }, []);
    const handleEditClick = (0, react_1.useCallback)(() => {
        setIsEditing(true);
    }, []);
    const handleChange = (0, react_1.useCallback)((updatedTitle) => {
        if (updatedTitle !== title) {
            patchInitiative({
                initiativeId: initiativeId,
                patchInitiativeDescription: {
                    title: updatedTitle
                }
            });
        }
        setIsEditing(false);
    }, [initiativeId, patchInitiative, title]);
    return (react_1.default.createElement(antd_1.Row, { align: 'middle', className: 'initiative-title-bar', justify: 'space-between', wrap: false },
        react_1.default.createElement(text_1.SonarText, { className: 'title h1', editable: {
                editing: isEditing,
                icon: react_1.default.createElement("div", null),
                maxLength: 60,
                onCancel: handleCancel,
                onChange: handleChange,
                tooltip: false
            }, ellipsis: true }, title),
        react_1.default.createElement(react_if_1.When, { condition: !isEditing && !isReadOnly },
            react_1.default.createElement("div", { className: 'actions' },
                react_1.default.createElement(antd_1.Space, null,
                    react_1.default.createElement(ArchiveButton_1.ArchiveButton, { initiativeId: initiativeId }),
                    react_1.default.createElement(antd_1.Button, { icon: react_1.default.createElement(icons_1.EditOutlined, null), id: 'initiative-rename-button', onClick: handleEditClick }, sonar_ts_constants_1.COPY_SPECIFIC.RENAME))))));
};
exports.InitiativeTitleBar = InitiativeTitleBar;
