"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PageLayoutFilter = void 0;
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_core_1 = require("@sonar-software/sonar-core");
const FilterTag_1 = require("./FilterTag");
const popups_1 = require("@lib/popups");
const lib_1 = require("@dictionary/@arch/lib");
const filters_1 = require("@periscopes/general/filters");
const text_1 = require("@ui/text");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const PageLayoutFilter = ({ displayAsTag, id, format, label, onChange, sonarObjectId, value }) => {
    var _a, _b;
    const loadPageLayouts = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreSonarObjectsPageLayouts);
    const pageLayoutNames = (_b = (_a = (0, _hooks_1.useSonarObjectReference)(sonarObjectId !== null && sonarObjectId !== void 0 ? sonarObjectId : 0)) === null || _a === void 0 ? void 0 : _a.salesforce) === null || _b === void 0 ? void 0 : _b.pageLayouts;
    (0, _hooks_1.useOnMount)(() => {
        if (sonarObjectId) {
            loadPageLayouts({ sonarObjectId });
        }
    });
    const options = (0, react_1.useMemo)(() => {
        const pageLayoutMap = pageLayoutNames === null || pageLayoutNames === void 0 ? void 0 : pageLayoutNames.map((pageLayout) => {
            if (!pageLayout || pageLayout.name === '') {
                return {
                    label: sonar_ts_constants_1.COPY_SPECIFIC.NONE,
                    value: -1
                };
            }
            return {
                label: pageLayout.name,
                value: pageLayout.id
            };
        });
        return pageLayoutMap !== null && pageLayoutMap !== void 0 ? pageLayoutMap : [];
    }, [pageLayoutNames]);
    if (displayAsTag) {
        return (react_1.default.createElement(FilterTag_1.FilterTag, { clearValue: [], format: format, id: id, label: (0, sonar_ts_lib_1.capitalize)(label), update: onChange, value: options
                .filter((option) => value === null || value === void 0 ? void 0 : value.includes(option.value))
                .map((option) => option.label)
                .join(', ') }));
    }
    return (react_1.default.createElement("div", { className: 'filter-container' },
        react_1.default.createElement(text_1.SonarText, null, (0, sonar_ts_lib_1.capitalize)(label)),
        react_1.default.createElement(filters_1.OptionFilter, { className: 'filter', getPopupContainer: popups_1.getDirectParent, id: (0, lib_1.getFilterId)(id), loading: !options, maxTagCount: 'responsive', mode: 'multiple', onChange: (pageLayoutIds) => {
                onChange({
                    [id]: {
                        format,
                        value: pageLayoutIds
                    }
                });
            }, options: options !== null && options !== void 0 ? options : [], placeholder: sonar_ts_constants_1.PLACEHOLDERS_COPY.FILTER_BY(label), showArrow: true, value: value })));
};
exports.PageLayoutFilter = PageLayoutFilter;
