"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.shouldItemShowParent = shouldItemShowParent;
exports.shouldItemShowApiName = shouldItemShowApiName;
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
function shouldItemShowParent(type) {
    return type === sonar_ts_types_1.SonarObjectType.SalesforceField || type === sonar_ts_types_1.SonarObjectType.PardotField;
}
function shouldItemShowApiName(type) {
    return type === sonar_ts_types_1.SonarObjectType.SalesforceEntity;
}
