"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updatePeriscopeInfo = exports.updatePeriscope = exports.resetPeriscope = exports.removePeriscope = void 0;
var actions_1 = require("./remove/actions");
Object.defineProperty(exports, "removePeriscope", { enumerable: true, get: function () { return actions_1.removePeriscope; } });
var actions_2 = require("./read/actions");
Object.defineProperty(exports, "resetPeriscope", { enumerable: true, get: function () { return actions_2.resetPeriscope; } });
var actions_3 = require("./update/actions");
Object.defineProperty(exports, "updatePeriscope", { enumerable: true, get: function () { return actions_3.updatePeriscope; } });
Object.defineProperty(exports, "updatePeriscopeInfo", { enumerable: true, get: function () { return actions_3.updatePeriscopeInfo; } });
