"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useCurrentInitiative = useCurrentInitiative;
const selectors_1 = require("@lib/selectors");
const useSonarSelector_1 = require("../useSonarSelector");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.initiatives.byId.get);
/** Subscribes to the currently loaded {@link Initiative}, if any. */
function useCurrentInitiative() {
    return (0, useSonarSelector_1.useSonarSelector)(selectors.data);
}
useCurrentInitiative.selectors = selectors;
