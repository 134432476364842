"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getMaxRule = exports.getRequiredRule = void 0;
exports.validateText = validateText;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const getRequiredRule = (fieldName) => ({
    message: sonar_ts_constants_1.COPY_SPECIFIC.FIELD_REQUIRED(fieldName),
    required: true
});
exports.getRequiredRule = getRequiredRule;
const getMaxRule = (fieldName, max) => ({
    max,
    message: sonar_ts_constants_1.COPY_SPECIFIC.FIELD_CANNOT_EXCEED(fieldName, max)
});
exports.getMaxRule = getMaxRule;
function validateText(currentValue, validationRules) {
    for (const rule of validationRules !== null && validationRules !== void 0 ? validationRules : []) {
        if (rule.required && !currentValue) {
            return rule.message;
        }
        if (!rule.required && !currentValue) {
            return undefined;
        }
        if (rule.min && currentValue.length < rule.min) {
            return rule.message;
        }
        if (rule.max && currentValue.length > rule.max) {
            return rule.message;
        }
        if (rule.pattern && !rule.pattern.test(currentValue)) {
            return rule.message;
        }
    }
    return undefined;
}
