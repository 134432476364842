"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SortByFilter = void 0;
require("./SortByFilter.scss");
const popups_1 = require("@lib/popups");
const general_1 = require("@ui/general");
const react_if_1 = require("react-if");
const icons_1 = require("@ant-design/icons");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
exports.SortByFilter = (0, react_1.memo)(({ defaultSortOption, onChange, queryParams, options, sortIcon }) => {
    var _a, _b, _c;
    const sortBy = (_a = queryParams.sortBy) !== null && _a !== void 0 ? _a : defaultSortOption.key;
    const sortDir = (_b = queryParams.sortDirection) !== null && _b !== void 0 ? _b : defaultSortOption.direction;
    const currentSortBy = (_c = options.find((option) => option.key === sortBy)) === null || _c === void 0 ? void 0 : _c.label;
    const sortText = `Sorted by: ${currentSortBy}`;
    const updateFilter = (0, react_1.useCallback)((sort) => {
        if (sort.key !== sortBy || sort.direction !== sortDir) {
            onChange({
                sortBy: sort.key,
                sortDirection: sort.direction
            });
        }
    }, [onChange, sortBy, sortDir]);
    const handleMenuClick = (0, react_1.useCallback)((e) => {
        var _a;
        const targetOption = (_a = options.find((option) => option.key === e.key)) !== null && _a !== void 0 ? _a : options[0];
        updateFilter(targetOption);
    }, [options, updateFilter]);
    const sortOutlined = sortDir === sonar_ts_types_1.SonarV3SortDirection.Ascending ?
        react_1.default.createElement(icons_1.SortAscendingOutlined, { onClick: updateFilter.bind(this, {
                direction: sonar_ts_types_1.SonarV3SortDirection.Descending,
                key: sortBy
            }) }) :
        react_1.default.createElement(icons_1.SortDescendingOutlined, { onClick: updateFilter.bind(this, {
                direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
                key: sortBy
            }) });
    return (react_1.default.createElement("div", { className: 'sort-by-filter' },
        react_1.default.createElement(antd_1.Dropdown, { disabled: options.length <= 1, getPopupContainer: popups_1.getDirectParent, menu: {
                items: options.map(({ disabled, disabledTooltip, key, label }) => ({
                    className: 'sort-by-filter-entry',
                    disabled,
                    key,
                    label: react_1.default.createElement(antd_1.Tooltip, { open: disabled && disabledTooltip ? undefined : false, title: react_1.default.createElement(general_1.SanitizedHtml, { as: 'span', html: disabledTooltip !== null && disabledTooltip !== void 0 ? disabledTooltip : '' }) }, label)
                })),
                onClick: handleMenuClick
            } },
            react_1.default.createElement("div", { className: 'periscope-sort-by-text' },
                sortText,
                options.length > 1 && react_1.default.createElement(icons_1.DownOutlined, null))),
        react_1.default.createElement(react_if_1.When, { condition: sortIcon },
            react_1.default.createElement("div", { className: 'sort-icon' }, sortOutlined))));
});
