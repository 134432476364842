"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useInvite = useInvite;
const selectors_1 = require("@lib/selectors");
const useAction_1 = require("../useAction");
const useSonarSelector_1 = require("../useSonarSelector");
const sonar_security_1 = require("@sonar-software/sonar-security");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.security.invitations.send.post);
/**
 * Subscribes to the user invitation processing. Provides actions to request and reset, flags for status, and
 * access to the last processing result, if any.
 * @returns A {@link UseAddToInitiativeResult} with processing flags, actions, and potential result.
 */
function useInvite() {
    const idle = (0, useSonarSelector_1.useSonarSelector)(selectors.isIdle);
    const finished = (0, useSonarSelector_1.useSonarSelector)(selectors.isReceived);
    const loading = (0, useSonarSelector_1.useSonarSelector)(selectors.isRequested);
    const result = (0, useSonarSelector_1.useSonarSelector)(selectors.data);
    const invite = (0, useAction_1.useAction)(sonar_security_1.securityActions.requestPostSecurityInvitationsSend);
    const reset = (0, useAction_1.useAction)(sonar_security_1.securityActions.resetPostSecurityInvitationsSend);
    return {
        finished,
        idle,
        invite,
        loading,
        reset,
        result
    };
}
useInvite.selectors = selectors;
