"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAmplitude = useAmplitude;
const useAction_1 = require("./useAction");
const react_1 = require("react");
const sonar_analytics_1 = require("@sonar-software/sonar-analytics");
function useAmplitude() {
    const logEventAction = (0, useAction_1.useAction)(sonar_analytics_1.amplitudeActions.requestAmplitudeEvent);
    return (0, react_1.useCallback)((event, data) => {
        if (typeof event === 'string') {
            logEventAction({
                data,
                id: event
            });
        }
        else {
            logEventAction(event);
        }
    }, [logEventAction]);
}
