"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.HideAppButton = void 0;
const antd_1 = require("antd");
const modals_1 = require("@ui/modals");
const sonar_core_1 = require("@sonar-software/sonar-core");
const popups_1 = require("@lib/popups");
const events_1 = require("@lib/events");
const react_router_dom_1 = require("react-router-dom");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const HideAppButton = ({ applicationId }) => {
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const navigate = (0, react_router_dom_1.useNavigate)();
    const hideApplication = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCorePulseApplications);
    const handleHide = (0, react_1.useCallback)(() => {
        hideApplication({ applicationId });
        navigate(sonar_ts_constants_1.FULL_ROUTES.PULSE);
    }, [applicationId, hideApplication, navigate]);
    if (isReadOnly) {
        return null;
    }
    return (react_1.default.createElement(modals_1.ConfirmDelete, { className: 'confirm-hide', confirmButtonProps: {
            value: sonar_ts_constants_1.COPY_SPECIFIC.HIDE
        }, getPopupContainer: popups_1.getClosestCardHeader, onConfirm: handleHide, title: sonar_ts_constants_1.MESSAGES_COPY.ARE_YOU_SURE_INTEGRATION_APP },
        react_1.default.createElement(antd_1.Button, { onClick: events_1.haltClickEventPropagation }, sonar_ts_constants_1.COPY_SPECIFIC.HIDE)));
};
exports.HideAppButton = HideAppButton;
