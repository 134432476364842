"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useUpdateOrganization = useUpdateOrganization;
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const useAction_1 = require("../useAction");
const useAsyncState_1 = require("../useAsyncState");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.organizations.byId.patch);
function useUpdateOrganization() {
    const { isErrored, isFailed, isReceived } = (0, useAsyncState_1.useAsyncState)(selectors);
    const request = (0, useAction_1.useAction)(sonar_core_1.coreActions.requestPatchCoreOrganizationsById);
    const reset = (0, useAction_1.useAction)(sonar_core_1.coreActions.resetPatchCoreOrganizationsById);
    return {
        isErrored,
        isFailed,
        isSuccessful: isReceived,
        request,
        reset
    };
}
useUpdateOrganization.selectors = selectors;
