"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ForgotPasswordView = void 0;
const general_1 = require("@ui/general");
const selectors_1 = require("@lib/selectors");
const form_items_1 = require("@ui/form-items");
const general_2 = require("../general");
const sonar_security_1 = require("@sonar-software/sonar-security");
const text_1 = require("@ui/text");
const react_router_dom_1 = require("react-router-dom");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const forgotPasswordSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.security.authentication.forgotPassword.post);
const ForgotPasswordView = () => {
    const navigate = (0, react_router_dom_1.useNavigate)();
    const requestForgotPassword = (0, _hooks_1.useAction)(sonar_security_1.securityActions.requestPostSecurityAuthenticationForgotPassword);
    const resetForgotPassword = (0, _hooks_1.useAction)(sonar_security_1.securityActions.resetPostSecurityAuthenticationForgotPassword);
    const hasError = (0, _hooks_1.useSonarSelector)(forgotPasswordSelectors.isErrored);
    const hasFailed = (0, _hooks_1.useSonarSelector)(forgotPasswordSelectors.isFailed);
    const hasOpenRequest = (0, _hooks_1.useSonarSelector)(forgotPasswordSelectors.isRequested);
    const hasSuccess = (0, _hooks_1.useSonarSelector)(forgotPasswordSelectors.isReceived);
    const handleOnBackToLoginClick = (0, react_1.useCallback)(() => {
        navigate(sonar_ts_constants_1.FULL_ROUTES.LOGIN);
    }, [navigate]);
    const handleOnFinish = (0, react_1.useCallback)((values) => {
        var _a;
        requestForgotPassword({ email: (_a = values.email) === null || _a === void 0 ? void 0 : _a.trim() });
    }, [requestForgotPassword]);
    const handleOnValuesChange = () => {
        if (hasError || hasFailed || hasSuccess) {
            resetForgotPassword();
        }
    };
    return (react_1.default.createElement(general_2.GatewayCard, { id: 'forgot-password', title: sonar_ts_constants_1.FORGOT_PASSWORD_COPY.FORGOT_PASSWORD_HEADER },
        react_1.default.createElement(antd_1.Form, { onFinish: handleOnFinish, onValuesChange: handleOnValuesChange },
            react_1.default.createElement(text_1.SonarText, { className: 'sub-text' }, sonar_ts_constants_1.FORGOT_PASSWORD_COPY.FORGOT_PASSWORD_SUBTEXT),
            react_1.default.createElement(general_1.AsyncStateAlert, { errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_FORGOT_PASSWORD, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_FORGOT_PASSWORD, received: sonar_ts_constants_1.NOTIFICATIONS_COPY.SUCCESS_FORGOT_PASSWORD, stateSelector: forgotPasswordSelectors.state }),
            react_1.default.createElement(form_items_1.Email, { disabled: hasOpenRequest, id: 'forgot-password-email', size: 'large' }),
            react_1.default.createElement(antd_1.Row, { justify: 'space-between' },
                react_1.default.createElement(antd_1.Button, { disabled: hasOpenRequest, id: 'forgot-password-return-button', onClick: handleOnBackToLoginClick, size: 'large' }, sonar_ts_constants_1.COPY_SPECIFIC.BACK_TO_LOGIN),
                react_1.default.createElement(antd_1.Button, { htmlType: 'submit', id: 'forgot-password-submit-button', loading: hasOpenRequest, size: 'large', type: 'primary' }, sonar_ts_constants_1.COPY_SPECIFIC.SEND_INSTRUCTIONS)))));
};
exports.ForgotPasswordView = ForgotPasswordView;
