"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.UserChangesChart = void 0;
const css = __importStar(require("css/exports.module.scss"));
require("./UserChangesChart.scss");
const selectors_1 = require("./@arch/selectors");
const SalesforceUser_1 = require("@ui/SalesforceUser");
const SonarUser_1 = require("@ui/SonarUser");
const plots_1 = require("@ant-design/plots");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const chartDataPadding = (length) => [...Array(Math.max(6 - length, 0)).keys()].map((i) => ({
    changeCount: 0,
    salesforceUsername: `placeholder-username-${i}`
}));
const tooltipCustomContent = (_title, item) => {
    var _a, _b, _c;
    return (((_a = item[0]) === null || _a === void 0 ? void 0 : _a.data.changeCount) > 0 ?
        `<div class='chart-tooltip'>
			<div class='h3'>${(_b = item[0]) === null || _b === void 0 ? void 0 : _b.data.salesforceName}</div>
			<div>${(_c = item[0]) === null || _c === void 0 ? void 0 : _c.data.changeCount} changes</div>
		</div>` : null);
};
const chartConfig = {
    autoFit: true,
    columnWidthRatio: 0.9,
    data: [],
    height: 120,
    padding: 0,
    xAxis: {
        label: {
            formatter: () => null
        },
        tickLine: { length: 0 }
    },
    xField: 'salesforceUsername',
    yAxis: {
        label: {
            formatter: () => null
        }
    },
    yField: 'changeCount'
};
const UserChangesChart = ({ modifiedBy = [], onChange }) => {
    const users = (0, _hooks_1.useUsers)();
    const userChanges = (0, _hooks_1.useSonarSelector)(selectors_1.getTimelineUserChangesSelector.data);
    const [selectedUser, setSelectedUser] = (0, react_1.useState)('');
    (0, react_1.useEffect)(() => {
        if (selectedUser) {
            setSelectedUser('');
            const usernames = Array.isArray(modifiedBy) ? modifiedBy : [modifiedBy];
            const modifiedByUsernames = usernames.includes(selectedUser) ?
                usernames.filter((username) => username !== selectedUser) :
                [...usernames, selectedUser];
            onChange({ modifiedByUsernames });
        }
    }, [modifiedBy, onChange, selectedUser]);
    const data = (0, react_1.useMemo)(() => {
        var _a;
        return [
            ...userChanges !== null && userChanges !== void 0 ? userChanges : [],
            ...chartDataPadding((_a = userChanges === null || userChanges === void 0 ? void 0 : userChanges.length) !== null && _a !== void 0 ? _a : 0)
        ];
    }, [userChanges]);
    const userMap = (0, react_1.useMemo)(() => users.reduce((map, user) => (Object.assign(Object.assign({}, map), { [user.email]: user })), {}), [users]);
    const getColor = (0, react_1.useCallback)(({ salesforceUsername }) => (!modifiedBy.length || modifiedBy.includes(salesforceUsername) ? css.addition : css.unmodified), [modifiedBy]);
    const onChartReady = (0, react_1.useCallback)((plot) => {
        plot.on('plot:click', (e) => {
            var _a, _b;
            const { changeCount, salesforceUsername } = (_b = (_a = e.view.controllers[2].items) === null || _a === void 0 ? void 0 : _a[0].data) !== null && _b !== void 0 ? _b : {};
            if (changeCount) {
                setSelectedUser(salesforceUsername !== null && salesforceUsername !== void 0 ? salesforceUsername : '');
            }
        });
    }, []);
    if (!(userChanges === null || userChanges === void 0 ? void 0 : userChanges.length)) {
        return null;
    }
    const chartWidth = data.length * 28;
    return (react_1.default.createElement("div", { className: 'user-changes-chart', style: {
            maxWidth: chartWidth,
            minWidth: chartWidth
        } },
        react_1.default.createElement(plots_1.Column, Object.assign({ className: 'chart' }, chartConfig, { color: getColor, data: data, onReady: onChartReady, tooltip: { customContent: tooltipCustomContent } })),
        react_1.default.createElement("div", { className: 'user-avatars' }, userChanges.map(({ salesforceName, salesforceUsername }) => {
            var _a;
            const userId = (_a = userMap[salesforceUsername]) === null || _a === void 0 ? void 0 : _a.id;
            switch (true) {
                case Number.isInteger(userId):
                    return (react_1.default.createElement(SonarUser_1.SonarUser, { key: salesforceUsername, shape: 'square', showTooltip: true, userId: userId, withFullName: false }));
                case Boolean(salesforceUsername):
                    return (react_1.default.createElement(SalesforceUser_1.SalesforceUser, { key: salesforceUsername, name: salesforceName, shape: 'square', showTooltip: true, username: salesforceUsername, withFullName: false }));
                default:
                    return null;
            }
        }))));
};
exports.UserChangesChart = UserChangesChart;
