"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppUsers = void 0;
const hooks_1 = require("@lib/hooks");
const sonar_core_1 = require("@sonar-software/sonar-core");
const empty = [];
/**
 * Subscribes to and returns the collection of Pulse Application Users. Will load related data upon mount if data is not
 * available or forced via `refresh`.
 * @param refresh Determines if the component should force load the latest data upon mount. Defaults to `false`.
 * @returns Array of {@link PulseAppUser}.
 */
exports.useAppUsers = (0, hooks_1.createAsyncStateWithPreloadHook)({
    loadAction: sonar_core_1.coreActions.requestGetCorePulseAppUsers,
    selector: (state) => state.core.pulse.appUsers.get,
    useTransform: (result) => (Object.assign(Object.assign({}, result), { data: result.data || empty }))
});
