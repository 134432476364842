"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useClipboard = useClipboard;
const react_1 = require("react");
const fullClipboard = window.navigator.clipboard;
const write = (items) => fullClipboard.write(items.map((item) => new ClipboardItem({ [item.mimeType]: item.data })));
const writeText = (data) => fullClipboard.writeText(data);
function useClipboard() {
    return (0, react_1.useMemo)(() => (Object.assign(Object.assign({}, fullClipboard), { write: write, writeText: writeText })), []);
}
