"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FormulaFieldSelectionTable = void 0;
const _1 = require("./");
const __1 = require("../");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const tables_1 = require("@ui/tables");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const FormulaFieldSelectionTable = ({ parentIndex, resourceIdsUri, uri }) => {
    const { resource, loading } = (0, _hooks_1.useApiResource)(uri);
    const selectionData = (0, _hooks_1.useSelectionTable)({
        getRowKey: __1.getRowKey,
        resourceIdsUri,
        results: resource,
        selectionType: sonar_ts_types_1.SonarObjectType.SalesforceField
    });
    const renderRow = (0, react_1.useCallback)((item) => react_1.default.createElement(_1.FormulaFieldRow, { formulaField: item, key: item.sonarObjectId, parentIndex: parentIndex }), [parentIndex]);
    return (react_1.default.createElement(tables_1.SelectionTable, { dataSource: resource, loading: loading, renderRow: renderRow, rowKey: __1.getRowKey, selectionData: selectionData, selectionType: sonar_ts_constants_1.PERISCOPE_TAB_TYPES.FORMULA_FIELDS }));
};
exports.FormulaFieldSelectionTable = FormulaFieldSelectionTable;
