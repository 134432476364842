// extracted by mini-css-extract-plugin
var _1 = "#389e0d";
var _2 = "#fa8c16";
var _3 = "#cf1322";
var _4 = "#52c41a";
var _5 = "#08979c";
var _6 = "#434343";
var _7 = "#1890ff";
var _8 = "128";
var _9 = "16";
var _a = "2";
var _b = "24";
var _c = "32";
var _d = "4";
var _e = "64";
var _f = "8";
var _10 = "#bfbfbf";
var _11 = "#fa541c";
export { _1 as "addition", _2 as "badgeColor", _3 as "deletion", _4 as "lightGreen", _5 as "modification", _6 as "overviewStrokeColor", _7 as "read", _8 as "size128", _9 as "size16", _a as "size2", _b as "size24", _c as "size32", _d as "size4", _e as "size64", _f as "size8", _10 as "unmodified", _11 as "write" }
