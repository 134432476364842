"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.PeriscopeTagList = void 0;
const _1 = require(".");
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const PeriscopeTagList = ({ tagIds, parentIndex }) => {
    const { openPeriscope } = (0, _hooks_1.usePeriscopeActions)();
    const handleTagClick = (0, react_1.useCallback)((tag) => {
        const { name, id } = tag;
        openPeriscope({
            id,
            name,
            openedFromIndex: parentIndex,
            type: sonar_ts_types_1.SonarObjectType.SonarTag
        });
    }, [openPeriscope, parentIndex]);
    return (react_1.default.createElement(react_1.default.Fragment, null, tagIds.map((tagId) => react_1.default.createElement(_1.PeriscopeTag, { key: tagId, onTagClick: handleTagClick, tagId: tagId }))));
};
exports.PeriscopeTagList = PeriscopeTagList;
