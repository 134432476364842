"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FieldUpdateSearch = void 0;
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const filters_1 = require("../filters");
const FieldUpdateSearch = ({ onChange }) => {
    const handleChange = (0, react_1.useCallback)((params) => {
        onChange({ filters: params.query ? `DisplayName~like~"%${params.query}%"` : undefined });
    }, [onChange]);
    return (react_1.default.createElement(filters_1.SearchFilterContainer, null,
        react_1.default.createElement(antd_1.Col, { span: 12 },
            react_1.default.createElement(filters_1.SearchFilter, { onChange: handleChange }))));
};
exports.FieldUpdateSearch = FieldUpdateSearch;
