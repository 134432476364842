"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useAppNotifications = useAppNotifications;
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = require("react");
function useAppNotifications() {
    const { notification } = antd_1.App.useApp();
    const errorNotification = (0, react_1.useCallback)((description, options) => {
        notification.error(Object.assign({ description, message: sonar_ts_constants_1.COPY_GENERIC.ERROR }, options));
    }, [notification]);
    const failedNotification = (0, react_1.useCallback)((description, options) => {
        notification.warning(Object.assign({ description, message: sonar_ts_constants_1.COPY_GENERIC.ERROR }, options));
    }, [notification]);
    const infoNotification = (0, react_1.useCallback)((description, options) => {
        notification.info(Object.assign({ description, message: sonar_ts_constants_1.COPY_GENERIC.INFO }, options));
    }, [notification]);
    const successNotification = (0, react_1.useCallback)((description, options) => {
        notification.success(Object.assign({ description, message: sonar_ts_constants_1.COPY_GENERIC.SUCCESS }, options));
    }, [notification]);
    const openNotification = (0, react_1.useCallback)((type, description, options) => {
        switch (type) {
            case 'error':
                errorNotification(description, options);
                break;
            case 'info':
                infoNotification(description, options);
                break;
            case 'success':
                successNotification(description, options);
                break;
            case 'warning':
                failedNotification(description, options);
                break;
            default:
                notification.open(Object.assign({ description, message: sonar_ts_constants_1.COPY_GENERIC.ERROR }, options));
        }
    }, [errorNotification, failedNotification, infoNotification, notification, successNotification]);
    return {
        errorNotification,
        failedNotification,
        infoNotification,
        openNotification,
        successNotification
    };
}
