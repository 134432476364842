"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.InitiativeMediaListItem = void 0;
require("./MediaList.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const text_1 = require("@ui/text");
const react_router_dom_1 = require("react-router-dom");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const sonar_core_1 = require("@sonar-software/sonar-core");
const icons_1 = require("@ant-design/icons");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const InitiativeMediaListItem = ({ listItem, setSelectedItem }) => {
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const isFile = Boolean(listItem.contentType);
    const params = (0, react_router_dom_1.useParams)();
    const initiativeId = Number(params.id);
    const deleteAction = (0, _hooks_1.useAction)(isFile ?
        sonar_core_1.coreActions.requestDeleteCoreInitiativesFilesById :
        sonar_core_1.coreActions.requestDeleteCoreInitiativesLinksById);
    const handleDeleteConfirm = () => deleteAction({
        id: listItem.id,
        initiativeId
    });
    const downloadRequest = (0, react_1.useCallback)(() => (0, sonar_core_1.getInitiativeFile)({
        fileId: listItem.id,
        initiativeId
    }), [initiativeId, listItem.id]);
    const download = (0, _hooks_1.useDownload)();
    const handleEditClick = () => setSelectedItem(listItem);
    const handleDownloadClick = (0, react_1.useCallback)(() => {
        download({
            errorMessage: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_DOWNLOAD_INITIATIVE_FILE,
            request: downloadRequest
        });
    }, [download, downloadRequest]);
    const renderFile = (file) => react_1.default.createElement("a", { onClick: handleDownloadClick }, file.name);
    const renderLink = (link) => react_1.default.createElement("a", { href: link.url, target: '_blank' }, link.title || (0, sonar_ts_lib_1.getDisplayUrl)(link.url));
    const editButton = react_1.default.createElement("div", { key: 1 },
        react_1.default.createElement(icons_1.EditOutlined, { className: 'edit-button', onClick: handleEditClick }));
    const deleteButton = react_1.default.createElement("div", { key: 2 },
        react_1.default.createElement(antd_1.Popconfirm, { cancelText: 'Cancel', okText: 'Delete', okType: 'danger', onConfirm: handleDeleteConfirm, title: 'Are you sure?' },
            react_1.default.createElement(icons_1.DeleteFilled, { className: 'delete-button' })));
    const title = react_1.default.createElement(antd_1.Row, { justify: 'space-between', wrap: false },
        react_1.default.createElement(text_1.SonarText, { ellipsis: true }, isFile ? renderFile(listItem) : renderLink(listItem)));
    const description = react_1.default.createElement(text_1.SonarText, { size: 12 }, (0, sonar_ts_lib_1.convertISODateToHumanReadableDate)(listItem.addedOn));
    return (react_1.default.createElement(antd_1.List.Item, { actions: isReadOnly ? [] : [editButton, deleteButton], key: listItem.id },
        react_1.default.createElement(antd_1.List.Item.Meta, { description: description, title: title })));
};
exports.InitiativeMediaListItem = InitiativeMediaListItem;
