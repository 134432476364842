"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.haltClickEventPropagation = haltClickEventPropagation;
exports.haltKeyboardEventPropagation = haltKeyboardEventPropagation;
exports.haltEvent = haltEvent;
exports.ifClickHandler = ifClickHandler;
exports.on = on;
exports.off = off;
exports.once = once;
exports.trigger = trigger;
exports.handleEnter = handleEnter;
exports.handleEscape = handleEscape;
function haltClickEventPropagation(event) {
    event === null || event === void 0 ? void 0 : event.stopPropagation();
}
function haltKeyboardEventPropagation(event) {
    event === null || event === void 0 ? void 0 : event.stopPropagation();
}
function haltEvent(event) {
    event === null || event === void 0 ? void 0 : event.preventDefault();
    event === null || event === void 0 ? void 0 : event.stopPropagation();
}
function ifClickHandler(clickHandler, arg) {
    if (clickHandler) {
        return (event) => {
            if (event) {
                event.preventDefault();
                event.stopPropagation();
            }
            clickHandler(arg);
        };
    }
    return undefined;
}
function on(eventType, listener) {
    document.addEventListener(eventType, listener);
}
function off(eventType, listener) {
    document.removeEventListener(eventType, listener);
}
function once(eventType, listener) {
    function handleEventOnce(event) {
        listener(event);
        off(eventType, handleEventOnce);
    }
    on(eventType, handleEventOnce);
}
function trigger(eventType, data) {
    const event = new CustomEvent(eventType, { detail: data });
    document.dispatchEvent(event);
}
function handleEnter(handler) {
    return function (e) {
        if (e.code === 'Enter') {
            handler();
        }
    };
}
function handleEscape(handler) {
    return function (e) {
        if (e.code === 'Escape') {
            handler();
        }
    };
}
