"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.FollowCheckbox = void 0;
const antd_1 = require("antd");
const icons_1 = require("@ant-design/icons");
const sonar_core_1 = require("@sonar-software/sonar-core");
const events_1 = require("@lib/events");
const useIsFollowing_1 = require("@hooks/followers/useIsFollowing");
const useUpdateFollowing_1 = require("@hooks/followers/useUpdateFollowing");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const FollowCheckbox = ({ sonarObjectId }) => {
    const checked = (0, useIsFollowing_1.useIsFollowing)(sonarObjectId);
    const { follow, unfollow } = (0, useUpdateFollowing_1.useUpdateFollowing)([sonarObjectId]);
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const enabled = (0, _hooks_1.useFeatureFlag)(sonar_core_1.FeatureFlag.Followers);
    const handleOnChange = (0, react_1.useCallback)(() => {
        if (checked) {
            unfollow();
        }
        else {
            follow();
        }
    }, [checked, follow, unfollow]);
    if (!enabled) {
        return null;
    }
    if (isReadOnly) {
        return checked ? react_1.default.createElement(icons_1.CheckCircleOutlined, null) : null;
    }
    return (react_1.default.createElement("div", { className: 'follow-checkbox', onClick: events_1.haltClickEventPropagation },
        react_1.default.createElement(antd_1.Checkbox, { checked: checked, onChange: handleOnChange })));
};
exports.FollowCheckbox = FollowCheckbox;
