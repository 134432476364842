"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SetupAuditTrailTable = void 0;
require("./SetupAuditTrailTable.scss");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const selectors_1 = require("@lib/selectors");
const antd_1 = require("antd");
const SetupAuditTrailTableColumns_1 = require("./SetupAuditTrailTableColumns");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const filters_1 = require("@dictionary/filters");
const lodash_1 = require("lodash");
const sonar_core_1 = require("@sonar-software/sonar-core");
const PaginatedTable_1 = require("@ui/tables/PaginatedTable");
const react_1 = __importStar(require("react"));
const sonar_ts_types_1 = require("@sonar-software/sonar-ts-types");
const _hooks_1 = require("@hooks");
const getRowKey = (result) => `${result.auditDate}-${(0, lodash_1.uniqueId)()}`;
const columns = (0, SetupAuditTrailTableColumns_1.getColumns)();
const sortOptions = [
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'auditDate',
        label: sonar_ts_constants_1.COPY_SPECIFIC.DATE
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'action',
        label: sonar_ts_constants_1.COPY_SPECIFIC.ACTION
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'sourceNamePrefix',
        label: sonar_ts_constants_1.COPY_SPECIFIC.SOURCE_NAMESPACE_PREFIX
    },
    {
        direction: sonar_ts_types_1.SonarV3SortDirection.Ascending,
        key: 'section',
        label: sonar_ts_constants_1.COPY_SPECIFIC.SECTION
    }
];
const setupAuditTrailSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.pulse.setupAuditTrail.get);
const convertToDql = (params) => (0, sonar_ts_lib_1.queryParamsToDynamicQueryLanguageParams)(params, {
    filterParams: {
        start: {
            name: 'auditDate',
            rangeEnd: 'end'
        }
    }
});
const SetupAuditTrailTable = ({ dateRange }) => {
    const getSetupAuditTrailEvents = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestGetCoreSetupAuditTrail);
    const { data, isRequested } = (0, _hooks_1.useAsyncState)(setupAuditTrailSelectors);
    const { queryParams, updateParams } = (0, _hooks_1.useQueryParams)({
        defaultParams: {
            count: 10,
            sortBy: sortOptions[0].key,
            sortDirection: sortOptions[0].direction
        },
        onUpdate: (newParams, prev, combined) => {
            const { key, paginationDirection } = newParams;
            const params = convertToDql(Object.assign(Object.assign({}, combined), { key,
                paginationDirection }));
            getSetupAuditTrailEvents(params);
        }
    });
    (0, react_1.useEffect)(() => {
        if (dateRange.end !== (queryParams === null || queryParams === void 0 ? void 0 : queryParams.end) || dateRange.start !== (queryParams === null || queryParams === void 0 ? void 0 : queryParams.start)) {
            const { start, end } = dateRange;
            updateParams({
                end,
                start
            });
        }
    }, [dateRange, queryParams === null || queryParams === void 0 ? void 0 : queryParams.end, queryParams === null || queryParams === void 0 ? void 0 : queryParams.start, updateParams]);
    const handleSortUpdate = (0, react_1.useCallback)((update) => {
        updateParams(update);
    }, [updateParams]);
    return (react_1.default.createElement("div", { id: 'setup-audit-trail-table' },
        react_1.default.createElement(antd_1.Flex, { align: 'end', className: 'filter-section', justify: 'end' },
            react_1.default.createElement(filters_1.SortedBySelect, { defaultValue: sortOptions[0], onChange: handleSortUpdate, queryParams: queryParams, sortOptions: sortOptions })),
        react_1.default.createElement(PaginatedTable_1.PaginatedTable, { columns: columns, defaultPageSize: queryParams.count, loading: isRequested, onChange: updateParams, resource: data, rowKey: getRowKey, scroll: {
                scrollToFirstRowOnChange: true,
                x: 'max-content'
            }, sortBy: queryParams.sortBy, sortDirection: queryParams.sortDirection, sticky: true })));
};
exports.SetupAuditTrailTable = SetupAuditTrailTable;
