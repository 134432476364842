"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.usePulseAppColors = usePulseAppColors;
const lib_1 = require("@pulse/@arch/lib");
const lodash_1 = require("lodash");
const references_1 = require("@hooks/references");
const usePrevious_1 = require("@hooks/usePrevious");
const react_1 = require("react");
function usePulseAppColors(appIds) {
    const [applicationColors, setApplicationColors] = (0, react_1.useState)({});
    const apps = (0, references_1.useApplicationReferences)();
    const previousAppIds = (0, usePrevious_1.usePrevious)(appIds);
    (0, react_1.useEffect)(() => {
        if (appIds && !(0, lodash_1.isEqual)(appIds, previousAppIds)) {
            appIds.forEach((id) => {
                var _a;
                const { name } = (_a = apps[id]) !== null && _a !== void 0 ? _a : {};
                (0, lib_1.getAppColor)(name, (color) => {
                    setApplicationColors((colors) => (Object.assign(Object.assign({}, colors), { [id]: color })));
                });
            });
        }
    }, [appIds, applicationColors, apps, previousAppIds]);
    return applicationColors;
}
