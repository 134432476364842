"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SalesforcePreferences = void 0;
const notifications_1 = require("@ui/notifications");
const sonar_core_1 = require("@sonar-software/sonar-core");
const LoadingSpinner_1 = require("@ui/LoadingSpinner");
const selectors_1 = require("@lib/selectors");
const antd_1 = require("antd");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const selectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.users.byId.salesforcePrefs.get);
const patchStateSelector = (0, selectors_1.createSonarSelector)((state) => state.core.users.byId.salesforcePrefs.patch);
const SalesforcePreferences = () => {
    const { id: userId } = (0, _hooks_1.useCurrentUser)();
    const { isRequested, data } = (0, _hooks_1.useAsyncStateWithPreload)(selectors, sonar_core_1.coreActions.requestGetCoreUsersByIdSalesforcePreferences, true, { userId });
    const patchPreferences = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPatchCoreUsersByIdSalesforcePreferences);
    const handleLightningUrlsChange = (0, react_1.useCallback)((value) => {
        patchPreferences({
            useLightningUrls: value,
            userId
        });
    }, [patchPreferences, userId]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement("div", { id: 'salesforce-preferences' },
            react_1.default.createElement(LoadingSpinner_1.LoadingSpinner, { spinning: isRequested },
                react_1.default.createElement(antd_1.Form, { colon: false },
                    react_1.default.createElement(antd_1.Form.Item, { className: 'horizontal-form-item', label: sonar_ts_constants_1.SALESFORCE_PREFERENCES_COPY.USE_LIGHTNING_URLS, name: 'useLightningUrls' },
                        react_1.default.createElement(antd_1.Switch, { checked: data === null || data === void 0 ? void 0 : data.useLightningUrls, onChange: handleLightningUrlsChange }))))),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.NOTIFICATIONS_COPY.ERROR_PATCH_USER_PREFERENCES, failed: sonar_ts_constants_1.NOTIFICATIONS_COPY.FAILED_PATCH_USER_PREFERENCES, received: sonar_ts_constants_1.NOTIFICATIONS_COPY.SUCCESS_PATCH_USER_PREFERENCES, resetRawAction: sonar_core_1.coreActions.resetPatchCoreUsersByIdSalesforcePreferences, stateSelector: patchStateSelector })));
};
exports.SalesforcePreferences = SalesforcePreferences;
