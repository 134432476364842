"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useSalesforceUsers = void 0;
const hooks_1 = require("@lib/hooks");
const sonar_core_1 = require("@sonar-software/sonar-core");
const empty = [];
exports.useSalesforceUsers = (0, hooks_1.createAsyncStateWithPreloadHook)({
    loadAction: sonar_core_1.coreActions.requestGetCoreAllSalesforceUsers,
    selector: (state) => state.core.users.salesforce.all.get,
    useTransform: (result) => result.data || empty
});
