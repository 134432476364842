"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeleteInitiativeScopeButton = void 0;
const modals_1 = require("@ui/modals");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const sonar_core_1 = require("@sonar-software/sonar-core");
const _hooks_1 = require("@hooks");
const react_1 = __importStar(require("react"));
const DeleteInitiativeScopeButton = ({ initiativeId, sonarObjectIds, selectedObjectType, onDelete }) => {
    const deleteBulkInitiativeScope = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestDeleteCoreInitiativesSonarObjects);
    const handleBulkDeleteScope = (0, react_1.useCallback)(() => {
        deleteBulkInitiativeScope({
            initiativeId: initiativeId,
            sonarObjectIds: sonarObjectIds
        });
        onDelete();
    }, [deleteBulkInitiativeScope, initiativeId, sonarObjectIds, onDelete]);
    const title = sonar_ts_constants_1.COPY_SPECIFIC.ARE_YOU_SURE_DELETE_SCOPE(sonarObjectIds.length, selectedObjectType !== null && selectedObjectType !== void 0 ? selectedObjectType : 'scope items');
    return (react_1.default.createElement(modals_1.ConfirmDeleteButton, { buttonId: 'initiative-scope-table-delete-button', buttonLabel: sonar_ts_constants_1.COPY_SPECIFIC.REMOVE, cancelButtonProps: {
            id: 'initiative-scope-table-cancel-button'
        }, confirmButtonProps: {
            id: 'initiative-scope-table-confirm-button',
            value: sonar_ts_constants_1.COPY_SPECIFIC.REMOVE
        }, onConfirm: handleBulkDeleteScope, placement: 'bottomRight', title: title, type: 'text' }));
};
exports.DeleteInitiativeScopeButton = DeleteInitiativeScopeButton;
