"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AvatarUploadModal = void 0;
const notifications_1 = require("@ui/notifications");
const sonar_core_1 = require("@sonar-software/sonar-core");
const selectors_1 = require("@lib/selectors");
const sonar_ts_lib_1 = require("@sonar-software/sonar-ts-lib");
const modals_1 = require("@ui/modals");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const files_1 = require("@ui/files");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const acceptedTypes = {
    'image/jpeg': [],
    'image/png': []
};
const cancelButtonProps = {
    danger: true,
    type: 'link'
};
const uploadSelectors = (0, selectors_1.createAsyncSelectors)((state) => state.core.users.current.picture.put);
const AvatarUploadModal = (_a) => {
    var { onAfterSave } = _a, rest = __rest(_a, ["onAfterSave"]);
    const { id } = (0, _hooks_1.useCurrentUser)();
    const [file, setFile] = (0, react_1.useState)();
    const isProcessing = (0, _hooks_1.useSonarSelector)(uploadSelectors.isRequested);
    const isFinished = !(0, _hooks_1.useSonarSelector)(uploadSelectors.isIdle) && !isProcessing;
    const uploadProfilePicture = (0, _hooks_1.useAction)(sonar_core_1.coreActions.requestPutCoreUsersCurrentPicture);
    const updateUserReference = (0, _hooks_1.useAction)(sonar_core_1.coreActions.updateUserReferences);
    const imageCropRef = (0, react_1.useRef)(null);
    (0, react_1.useEffect)(() => {
        if (!rest.open && file) {
            setFile(undefined);
        }
    }, [file, rest.open]);
    (0, react_1.useEffect)(() => {
        if (isFinished && onAfterSave) {
            onAfterSave();
        }
    }, [isFinished, onAfterSave]);
    const handleFileSelection = (0, react_1.useCallback)((files) => {
        if (!files.length) {
            return;
        }
        setFile(files[0]);
    }, []);
    const handleSave = (0, react_1.useCallback)(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!imageCropRef.current) {
            return;
        }
        const croppedImage = yield imageCropRef.current.getCroppedImage();
        if (croppedImage) {
            const mimeType = croppedImage.type;
            const extension = (0, sonar_ts_lib_1.getFileExtensionForMimeType)(mimeType);
            const croppedFile = new File([croppedImage], `profile-picture-${id}${extension}`, {
                type: mimeType
            });
            uploadProfilePicture({
                file: croppedFile
            });
            updateUserReference([
                {
                    profilePicture: URL.createObjectURL(croppedFile),
                    profilePictureTimestamp: Date.now(),
                    userId: id
                }
            ]);
        }
    }), [id, updateUserReference, uploadProfilePicture]);
    return (react_1.default.createElement(react_1.default.Fragment, null,
        react_1.default.createElement(modals_1.SonarModal, Object.assign({ fixedWidth: true }, rest, { cancelButtonProps: Object.assign(Object.assign({}, cancelButtonProps), { disabled: isProcessing }), destroyOnClose: true, footer: file ? undefined : null, okButtonProps: {
                disabled: isProcessing
            }, okText: sonar_ts_constants_1.COPY_SPECIFIC.SAVE, onOk: handleSave, title: sonar_ts_constants_1.COPY_SPECIFIC.UPLOAD_YOUR_PHOTO }),
            react_1.default.createElement("section", { className: 'avatar-crop-container' },
                !file &&
                    react_1.default.createElement(files_1.FileDropArea, { accept: acceptedTypes, onAccept: handleFileSelection, unsupportedMessage: sonar_ts_constants_1.COPY_SPECIFIC.UNSUPPORTED_FILE_TYPE_IMAGE }),
                file && react_1.default.createElement(files_1.ImageFileCrop, { cropShape: 'round', ref: imageCropRef, sourceFile: file }))),
        react_1.default.createElement(notifications_1.AsyncStateNotification, { errored: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, failed: sonar_ts_constants_1.COPY_GENERIC.CONTACT_SUPPORT, resetRawAction: sonar_core_1.coreActions.resetPutCoreUsersCurrentPicture, stateSelector: uploadSelectors.state })));
};
exports.AvatarUploadModal = AvatarUploadModal;
