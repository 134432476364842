"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AddToTaskSelector = void 0;
require("./AddToTaskSelector.scss");
const filter_1 = require("@lib/filter");
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
const antd_1 = require("antd");
const react_1 = __importStar(require("react"));
const _hooks_1 = require("@hooks");
const AddToTaskSelector = ({ initiativeId, nameFilter, onSelect, selectAllEnabled, scopeIds, sonarObjectIds, typesFilter }) => {
    const { isReadOnly } = (0, _hooks_1.useCurrentUser)();
    const { tasks } = (0, _hooks_1.useInitiativeTasks)(initiativeId);
    const { request } = (0, _hooks_1.useLinkInitiativeTask)();
    const selectOptions = (0, react_1.useMemo)(() => {
        var _a;
        return (_a = tasks === null || tasks === void 0 ? void 0 : tasks.map((task) => ({
            label: task.name,
            value: String(task.initiativeTaskId)
        }))) !== null && _a !== void 0 ? _a : [];
    }, [tasks]);
    const handleOnSelect = (value) => {
        const taskId = Number(value);
        request({
            all: selectAllEnabled,
            initiativeId,
            initiativeScopeIds: selectAllEnabled ? [] : scopeIds,
            initiativeScopeIdsToExclude: selectAllEnabled ? scopeIds : [],
            initiativeTaskId: taskId,
            objectNameSearchText: nameFilter,
            objectTypes: typesFilter,
            sonarObjectIds: sonarObjectIds
        });
        onSelect();
    };
    return (react_1.default.createElement(antd_1.Select, { className: 'add-to-task-selector', defaultValue: sonar_ts_constants_1.COPY_SPECIFIC.ADD_TO_TASK, disabled: isReadOnly, filterOption: filter_1.caseInsensitiveContainsFilterOption, onSelect: handleOnSelect, options: selectOptions, showSearch: true, value: sonar_ts_constants_1.COPY_SPECIFIC.ADD_TO_TASK }));
};
exports.AddToTaskSelector = AddToTaskSelector;
