"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SuggestPlaintextEditor = void 0;
const SuggestionField_1 = require("./SuggestionField");
const PlaintextEditor_1 = require("./PlaintextEditor");
const react_1 = __importStar(require("react"));
const SuggestPlaintextEditor = (_a) => {
    var { autodocType, isReadOnly, onSave, placeholder, sonarObjectId, suggestion, value } = _a, rest = __rest(_a, ["autodocType", "isReadOnly", "onSave", "placeholder", "sonarObjectId", "suggestion", "value"]);
    const editorRef = (0, react_1.useRef)(null);
    const [editingValue, setEditingValue] = (0, react_1.useState)('');
    const onEdit = (0, react_1.useCallback)(() => {
        var _a;
        suggestion.accept();
        (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.edit();
    }, [suggestion]);
    const onAccept = (0, react_1.useCallback)(() => {
        var _a;
        suggestion.accept();
        (_a = editorRef.current) === null || _a === void 0 ? void 0 : _a.save();
    }, [suggestion]);
    const onFocus = (0, react_1.useCallback)(() => {
        suggestion.accept();
    }, [suggestion]);
    const onBlur = (0, react_1.useCallback)(() => {
        const userInput = !suggestion.value && editingValue;
        if (!(suggestion.value && suggestion.accepted) && !userInput) {
            suggestion.reset();
        }
    }, [suggestion, editingValue]);
    const onCancel = (0, react_1.useCallback)(() => {
        suggestion.reset();
    }, [suggestion]);
    const onChange = (0, react_1.useCallback)((newValue) => {
        setEditingValue(newValue);
    }, []);
    const handleSave = (0, react_1.useCallback)((newValue) => __awaiter(void 0, void 0, void 0, function* () {
        const result = onSave(newValue);
        if (result === null || result === void 0 ? void 0 : result.then) {
            yield result;
        }
        suggestion.reset();
    }), [onSave, suggestion]);
    return (react_1.default.createElement(SuggestionField_1.SuggestionField, { autodocType: autodocType, isReadOnly: isReadOnly, onAccept: onAccept, onBlur: onBlur, onEdit: onEdit, placeholder: placeholder, suggestion: suggestion },
        react_1.default.createElement(PlaintextEditor_1.PlaintextEditor, Object.assign({}, rest, { isReadOnly: isReadOnly, onBlur: onBlur, onCancel: onCancel, onChange: onChange, onFocus: onFocus, onSave: handleSave, placeholder: placeholder, ref: editorRef, value: suggestion.editorValue }))));
};
exports.SuggestPlaintextEditor = SuggestPlaintextEditor;
