"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fieldAccessOptions = void 0;
const sonar_ts_constants_1 = require("@sonar-software/sonar-ts-constants");
exports.fieldAccessOptions = [
    {
        label: sonar_ts_constants_1.COPY_SPECIFIC.EDIT,
        value: sonar_ts_constants_1.COPY_SPECIFIC.EDIT
    },
    {
        label: sonar_ts_constants_1.COPY_SPECIFIC.READ_ONLY,
        value: sonar_ts_constants_1.COPY_SPECIFIC.READ_ONLY
    },
    {
        label: sonar_ts_constants_1.COPY_SPECIFIC.REQUIRED,
        value: sonar_ts_constants_1.COPY_SPECIFIC.REQUIRED
    }
];
